import { Alert, Typography, Link } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetSamplesQuery,
  useGetSampleTokensCountQuery,
  useGetUserQuery,
} from 'graphql/generated';
import { usePro } from 'use/pro';

export default function TokenChecker() {
  const { setOpenModal } = usePro();
  const { memoryID } = useParams<{ memoryID: string }>();
  const { data: sampleData } = useGetSamplesQuery({
    input: { memoryID },
  });
  const { data: sampleTokensData, refetch: refetchSampleTokens } =
    useGetSampleTokensCountQuery(
      {
        input: {
          memoryID,
        },
      },
      {
        skip: !memoryID,
      },
    );
  console.log(sampleTokensData);
  const { data: userData } = useGetUserQuery({ input: {} });
  const samples = useMemo(() => sampleData?.getSamples?.data, [sampleData]);
  const user = useMemo(() => userData?.getUser, [userData]);
  const sampleTokens = useMemo(
    () => sampleTokensData?.getSampleTokensCount,
    [sampleTokensData],
  );

  const isNearLimit = useMemo(() => {
    if (!user || !sampleTokens) return false;

    const remainingTokens = user.ingressTokensLimit - user.ingressTokensUsed;
    const warningThreshold = remainingTokens * 0.9; // 10% threshold

    return sampleTokens.tokenCount >= warningThreshold;
  }, [user, sampleTokens]);

  const getWarningMessage = useMemo(() => {
    if (!user || !sampleTokens) return null;

    const remainingTokens = user.ingressTokensLimit - user.ingressTokensUsed;

    if (sampleTokens.tokenCount > remainingTokens) {
      return {
        beforeUpgrade:
          'You will exceed your token limit. The job will fail and not all files will ingest. Consider ',
        afterUpgrade: ' or removing some samples.',
      };
    }

    if (isNearLimit && !sampleTokens.completed) {
      return {
        beforeUpgrade:
          'Some files have not finished pre-processing, and token counting is not completed. You are likely to exceed the limit once all files have finished pre-processing. Consider',
        afterUpgrade: ' or removing some samples.',
      };
    }

    if (isNearLimit) {
      return {
        beforeUpgrade: 'You are near your token limit. Please consider ',
        afterUpgrade: ' or removing some samples.',
      };
    }

    return null;
  }, [user, sampleTokens, isNearLimit]);

  useEffect(() => {
    if (samples) {
      refetchSampleTokens();
    }
  }, [samples, refetchSampleTokens]);

  return (
    <>
      {getWarningMessage && (
        <Alert
          severity={
            sampleTokens?.tokenCount >
            user?.ingressTokensLimit - user?.ingressTokensUsed
              ? 'error'
              : 'warning'
          }
        >
          <Typography variant="body2">
            {getWarningMessage.beforeUpgrade}
            <Link
              component="button"
              onClick={() => setOpenModal(true)}
              underline="always"
              sx={{ color: 'inherit', mb: 0.5 }}
            >
              upgrading
            </Link>
            {getWarningMessage.afterUpgrade}
          </Typography>
        </Alert>
      )}
    </>
  );
}
