import React, { useState, useEffect, useCallback } from 'react';
import { Box, TextField } from '@mui/material';
import InfoLabel from '../info-label';
import { debounce } from 'lodash';

interface InfoTextFieldProps {
  label: string;
  tooltip: string;
  placeholder?: string;
  multiline?: boolean;
  minRows?: number;
  value: string | number;
  setValue: (value: string | number) => void;
  endAction?: React.ReactNode;
  type?: string;
}
const debouncedSetValue = debounce(
  (func: (value: string) => void, value: string) => {
    func(value);
  },
  300,
);

function InfoTextField({
  label,
  tooltip,
  placeholder,
  multiline,
  minRows,
  value,
  setValue,
  endAction,
  type,
}: InfoTextFieldProps) {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setLocalValue(newValue);
      debouncedSetValue(setValue, newValue);
    },
    [setValue],
  );

  const handleBlur = useCallback(() => {
    setValue(localValue);
  }, [setValue, localValue]);

  return (
    <Box>
      <InfoLabel
        label={label}
        tooltip={tooltip}
        placement="top"
        endAction={endAction}
      />
      <TextField
        type={type ? type : 'text'}
        value={localValue}
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        size="small"
        placeholder={placeholder}
        multiline={multiline}
        minRows={minRows}
      />
    </Box>
  );
}

export default InfoTextField;
