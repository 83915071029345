import { useMemo } from 'react';
import { createAvatar } from '@dicebear/core';
import { avataaars } from '@dicebear/collection';
import { Avatar, AvatarProps } from '@mui/material';

interface AvatarVoiceProps extends Omit<AvatarProps, 'src'> {
  name: string;
  size?: number;
}

export default function AvatarVoice({
  name,
  size = 56,
  ...props
}: AvatarVoiceProps) {
  const avatarSvg = useMemo(() => {
    const avatar = createAvatar(avataaars, {
      seed: name,
      size: size,
    });
    return avatar.toDataUri();
  }, [name, size]);

  return (
    <Avatar
      src={avatarSvg}
      alt={`Avatar for voice ${name}`}
      sx={{ width: size, height: size }}
      {...props}
    />
  );
}
