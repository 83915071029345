import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Layout from 'layouts/index';
import { Billing } from './components';
import { useGetUserQuery, useUpdateUserMutation } from 'graphql/generated';
import { Close, Settings } from '@mui/icons-material';
import { ApiKeys } from 'components/organisms';
import { useSnackbar } from 'use/snackbar';
import { LoadingButton } from '@mui/lab';
import { UsageAccount } from 'components/molecules';

export function Account() {
  const { data, refetch } = useGetUserQuery({
    input: {},
  });
  const { showSnackbar } = useSnackbar();
  const user = useMemo(() => data?.getUser, [data]);
  const { firstName, lastName } = user ?? {};
  const [updateUser, { isLoading: isUpdatingUser }] = useUpdateUserMutation();

  const [openSettings, setOpenSettings] = useState(false);
  const [editFirstName, setEditFirstName] = useState(firstName || '');
  const [editLastName, setEditLastName] = useState(lastName || '');

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    setEditFirstName(firstName || '');
    setEditLastName(lastName || '');
  }, [firstName, lastName]);

  const handleOpenSettings = () => {
    setOpenSettings(true);
  };

  const handleCloseSettings = useCallback(() => {
    setOpenSettings(false);
  }, [setOpenSettings]);

  const handleSaveSettings = useCallback(async () => {
    await updateUser({
      input: {
        firstName: editFirstName,
        lastName: editLastName,
      },
    }).unwrap();
    await refetch();
    showSnackbar('Name updated', 'success');
    handleCloseSettings();
  }, [
    editFirstName,
    editLastName,
    refetch,
    updateUser,
    handleCloseSettings,
    showSnackbar,
  ]);

  return (
    <Layout
      endAction={
        <IconButton onClick={handleOpenSettings}>
          <Settings />
        </IconButton>
      }
    >
      <Box>
        <Billing />
        <UsageAccount />
      </Box>
      <ApiKeys />
      <Dialog
        open={openSettings}
        onClose={handleCloseSettings}
        fullWidth
        maxWidth="sm"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ px: 2, py: 1 }}
        >
          <Typography variant="h4">Edit Name</Typography>
          <IconButton onClick={handleCloseSettings}>
            <Close />
          </IconButton>
        </Stack>
        <Divider sx={{ opacity: 0.5 }} />
        <DialogContent>
          <Stack
            spacing={2}
            direction="column"
          >
            <TextField
              autoFocus
              margin="dense"
              label="First Name"
              type="text"
              size="small"
              fullWidth
              value={editFirstName}
              onChange={(e) => setEditFirstName(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Last Name"
              type="text"
              fullWidth
              size="small"
              value={editLastName}
              onChange={(e) => setEditLastName(e.target.value)}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <LoadingButton
            loading={isUpdatingUser}
            size="small"
            variant="contained"
            sx={{ height: 28, fontWeight: 'bold' }}
            onClick={handleSaveSettings}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}

export default Account;
