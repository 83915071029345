import { Close } from '@mui/icons-material';
import {
  Paper,
  Stack,
  IconButton,
  Typography,
  Box,
  Skeleton,
  Link,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import {
  useGetApiKeysQuery,
  useGetBotQuery,
  useGetUserQuery,
} from 'graphql/generated';
import { Call as CallComponent } from 'components/organisms';
import { AvatarBot, Logo } from 'components/atoms';
import { getCurrentUser } from 'aws-amplify/auth';

export default function Call() {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const { botID } = useParams();

  const { data: botData, isLoading: isLoadingBot } = useGetBotQuery({
    input: {
      id: botID,
    },
  });
  const bot = botData?.getBot?.data;
  const { data: userData, isLoading: isLoadingOwner } = useGetUserQuery(
    {
      input: {
        userID: bot?.userID,
      },
    },
    {
      skip: !bot?.userID,
    },
  );
  const { data: apiKeyData } = useGetApiKeysQuery(
    {
      input: { activeOnly: true, userID: bot?.userID },
    },
    {
      skip: !bot?.userID,
    },
  );
  const checkAuthState = useCallback(async () => {
    try {
      await getCurrentUser();
      return true;
    } catch {
      return false;
    }
  }, []);

  useEffect(() => {
    const checkAuth = async () => {
      const isLoggedIn = await checkAuthState();
      setIsLoggedIn(isLoggedIn);
    };
    checkAuth();
  }, [checkAuthState, isLoggedIn, navigate]);

  const apiKeys = apiKeyData?.getApiKeys?.data || [];
  const loading = isLoadingBot || isLoadingOwner;
  const owner = userData?.getUser;

  const exit = useCallback(() => {
    if (isLoggedIn) {
      navigate('/');
    } else {
      navigate('/signup');
    }
  }, [isLoggedIn, navigate]);

  return (
    <Paper
      sx={{
        width: '100vw',
        height: '100vh',
        p: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        sx={{
          width: '100%',
        }}
      >
        <Logo />
        <Stack
          direction="row"
          spacing={1.5}
          alignItems="center"
        >
          <IconButton onClick={exit}>
            <Close />
          </IconButton>
        </Stack>
      </Stack>

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={10}
        sx={{
          width: '100%',
          px: 2,
        }}
      >
        {!isLoggedIn && <Box pt={5} />}
        {loading ? (
          <Stack
            direction="column"
            spacing={1}
            alignItems="center"
            sx={{
              textAlign: 'center',
              width: '100%',
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              <Typography variant="h1">Call</Typography>
              <Skeleton
                variant="text"
                width={120}
                height={48}
              />
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              <Typography variant="h1">about</Typography>
              <Skeleton
                variant="text"
                width={160}
                height={48}
              />
            </Stack>
          </Stack>
        ) : (
          <Typography
            typography="h1"
            align="center"
          >
            Call {bot?.name} about {bot?.description}
          </Typography>
        )}
        {loading ? (
          <Skeleton
            variant="circular"
            width={64}
            height={64}
          />
        ) : (
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 0,
                opacity: 0.25,
              }}
            >
              <AvatarBot
                size={75}
                bot={bot}
              />
            </Box>
            <Box sx={{ position: 'relative', zIndex: 1 }}>
              <CallComponent
                variant={'icon'}
                apiKey={apiKeys[0]}
              />
            </Box>
          </Box>
        )}
        <Stack
          direction="column"
          spacing={1}
          alignItems="center"
        >
          <Typography typography="h6">Created by</Typography>
          {loading ? (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
            >
              <Skeleton
                variant="text"
                width={120}
                height={32}
              />{' '}
              <Skeleton
                variant="text"
                width={120}
                height={32}
              />{' '}
            </Stack>
          ) : (
            <Typography typography="h4">
              {owner?.firstName} {owner?.lastName}
            </Typography>
          )}
          {!isLoggedIn && (
            <Box pt={4}>
              <Link
                sx={{ fontSize: 12, cursor: 'pointer' }}
                onClick={() => navigate('/signup')}
              >
                Create My Own
              </Link>
            </Box>
          )}
        </Stack>
      </Stack>
      <Box height="75px" />
    </Paper>
  );
}
