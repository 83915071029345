import { Grid } from '@mui/material';
import { Loading } from 'components/atoms';
import { MemoryPreview } from 'components/molecules';
import { useGetMemoriesQuery } from 'graphql/generated';

export default function Memory() {
  const { data, isLoading: isGettingMemories } = useGetMemoriesQuery(
    {
      input: {},
    },
    { refetchOnMountOrArgChange: true },
  );
  const memories = data?.getMemories?.data;

  return isGettingMemories ? (
    <Loading />
  ) : (
    <Grid
      container
      spacing={2}
      my={1}
    >
      {memories?.map((memory) => (
        <MemoryPreview
          key={memory?.id}
          memory={memory}
        />
      ))}
    </Grid>
  );
}
