import { TextField } from '@mui/material';
import { useEffect } from 'react';

interface SitemapFormProps {
  url: string;
  title: string;
  onUrlChange: (url: string) => void;
  onTitleChange: (title: string) => void;
  isLoading: boolean;
  getSitemapMetadata: any; // Replace 'any' with actual type from your generated types
  setRootSitemap: (sitemap: string) => void;
  setPages: (pages: number) => void;
}

export default function SitemapForm({
  url,
  title,
  onUrlChange,
  onTitleChange,
  getSitemapMetadata,
  setRootSitemap,
  setPages,
}: SitemapFormProps) {
  useEffect(() => {
    const fetchSitemapMetadata = async () => {
      if (url) {
        try {
          const result = await getSitemapMetadata({ input: { url } }).unwrap();
          if (
            result.getSitemapMetadata.success &&
            result.getSitemapMetadata.title
          ) {
            onTitleChange(result.getSitemapMetadata.title);
            setRootSitemap(result.getSitemapMetadata.rootSitemap);
            setPages(result.getSitemapMetadata.urlCount);
          }
        } catch (error) {
          console.error('Error fetching sitemap metadata:', error);
        }
      }
    };

    fetchSitemapMetadata();
  }, [url, getSitemapMetadata, onTitleChange, setRootSitemap, setPages]);

  return (
    <>
      <TextField
        label="Site URL (any page)"
        fullWidth
        value={url}
        placeholder="https://example.com or https://example.com/sitemap.xml"
        onChange={(e) => onUrlChange(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TextField
        label="Website Title"
        fullWidth
        value={title}
        onChange={(e) => onTitleChange(e.target.value)}
        sx={{ mb: 2 }}
      />
    </>
  );
}
