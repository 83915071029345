import React, { useCallback } from 'react';
import { Stack, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useAudio } from 'use/audio';
import { useUploadQueue } from 'use/upload';
import { v4 as uuid } from 'uuid';
import type { File } from '@duohub/types';
import { FileSource, FileCategory, FileType, UploadStatus } from 'types/enums';
import { useUser } from 'use/user';
import { useSnackbar } from 'use/snackbar';
import { getFileType } from 'common/utils';
import { AppFile, FileWithMetadata } from 'types/app';
import { useParams } from 'react-router-dom';

interface AudioControlsProps {
  onClearAudio: () => void;
  recordedAudio: string | null;
}

const AudioControls: React.FC<AudioControlsProps> = ({
  onClearAudio,
  recordedAudio,
}) => {
  const { voiceID } = useParams<{ voiceID: string }>();
  const { isSubmitting, setIsSubmitting } = useAudio();
  const { addToQueue } = useUploadQueue();
  const { getUser } = useUser();
  const { showSnackbar } = useSnackbar();
  const handleAddFile = useCallback(
    async (blob: Blob): Promise<AppFile> => {
      try {
        const user = await getUser();
        const input: File = {
          id: uuid(),
          name: `recording_${new Date().getTime()}.webm`,
          bronzeKey: '',
          size: blob.size,
          extension: getFileType(blob.type) as FileType,
          category: FileCategory.audio,
          source: FileSource.record,
          userID: user?.userId,
          originRegion: process.env.REACT_APP_AWS_REGION,
          fileType: FileType.audio_recording,
        };

        return input;
      } catch (error) {
        console.error(error);
        showSnackbar('Failed to submit file. Please try again later.', 'error');
        return null;
      }
    },
    [getUser, showSnackbar],
  );

  const handleSubmit = async () => {
    if (recordedAudio) {
      setIsSubmitting(true);
      try {
        const response = await fetch(recordedAudio);
        const blob = await response.blob();
        const appFile = await handleAddFile(blob);
        if (appFile) {
          const file = new File([blob], 'recorded_audio.webm', {
            type: 'audio/webm',
          });
          const fileWithMetadata: FileWithMetadata = {
            file,
            appFile,
            uploadStatus: UploadStatus.pending,
            progress: 0,
            sampleIndexID: voiceID,
            createSample: true,
            sampleType: 'voice',
          };
          addToQueue([fileWithMetadata]);
          onClearAudio();
        }
      } catch (error) {
        console.error('Error preparing file for upload:', error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      sx={{ pl: 1.5, pr: 0.5 }}
    >
      <Stack
        direction="row"
        spacing={2}
      >
        <IconButton
          onClick={onClearAudio}
          disabled={!recordedAudio}
        >
          <Close />
        </IconButton>
      </Stack>
      <LoadingButton
        size="small"
        variant="contained"
        color="primary"
        loading={isSubmitting}
        disabled={!recordedAudio}
        sx={{ fontWeight: 'bold' }}
        onClick={handleSubmit}
      >
        Submit
      </LoadingButton>
    </Stack>
  );
};

export default AudioControls;
