import {
  Card,
  CardContent,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetVoiceQuery } from 'graphql/generated';

export default function VoiceNewTrain() {
  const { voiceID } = useParams<{ voiceID: string }>();
  const { refetch } = useGetVoiceQuery({
    input: {
      id: voiceID,
    },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 10000);

    return () => clearInterval(interval);
  }, [refetch]);

  return (
    <Card>
      <CardContent>
        <Stack
          direction="column"
          spacing={1.5}
        >
          <Typography variant="h5">Voice Training in Progress</Typography>
          <Stack
            direction="column"
            spacing={1}
          >
            <LinearProgress
              sx={{ width: '100%', borderRadius: 10 }}
              variant="indeterminate"
            />
            <Typography variant="body2">
              This usually takes less than 10 seconds.
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
