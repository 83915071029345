import { ReactNode } from 'react';
import { RecoilRoot } from 'recoil';
import { UserContextProvider } from './user/state';
import { AppContextProvider } from './app';
import { SnackbarProvider } from './snackbar';
import { CallContextProvider } from './call';
import { ProContextProvider } from './pro';
import UploadContextProvider from './upload';
import { AudioContextProvider } from './audio';
import { MemoryContextProvider } from './memory';
import { ContentContextProvider } from './content';

interface ContextProviderProps {
  children: ReactNode;
}

function ContextProvider({ children }: ContextProviderProps): JSX.Element {
  return (
    <RecoilRoot>
      <AppContextProvider>
        <UserContextProvider>
          <CallContextProvider>
            <ProContextProvider>
              <UploadContextProvider>
                <AudioContextProvider>
                  <MemoryContextProvider>
                    <ContentContextProvider>
                      <SnackbarProvider>{children}</SnackbarProvider>
                    </ContentContextProvider>
                  </MemoryContextProvider>
                </AudioContextProvider>
              </UploadContextProvider>
            </ProContextProvider>
          </CallContextProvider>
        </UserContextProvider>
      </AppContextProvider>
    </RecoilRoot>
  );
}

export default ContextProvider;

export { useContentReducer as useContent } from './content';
