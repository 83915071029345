import { getCurrentCredentials } from 'interface/auth/api';
import { AuthUser, getCurrentUser } from 'aws-amplify/auth';
import {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
  useMemo,
} from 'react';

interface UserContextState {
  user: AuthUser | null;
  getUser: () => Promise<AuthUser | null>;
  getJWT: () => Promise<string | null>;
}

const UserContext = createContext<UserContextState | undefined>(undefined);

export const useUserContext = (): UserContextState => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserContextProvider');
  }
  return context;
};

interface UserContextProviderProps {
  children: ReactNode;
}

export function UserContextProvider({
  children,
}: UserContextProviderProps): JSX.Element {
  const [user, setUser] = useState<AuthUser | null>(null);
  const [jwt, setJWT] = useState<string | null>(null);
  const [lastUpdatedJWT, setLastUpdatedJWT] = useState<number>(0);
  const [lastUpdatedUser, setLastUpdatedUser] = useState<number>(0);

  const getUser = useCallback(async (): Promise<AuthUser | null> => {
    const currentTime = Date.now() / 1000; // Convert to seconds
    if (user === null || currentTime - lastUpdatedUser > 600) {
      try {
        const newUser = await getCurrentUser();
        setUser(newUser);
        setLastUpdatedUser(currentTime);
        return newUser;
      } catch (error) {
        console.error('Error updating user:', error);
        return null;
      }
    }
    return user;
  }, [user, lastUpdatedUser]);

  const getJWT = useCallback(async (): Promise<string | null> => {
    const currentTime = Date.now() / 1000;
    if (jwt === null || currentTime - lastUpdatedJWT > 600) {
      try {
        const { jwt: newJWT } = await getCurrentCredentials();
        setJWT(newJWT);
        setLastUpdatedJWT(currentTime);
        return newJWT;
      } catch (error) {
        console.error('Error updating JWT:', error);
        return null;
      }
    }
    return jwt;
  }, [jwt, lastUpdatedJWT]);

  const contextValue = useMemo(
    () => ({ user, getUser, getJWT }),
    [user, getUser, getJWT],
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
}
