import { Card, CardContent, Typography, Divider, Stack } from '@mui/material';
import { AudioSource, FileCategory } from 'types/enums';
import {
  InterfaceMediaRecord,
  InterfaceMediaUpload,
  InterfaceMediaContent,
  AudioToggleButtonSource,
} from 'components/molecules';
import { useAudio } from 'use/audio';

const MediaAdd: React.FC = () => {
  const { audioSource } = useAudio();

  return (
    <Card>
      <CardContent>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
        >
          <Typography variant="h5">Add Media</Typography>
          <AudioToggleButtonSource />
        </Stack>
        <Divider sx={{ my: 2 }} />
        {audioSource === AudioSource.RECORD && <InterfaceMediaRecord />}
        {audioSource === AudioSource.UPLOAD && (
          <InterfaceMediaUpload acceptedFileTypes={FileCategory.audio} />
        )}
        {audioSource === AudioSource.CONTENT && <InterfaceMediaContent />}
      </CardContent>
    </Card>
  );
};

export default MediaAdd;
