import {
  Card,
  CardContent,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { getVoiceStepindex } from 'common/utils';
import { useParams } from 'react-router-dom';
import { useGetVoiceQuery } from 'graphql/generated';
import { VoiceStep } from 'types/enums';

export default function ProgressStepper() {
  const { voiceID } = useParams<{ voiceID: string }>();
  const { data: voiceData } = useGetVoiceQuery({
    input: { id: voiceID },
  });
  const voice = voiceData?.getVoice?.data;
  const activeStep = getVoiceStepindex(voice?.step as VoiceStep);
  return (
    <Card>
      <CardContent sx={{ mt: 1 }}>
        <Stepper
          activeStep={0}
          orientation="horizontal"
        >
          {['Select', 'Clean', 'Train', 'Test'].map((step, index) => (
            <Step
              key={index}
              active={index === activeStep}
              completed={index < activeStep}
            >
              <StepLabel>
                <Typography variant="body2">{step}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </CardContent>
    </Card>
  );
}
