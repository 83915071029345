import { CopyAll, OpenInNew } from '@mui/icons-material';
import {
  Box,
  Card,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'use/snackbar';

export default function Deployment() {
  const { botID } = useParams();
  const { showSnackbar } = useSnackbar();

  const link = `https://app.duohub.ai/call/${botID}`;
  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    showSnackbar('Link copied to clipboard', 'success');
  };

  const handleOpenShareLink = () => {
    window.open(link, '_blank');
  };
  return (
    <Box mt={2}>
      <Card sx={{ p: 2 }}>
        <Typography variant="h4">Shareable Link</Typography>
        <Card sx={{ mt: 2, px: 2, py: 1 }}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              sx={{ backgroundColor: blueGrey[900] }}
              variant="body1"
            >
              {link}
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
            >
              <Tooltip
                arrow
                placement="top"
                title="Copy Link"
              >
                <IconButton onClick={handleCopyLink}>
                  <CopyAll />
                </IconButton>
              </Tooltip>
              <Tooltip
                arrow
                placement="top"
                title="Open in new tab"
              >
                <IconButton onClick={handleOpenShareLink}>
                  <OpenInNew />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </Card>
      </Card>
    </Box>
  );
}
