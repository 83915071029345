import { Stack, Typography, Paper, TextField, Skeleton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useGetMemoryQuery, useUpdateMemoryMutation } from 'graphql/generated';
import { useEffect, useState } from 'react';

export default function StartTitle() {
  const { memoryID } = useParams<{ memoryID: string }>();
  const [updateMemory] = useUpdateMemoryMutation();
  const { data: memoryData, isLoading } = useGetMemoryQuery({
    input: {
      id: memoryID,
    },
  });
  const memory = memoryData?.getMemory?.data;

  const [isEditing, setIsEditing] = useState(false);
  const [memoryName, setMemoryName] = useState(
    memory?.name || 'New Knowledge Graph',
  );

  useEffect(() => {
    setMemoryName(memory?.name || 'New Memory');
  }, [memory]);

  const handleTextClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMemoryName(event.target.value);
  };

  const handleSave = async () => {
    setIsEditing(false);
    if (memory) {
      await updateMemory({
        input: { id: memoryID, name: memoryName },
      });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
      >
        {isLoading ? (
          <Skeleton
            variant="text"
            width={100}
            height={40}
          />
        ) : isEditing ? (
          <Paper
            elevation={3}
            style={{ display: 'inline-block' }}
          >
            <TextField
              placeholder="Memory name"
              value={memoryName}
              onChange={handleInputChange}
              onBlur={handleSave}
              onKeyDown={handleKeyDown}
              autoFocus
              sx={{
                '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
              }}
              size="small"
              inputProps={{
                style: {
                  fontSize: 'inherit',
                  padding: '6px 10px',
                  border: 'none',
                },
                maxLength: 70,
              }}
            />
          </Paper>
        ) : (
          <Typography
            variant="h4"
            onClick={handleTextClick}
            style={{ cursor: 'pointer' }}
          >
            {memoryName}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
