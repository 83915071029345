import { Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import { useGetCallFactsQuery } from 'graphql/generated';
import { CallFactsAction } from 'types/enums';

export default function LogSummary({ callID }: { callID: string }) {
  const { data: callFacts } = useGetCallFactsQuery({
    input: {
      callID: callID,
      action: CallFactsAction.getsummary,
    },
  });
  const summary = callFacts?.getCallFacts?.data.summaries.summaries[0].content;
  console.log(callFacts);
  return summary ? (
    <Card>
      <CardContent>
        <Typography
          sx={{ textTransform: 'uppercase' }}
          variant="caption"
        >
          Summary
        </Typography>
        <Divider sx={{ opacity: 0.2, my: 1 }} />
        <Stack
          direction="column"
          spacing={1}
        >
          <Typography variant="body2">{summary}</Typography>
        </Stack>
      </CardContent>
    </Card>
  ) : null;
}
