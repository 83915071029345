import {
  fetchAuthSession,
  fetchUserAttributes,
  FetchUserAttributesOutput,
  UserAttributeKey,
} from 'aws-amplify/auth';

// Defines the structure for user authentication data
// Contains core user information and JWT details
type UserCredentials = {
  username: string;
  jwt: string;
  expiry: number;
  userAttributes: FetchUserAttributesOutput;
  challengeName?: string;
};

// List of user attributes we want to extract from the JWT payload
// These are standard AWS Cognito attributes
const userAttributes: ReadonlyArray<UserAttributeKey> = [
  'email',
  'email_verified',
  'family_name',
  'given_name',
  'sub',
] as const;

// Default empty state for user credentials
const defaultCredentials: UserCredentials = {
  username: '',
  jwt: '',
  expiry: -1,
  userAttributes: {},
};

// Helper function to fetch the current authentication session
// Returns null if no valid session exists
async function getToken() {
  try {
    const creds = await fetchAuthSession({ forceRefresh: false });
    return creds;
  } catch (err) {
    return null;
  }
}

// Fetches the current user's attributes from Cognito
// Returns empty object if fetch fails or no user is logged in
export async function getCurrentUserAttributes() {
  try {
    const attrs = await fetchUserAttributes();
    return attrs;
  } catch (err) {
    return {};
  }
}

// Retrieves the current user's authentication credentials
// Optionally refreshes the user attributes from Cognito
export async function getAuthCredentials(
  shouldRefresh = false,
): Promise<UserCredentials> {
  const output = { ...defaultCredentials };
  const creds = await getToken();

  if (!creds) {
    return output;
  }

  // Extract JWT token and expiry from the session
  const idToken = creds.tokens?.idToken;
  output.jwt = idToken?.toString() || '';
  output.expiry = idToken?.payload?.exp || -1;

  if (output.jwt) {
    // Either fetch fresh attributes from Cognito or extract from JWT payload
    output.userAttributes = shouldRefresh
      ? await getCurrentUserAttributes()
      : Object.entries(idToken?.payload || {}).reduce((acc, [key, val]) => {
          if (userAttributes.includes(key)) {
            acc[key] = String(val);
          }
          return acc;
        }, {} as FetchUserAttributesOutput);
  }

  return output;
}

// Fetches current credentials, optionally forcing a refresh of the auth session
// Returns default empty credentials if no valid session exists
export async function getCurrentCredentials(
  forceRefresh = false,
): Promise<UserCredentials> {
  try {
    await fetchAuthSession({ forceRefresh });
    return getAuthCredentials(forceRefresh);
  } catch (err) {
    return { ...defaultCredentials };
  }
}
