import { Typography } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import LogCall from './call';
import Layout from 'layouts/index';
import LogCallLog from './call/log';

export default function LogsIndex() {
  return (
    <Layout
      backArrow
      startTitle={<Typography variant="h4">Logs</Typography>}
    >
      <Routes>
        <Route
          path="/call"
          element={<LogCall />}
        />
        <Route
          path="/call/:callID"
          element={<LogCallLog />}
        />
      </Routes>
    </Layout>
  );
}
