import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material';
import { formatCallDuration, formatDate } from 'common/utils';
import { LogFact, LogMessages, LogSummary } from 'components/molecules';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCallQuery, useMarkCallReadMutation } from 'graphql/generated';

export default function LogCallLog() {
  const { callID } = useParams<{ callID: string }>();
  const { data: rawCallData } = useGetCallQuery(
    {
      input: { id: callID, getUsers: true },
    },
    { skip: !callID },
  );
  const call = rawCallData?.getCall?.data?.[0];
  const [markCallRead] = useMarkCallReadMutation();

  useEffect(() => {
    if (call?.call.unread) {
      const markAsRead = async () => {
        const result = await markCallRead({ input: { id: call?.call?.id } });
        console.log('mark call read result', result);
      };
      markAsRead();
    }
  }, [call, markCallRead]);

  return (
    <Box>
      <Stack
        direction="column"
        sx={{ m: 2 }}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
          >
            <Typography variant="h5">Call</Typography>
            {call ? (
              <Typography variant="h5">
                with {call.participant?.firstName} {call.participant?.lastName}{' '}
                on {formatDate(call.call?.startTime, 'ccc - MMM d, yyyy')} for{' '}
                {formatCallDuration(call.call?.callDuration)}
              </Typography>
            ) : (
              <Skeleton
                variant="text"
                width="350px"
                height={25}
              />
            )}
          </Stack>
          <Typography
            sx={{ opacity: 0.4 }}
            variant="h5"
          >
            {callID}
          </Typography>
        </Stack>
        <Divider sx={{ opacity: 0.2, my: 1 }} />
        <Stack
          direction="column"
          spacing={2}
        >
          <LogFact callID={callID} />
          <LogSummary callID={callID} />
          <LogMessages callID={callID} />
        </Stack>
      </Stack>
    </Box>
  );
}
