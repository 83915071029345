import {
  AudioFile,
  Description,
  EditNote,
  Language,
  Mic,
  VideoFile,
  YouTube,
} from '@mui/icons-material';
import { FileType } from 'types/enums';

export const categoryConfig = {
  [FileType.youtube]: {
    icon: <YouTube fontSize="small" />,
    text: 'YouTube video',
  },
  [FileType.video_upload]: {
    icon: <VideoFile fontSize="small" />,
    text: 'Video file',
  },
  [FileType.audio_recording]: {
    icon: <Mic fontSize="small" />,
    text: 'Audio recording',
  },
  [FileType.audio_upload]: {
    icon: <AudioFile fontSize="small" />,
    text: 'Audio file',
  },
  [FileType.website]: {
    icon: <Language fontSize="small" />,
    text: 'Webpage',
  },
  [FileType.document]: {
    icon: <Description fontSize="small" />,
    text: 'Document',
  },
  [FileType.sitemap]: {
    text: 'Sitemap',
  },
  [FileType.website_bulk]: {
    text: 'Webpages',
  },
  [FileType.text]: { icon: <EditNote fontSize="small" />, text: 'Text' },
};
