import {
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
  TextField,
  InputAdornment,
} from '@mui/material';

export default function MemoryNewStepSettings() {
  return (
    <Card>
      <CardContent>
        <Typography>Settings</Typography>
        <Divider sx={{ my: 2, opacity: 0.5 }} />
        <Stack
          direction="column"
          spacing={2}
        >
          <Card sx={{ p: 2 }}>
            <Stack spacing={1}>
              <Typography
                variant="subtitle1"
                fontWeight="bold"
              >
                Chunk Size
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
              >
                Number of characters per chunk (10-5000)
              </Typography>
              <TextField
                type="number"
                defaultValue={250}
                inputProps={{
                  min: 10,
                  max: 5000,
                }}
                size="small"
              />
            </Stack>
          </Card>

          <Card sx={{ p: 2 }}>
            <Stack spacing={1}>
              <Typography
                variant="subtitle1"
                fontWeight="bold"
              >
                Chunk Overlap
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
              >
                Percentage of overlap between chunks (0-100%)
              </Typography>
              <TextField
                type="number"
                defaultValue={10}
                inputProps={{
                  min: 0,
                  max: 100,
                }}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Card>
        </Stack>
      </CardContent>
    </Card>
  );
}
