import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import {
  AiLanguages,
  AiModels,
  AiProvider,
  Model,
  ModelOptions,
} from '@duohub/types';
import {
  AIModels,
  AIProvider,
  AIType,
  Languages,
  MODEL_PROVIDERS,
} from 'types/enums';
import { EnumSelect, Select, Section } from 'components/atoms';
import { menuOptions } from 'common/utils';
import { useSnackbar } from 'use/snackbar';
import { useParams } from 'react-router-dom';
import { useGetModelQuery, useUpdateModelMutation } from 'graphql/generated';

export function ModelTranscriber() {
  const { botID } = useParams();
  const { data } = useGetModelQuery({
    input: {
      botID,
    },
  });
  const models = useMemo(() => data?.getModel?.data || [], [data]);
  const transcriberModelIndex = models?.findIndex((m) => m.type === AIType.ASR);
  const transcriberModel: Model | undefined = transcriberModelIndex
    ? models[transcriberModelIndex]
    : undefined;
  const availableProviders = Object.keys(
    MODEL_PROVIDERS[AIType.ASR] || {},
  ) as AIProvider[];
  const selectedProvider = (transcriberModel?.provider ||
    AIProvider.DEEPGRAM) as keyof (typeof MODEL_PROVIDERS)[typeof AIType.ASR];
  const availableModels = MODEL_PROVIDERS[AIType.ASR]?.[selectedProvider] || [];
  const selectedModel = (transcriberModel?.model ||
    AIModels.NOVA_2) as AIModels;
  const { showSnackbar } = useSnackbar();
  const [changed, setChanged] = useState(false);
  const [updateModel] = useUpdateModelMutation();

  const changeStatus = useCallback(
    (value: boolean): void => {
      if (!changed) {
        setChanged(value);
      }
    },
    [changed, setChanged],
  );

  const handleModelUpdate = useCallback(
    (updatedModel: Partial<Model>): void => {
      if (transcriberModel) {
        const newModel: Model = {
          ...transcriberModel,
          ...updatedModel,
          options: {
            ...transcriberModel?.options,
            ...(updatedModel?.options || {}),
          },
        };
        updateModel({ input: newModel })
          .then(() => {
            changeStatus(true);
          })
          .catch((error) => {
            console.error('Failed to update model:', error);
            showSnackbar('Failed to update model', 'error');
          });
      }
    },
    [changeStatus, transcriberModel, updateModel, showSnackbar],
  );
  useEffect(() => {
    if (
      transcriberModel?.options &&
      typeof transcriberModel?.options === 'string'
    ) {
      try {
        const parsedOptions = JSON.parse(
          transcriberModel?.options,
        ) as ModelOptions;
        handleModelUpdate({ options: parsedOptions });
      } catch (error) {
        console.error('Failed to parse model options:', error);
        showSnackbar('Failed to parse model options', 'error');
      }
    }
  }, [handleModelUpdate, showSnackbar, transcriberModel?.options, updateModel]);

  const handleProviderChange = (value: AiProvider): void =>
    handleModelUpdate({ provider: value });

  const handleLanguageChange = (value: AiLanguages): void =>
    handleModelUpdate({ language: value });

  const handleModelChange = (value: AiModels): void =>
    handleModelUpdate({ model: value });

  return (
    <Box
      component="section"
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <Box mb={2}>
        <Typography
          color="white"
          variant="h4"
        >
          Transcription Configuration
        </Typography>
        <Typography variant="caption">
          This section allows you to configure the transcription settings for
          the assistant.
        </Typography>
      </Box>
      <Box my={2}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Section label="Provider">
              <Select
                value={selectedProvider || AIProvider.DEEPGRAM}
                defaultValue={AIProvider.DEEPGRAM}
                options={menuOptions(availableProviders)}
                onChange={(val) => handleProviderChange(val as AiProvider)}
              />
            </Section>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Section label="Languages">
              <EnumSelect
                enumOptions={Languages}
                value={transcriberModel?.language}
                defaultValue={Languages.EN}
                onChange={(val) => handleLanguageChange(val)}
                fullWidth
                size="small"
              />
            </Section>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Section label="Model">
              <Select
                value={selectedModel || AIModels.NOVA_2}
                defaultValue={AIModels.NOVA_2}
                options={menuOptions(availableModels)}
                onChange={(val) => handleModelChange(val as AiModels)}
              />
            </Section>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
