import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UploadQueueContext } from '..';
import { sampleApi } from 'graphql/samples';
import {
  Box,
  Collapse,
  Divider,
  Fade,
  IconButton,
  List,
  Paper,
  Stack,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { Close, ExpandLess, ExpandMore } from '@mui/icons-material';
import { UploadItem } from './item';
import { UploadStatus } from 'types/enums';

export const UploadQueueComponent = () => {
  const {
    queue,
    removeFromQueue,
    uploadingIndex,
    isVisible,
    isExpanded,
    setIsVisible,
    setIsExpanded,
    stopAndClearQueue,
  } = useContext(UploadQueueContext);
  const dispatch = useDispatch();

  const [canceledUploads, setCanceledUploads] = useState<number[]>([]);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const cancelUpload = (index: number) => {
    setCanceledUploads([...canceledUploads, index]);
  };
  const handleCloseClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (uploadingIndex >= 0) {
      setIsConfirmDialogOpen(true);
    } else {
      clearQueueAndHide();
    }
  };

  const clearQueueAndHide = () => {
    stopAndClearQueue();
    setIsVisible(false);
  };

  const handleConfirmCancel = () => {
    cancelAllUploads();
    clearQueueAndHide();
    setIsConfirmDialogOpen(false);
  };

  const cancelAllUploads = () => {
    queue.forEach((_, index) => cancelUpload(index));
  };

  useEffect(() => {
    if (queue.length > 0) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [queue, setIsVisible]);

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  const completedUploads = queue.filter((item) => item.progress === 100).length;
  const allUploadsCompleted =
    completedUploads === queue.length && queue.length > 0;

  useEffect(() => {
    if (allUploadsCompleted) {
      dispatch(sampleApi.util.invalidateTags([{ type: 'Sample', id: 'LIST' }]));
    }
  }, [allUploadsCompleted, dispatch]);

  return (
    <>
      <Fade in={isVisible}>
        <Paper
          elevation={3}
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            width: 350,
            maxHeight: isExpanded ? '70vh' : '56px',
            overflow: 'hidden',
            transition: 'all 0.3s ease',
            zIndex: 1000,
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: 'pointer',
              py: 1,
              px: 2,
            }}
            onClick={toggleExpanded}
          >
            <Typography variant="subtitle1">
              {allUploadsCompleted
                ? `${queue.length} file${queue.length !== 1 ? 's' : ''} uploaded`
                : uploadingIndex >= 0
                  ? `Uploading ${uploadingIndex + 1} of ${queue.length}`
                  : `${queue.length} file${queue.length !== 1 ? 's' : ''} queued`}
            </Typography>
            <Box>
              <IconButton onClick={handleCloseClick}>
                <Close fontSize="small" />
              </IconButton>
              <IconButton size="small">
                {isExpanded ? <ExpandMore /> : <ExpandLess />}
              </IconButton>
            </Box>
          </Stack>
          <Divider sx={{ opacity: 0.5 }} />
          <Collapse in={isExpanded}>
            <List sx={{ maxHeight: 'calc(70vh - 56px)', overflowY: 'auto' }}>
              {queue.map((item, index) => (
                <UploadItem
                  key={index}
                  file={item.appFile}
                  progress={item.progress}
                  onRemove={() => removeFromQueue(index)}
                  onCancel={() => cancelUpload(index)}
                  status={
                    canceledUploads.includes(index)
                      ? UploadStatus.canceled
                      : index === uploadingIndex
                        ? UploadStatus.uploading
                        : item.progress === 100
                          ? UploadStatus.completed
                          : UploadStatus.pending
                  }
                  createSample={item.createSample}
                  sampleIndexID={item.sampleIndexID}
                />
              ))}
            </List>
          </Collapse>
        </Paper>
      </Fade>
      <Dialog
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 2, py: 1 }}
        >
          <Typography variant="h4">Cancel Uploads</Typography>
          <IconButton onClick={() => setIsConfirmDialogOpen(false)}>
            <Close fontSize="small" />
          </IconButton>
        </Stack>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to cancel all uploads?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', px: 2, py: 2 }}>
          <Button
            size="small"
            onClick={() => setIsConfirmDialogOpen(false)}
            sx={{ fontWeight: 'bold' }}
          >
            Continue
          </Button>
          <Button
            onClick={handleConfirmCancel}
            color="error"
            variant="contained"
            size="small"
            sx={{ fontWeight: 'bold' }}
          >
            Cancel Uploads
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
