import { useRef, useEffect } from 'react';
import ForceGraph3D from '3d-force-graph';
import { blue } from '@mui/material/colors';

interface GraphData {
  nodes: {
    id: string;
    name?: string;
    val?: number;
    color?: string;
    description?: string;
  }[];
  links: {
    source: string;
    target: string;
  }[];
}

interface ForceDirectedGraph3DProps {
  data: GraphData;
  width?: number;
  height?: number;
  viewport?: boolean;
}

export function ForceDirectedGraph3D({
  data,
  width = 1000,
  height = 500,
  viewport = false,
}: ForceDirectedGraph3DProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const graphRef = useRef<any>(null);

  console.log('data:', data);

  useEffect(() => {
    if (!containerRef.current) return;

    const Graph = ForceGraph3D()(containerRef.current);
    const radius = Math.cbrt(data.nodes.length) * 10;

    const graphWidth = viewport ? window.innerWidth : width;
    const graphHeight = viewport ? window.innerHeight : height;

    Graph.width(graphWidth)
      .height(graphHeight)
      .backgroundColor('#000')
      .nodeColor((node: any) => node.color || '#ffffff')
      .linkColor(() => '#ffffff')
      .enableNavigationControls(true)
      .nodeLabel((node: any) => {
        let label = node.name || node.id;
        return `<div style="color: #ffffff;">
                  <div style="border-radius: 8px; background: rgba(0, 0, 0, 0.8); padding: 16px;">
                    <h3 style="margin: 0;">
                      ${label}
                    </h5>
                    <div style="margin-top: 8px;">
                      ${node.description}
                    </div>
                  </div>
                </div>`;
      })
      .nodeThreeObject(null)
      .showNavInfo(false)
      .graphData(data)
      .d3Force('center', null)
      .d3Force('charge', null)
      .d3Force('collide', null)
      .d3Force('random', (alpha: number) => {
        const boxSize = Math.cbrt(data.nodes.length) * 100;
        data.nodes.forEach((node: any) => {
          if (!node.initialPosition) {
            node.initialPosition = {
              x: (Math.random() - 0.5) * boxSize,
              y: (Math.random() - 0.5) * boxSize,
              z: (Math.random() - 0.5) * boxSize,
            };
          }
          node.x += (node.initialPosition.x - node.x) * alpha;
          node.y += (node.initialPosition.y - node.y) * alpha;
          node.z += (node.initialPosition.z - node.z) * alpha;
        });
      })
      .linkDirectionalParticles(2)
      .linkDirectionalParticleWidth(2)
      .linkDirectionalParticleColor(() => blue[200])
      .linkDirectionalParticleSpeed(() => 0.00075);
    Graph.linkOpacity(0.1);

    Graph.numDimensions(3);

    // Adjust initial camera position to zoom out
    Graph.cameraPosition({ z: radius * 5 });

    graphRef.current = Graph;

    return () => {
      graphRef.current && graphRef.current._destructor();
    };
  }, [data, height, width, viewport]);

  useEffect(() => {
    if (graphRef.current) {
      graphRef.current.graphData(data);
    }
  }, [data]);

  return <div ref={containerRef} />;
}
