import { Box, Container } from '@mui/material';
import React, { useMemo } from 'react';
import MiniDrawer from '../components/organisms/drawer';
import ModalUpgrade from 'components/organisms/modal-upgrade';
import { useGetUserQuery, useLazyGetCheckoutQuery } from 'graphql/generated';
import { LoadingButton } from '@mui/lab';
import { ColorVariant } from 'types/app';
import { usePro } from 'use/pro';
import { PlanType } from 'types/enums';
import mixpanel from 'mixpanel-browser';

interface LayoutProps {
  children: React.ReactNode;
  endAction?: React.ReactNode;
  startTitle?: React.ReactNode;
  backArrow?: boolean;
  backArrowPath?: string;
  middleAction?: React.ReactNode;
  noticeBanner?: string;
}

export default function Layout({
  children,
  startTitle,
  endAction,
  backArrow,
  backArrowPath,
  middleAction,
  noticeBanner,
}: LayoutProps) {
  const { data: rawUserData } = useGetUserQuery({ input: {} });
  const { setOpenModal } = usePro();
  const user = rawUserData?.getUser;
  const [getCheckout, { isLoading: isGettingCheckout }] =
    useLazyGetCheckoutQuery();

  mixpanel.identify(user?.id);
  mixpanel.people.set({
    $name: user?.firstName + ' ' + user?.lastName,
    $email: user?.email,
    plan: user?.plan,
  });

  const usageWarning = useMemo(() => {
    if (!user) return null;

    const usageTypes = [
      { used: user.ingressTokensUsed, limit: user.ingressTokensLimit },
      { used: user.egressCallMinutesUsed, limit: user.egressCallMinutesLimit },
    ];

    const maxUsagePercentage = Math.max(
      ...usageTypes.map(({ used = 0, limit = 1 }) => (used / limit) * 100),
    );

    const warningLevels: {
      threshold: number;
      message: string;
      severity: ColorVariant;
    }[] = [
      {
        threshold: 90,
        message:
          'You are critically low on usage limits. Please upgrade your plan to continue.',
        severity: 'error',
      },
      {
        threshold: 80,
        message:
          'You are running low on usage limits. Consider upgrading your plan.',
        severity: 'warning',
      },
      {
        threshold: 70,
        message:
          'You are approaching your usage limits. You may want to consider upgrading your plan.',
        severity: 'info',
      },
    ];

    return (
      warningLevels.find((level) => maxUsagePercentage >= level.threshold) ||
      null
    );
  }, [user]);

  const handleGetCheckout = async () => {
    try {
      if (user?.plan === PlanType.starter) {
        const result = await getCheckout({
          input: {
            priceID: process.env.REACT_APP_PRO_PRICE_ID,
            planType: PlanType.pro,
          },
        });
        const checkoutUrl = result.data?.getCheckout?.url;
        if (checkoutUrl) {
          window.location.href = checkoutUrl;
        } else {
          console.error('Checkout URL not received');
        }
      } else {
        setOpenModal(true);
      }
    } catch (error) {
      console.error('Error handling checkout:', error);
    }
  };

  const displayNoticeBanner = noticeBanner || usageWarning?.message;
  const noticeBannerSeverity = usageWarning?.severity || 'error';

  return (
    <Box>
      <ModalUpgrade />
      <MiniDrawer
        backArrow={backArrow}
        backArrowPath={backArrowPath}
        startTitle={startTitle}
        middleAction={middleAction}
        endAction={endAction}
        noticeBannerSeverity={noticeBannerSeverity}
        noticeBanner={displayNoticeBanner}
        noticeBannerCTA={
          <LoadingButton
            loading={isGettingCheckout}
            size="small"
            variant="contained"
            sx={{ height: 24, fontWeight: 'bold' }}
            onClick={handleGetCheckout}
          >
            Upgrade
          </LoadingButton>
        }
      >
        <Container
          sx={{ mb: 4 }}
          maxWidth="xl"
        >
          {children}
        </Container>
      </MiniDrawer>
    </Box>
  );
}
