import { Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import { useGetCallFactsQuery } from 'graphql/generated';
import { CallFactsAction } from 'types/enums';

export default function LogFact({ callID }: { callID: string }) {
  const { data: callFacts } = useGetCallFactsQuery({
    input: {
      callID: callID,
      action: CallFactsAction.getfacts,
    },
  });
  const facts = callFacts?.getCallFacts?.data?.facts;
  return (
    <Card>
      <CardContent>
        <Typography
          sx={{ textTransform: 'uppercase' }}
          variant="caption"
        >
          Facts
        </Typography>
        <Divider sx={{ opacity: 0.2, my: 1 }} />
        <Stack
          direction="column"
          spacing={1}
        >
          {facts &&
            facts?.facts?.map((fact, index) => (
              <>
                <Typography
                  key={`fact-${index}`}
                  variant="body2"
                >
                  {fact.fact}
                </Typography>
                {index < facts.facts.length - 1 && (
                  <Divider sx={{ opacity: 0.3 }} />
                )}
              </>
            ))}
        </Stack>
      </CardContent>
    </Card>
  );
}
