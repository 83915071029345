import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Dialog,
  Divider,
  IconButton,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import {
  useLazyGetWebsiteMetadataQuery,
  useLazyGetSitemapMetadataQuery,
  useUpdateFileMutation,
} from 'graphql/generated';
import { FileCategory, FileType } from 'types/enums';
import mixpanel from 'mixpanel-browser';
import SingleWebsiteForm from './dialog-content-add-website-single';
import BulkWebsiteForm from './dialog-content-add-website-bulk';
import SitemapForm from './dialog-content-add-website-sitemap';
import { useContent } from 'use/content';

interface DialogContentAddWebsiteProps {
  open: boolean;
  onClose: () => void;
  onFileAdded?: () => void;
}

export default function DialogContentAddWebsite({
  open,
  onClose,
  onFileAdded,
}: DialogContentAddWebsiteProps) {
  const { handleProcessSelectedFiles } = useContent();
  const [updateFile, { isLoading: isLoadingUpdateFile }] =
    useUpdateFileMutation();
  const [getWebsiteMetadata, { isLoading: isLoadingGetWebsiteMetadata }] =
    useLazyGetWebsiteMetadataQuery();
  const [getSitemapMetadata, { isLoading: isLoadingGetSitemapMetadata }] =
    useLazyGetSitemapMetadataQuery();

  const [value, setValue] = useState(FileType.website);
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const [bulkUrls, setBulkUrls] = useState('');
  const [bulkName, setBulkName] = useState('');
  const [isProcessingCsv, setIsProcessingCsv] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState<string>('');
  const [rootSitemap, setRootSitemap] = useState('');
  const [pages, setPages] = useState(0);

  useEffect(() => {
    if (!open) {
      clearFields();
    }
  }, [open]);

  const clearFields = () => {
    setUrl('');
    setTitle('');
    setBulkUrls('');
    setBulkName('');
    setRootSitemap('');
    setPages(0);
    setUploadedFileName('');
  };

  const createWebsiteFile = async (singleUrl: string, singleTitle: string) => {
    try {
      await updateFile({
        input: {
          externalURI: value === FileType.sitemap ? rootSitemap : singleUrl,
          category: FileCategory.web,
          fileType:
            value === FileType.sitemap
              ? FileType.sitemap
              : value === FileType.website_bulk
                ? FileType.website_bulk
                : FileType.website,
          hiddenFilesTotal: value === FileType.sitemap ? pages : undefined,
          name: singleTitle,
          originRegion: process.env.REACT_APP_AWS_REGION || 'eu-west-2',
        },
      }).unwrap();

      mixpanel.track('add_file', {
        'File Extension':
          value === FileType.sitemap ? 'sitemap' : FileType.website,
      });

      if (onFileAdded) {
        onFileAdded();
      }
    } catch (error) {
      console.error('Error creating file:', error);
    }
  };

  const handleSubmit = async () => {
    if (value === FileType.website) {
      await createWebsiteFile(url, title);
    } else if (value === FileType.sitemap) {
      await createWebsiteFile(url, title);
    } else {
      const csvContent = bulkUrls
        .split('\n')
        .map((url) => url.trim())
        .filter((url) => url !== '')
        .join('\n');

      const csvFile = new File([csvContent], `${bulkName || 'bulk_urls'}.csv`, {
        type: 'text/csv',
      });

      handleProcessSelectedFiles([csvFile], FileCategory.web);
    }

    clearFields();
    onClose();
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    if (newValue !== null) {
      setValue(newValue as FileType);
      clearFields();
    }
  };

  const tabs = [
    { value: FileType.website, label: 'Single' },
    { value: FileType.website_bulk, label: 'Bulk' },
    { value: FileType.sitemap, label: 'Sitemap' },
  ];

  const isLoading =
    isLoadingUpdateFile ||
    isLoadingGetWebsiteMetadata ||
    isLoadingGetSitemapMetadata ||
    isProcessingCsv;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={
        value === FileType.website || value === FileType.sitemap ? 'sm' : 'md'
      }
      fullWidth
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        p={2}
      >
        <Box width={115}>
          <Typography variant="h4">Add Website</Typography>
        </Box>
        <Paper
          elevation={0}
          sx={{ display: 'flex', borderRadius: 40, height: 28, mb: 2 }}
        >
          <ToggleButtonGroup
            size="small"
            value={value}
            onChange={handleChange}
            exclusive
          >
            {tabs.map((tab) => (
              <ToggleButton
                key={tab.value}
                value={tab.value}
                aria-label={tab.label}
                sx={{
                  borderRadius: 40,
                  height: 28,
                  fontSize: 12,
                  px: 1.5,
                  backgroundColor: '#343434',
                  color: 'text.primary',
                  '&.Mui-selected': {
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'black',
                    },
                  },
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              >
                {tab.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Paper>
        <Box
          width={115}
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </Stack>
      <Divider />
      <Box p={2}>
        {value === FileType.website && (
          <SingleWebsiteForm
            url={url}
            title={title}
            onUrlChange={setUrl}
            onTitleChange={setTitle}
            isLoading={isLoading}
            getWebsiteMetadata={getWebsiteMetadata}
          />
        )}
        {value === FileType.website_bulk && (
          <BulkWebsiteForm
            bulkUrls={bulkUrls}
            bulkName={bulkName}
            onBulkUrlsChange={setBulkUrls}
            onBulkNameChange={setBulkName}
            isProcessingCsv={isProcessingCsv}
            setIsProcessingCsv={setIsProcessingCsv}
            uploadedFileName={uploadedFileName}
            setUploadedFileName={setUploadedFileName}
          />
        )}
        {value === 'sitemap' && (
          <SitemapForm
            url={url}
            title={title}
            onUrlChange={setUrl}
            onTitleChange={setTitle}
            isLoading={isLoading}
            getSitemapMetadata={getSitemapMetadata}
            setRootSitemap={setRootSitemap}
            setPages={setPages}
          />
        )}
      </Box>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        p={2}
      >
        <LoadingButton
          size="small"
          sx={{ fontWeight: 'bold', height: 28 }}
          variant="contained"
          onClick={handleSubmit}
          loading={isLoading}
          disabled={
            value === FileType.website_bulk
              ? !bulkUrls || isProcessingCsv
              : !url || !title
          }
        >
          Add
        </LoadingButton>
      </Stack>
    </Dialog>
  );
}
