import { LoadingButton } from '@mui/lab';
import {
  Card,
  CardContent,
  Chip,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { RowMiniSampleVoiceAI } from 'components/atoms';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetAiSampleFilesQuery,
  useGetVoiceQuery,
  useLazyGetTrainedVoiceSampleQuery,
  useUpdateVoiceMutation,
} from 'graphql/generated';
import { VoiceStep } from 'types/enums';

export default function VoiceNewTest() {
  const { voiceID } = useParams<{ voiceID: string }>();
  const [getTrainedVoiceSample, { isLoading: isLoadingVoiceSample }] =
    useLazyGetTrainedVoiceSampleQuery();
  const { refetch: refetchVoices } = useGetVoiceQuery({
    input: {},
  });
  const { data: aiSampleFileData, refetch } = useGetAiSampleFilesQuery({
    input: {
      voiceID,
    },
  });
  const [updateVoice] = useUpdateVoiceMutation();
  const aiSampleFiles = useMemo(
    () => aiSampleFileData?.getAiSampleFiles?.data,
    [aiSampleFileData],
  );
  const [text, setText] = useState('');

  const { data: voiceData } = useGetVoiceQuery({
    input: { id: voiceID },
  });
  const voice = voiceData?.getVoice?.data;

  const getTrainedVoiceSampleHandler = async () => {
    try {
      const { data } = await getTrainedVoiceSample({
        input: {
          voiceID,
          text,
        },
      });

      const aiFileSample = data?.getTrainedVoiceSample?.data;
      if (aiFileSample) {
        await refetch();
        if (voice?.step !== VoiceStep.complete) {
          await updateVoice({
            input: {
              id: voiceID,
              step: VoiceStep.complete,
            },
          });
          await refetchVoices();
        }
      }
    } catch (error) {
      console.error('Error getting trained voice sample:', error);
    }
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  return (
    <Stack
      direction="column"
      spacing={1.5}
    >
      <Card>
        <CardContent>
          <Stack
            direction="column"
            spacing={3}
          >
            <Typography variant="h5">Test Voice</Typography>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
            >
              <TextField
                size="small"
                label="Text"
                fullWidth
                placeholder="Enter some text to test your voice"
                value={text}
                onChange={handleTextChange}
              />
              <LoadingButton
                variant="contained"
                color="primary"
                size="small"
                onClick={getTrainedVoiceSampleHandler}
                sx={{ fontWeight: 'bold' }}
                loading={isLoadingVoiceSample}
              >
                Test
              </LoadingButton>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Stack
            direction="column"
            spacing={1.5}
          >
            <Stack
              direction="row"
              spacing={1.5}
              alignItems="center"
              sx={{ px: 0.5 }}
            >
              <Typography variant="h5">TTS Samples</Typography>
              <Chip
                label={aiSampleFiles?.length || 0}
                size="small"
              />
            </Stack>
            {aiSampleFiles?.map((aiSampleFile) => (
              <RowMiniSampleVoiceAI
                key={aiSampleFile.id}
                aiSampleFile={aiSampleFile}
              />
            ))}
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}
