import { ReactNode, useCallback, useEffect, useState } from 'react';
import {
  List,
  Toolbar,
  Box,
  CssBaseline,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  Tooltip,
  Collapse,
  Stack,
  Skeleton,
  Typography,
} from '@mui/material';
import {
  ExpandMore,
  ExpandLess,
  AccountBalanceWalletOutlined,
  AccountBalanceWallet,
  AutoAwesome,
  Logout,
  HelpCenter,
} from '@mui/icons-material';
import { useApp } from 'use/app';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCurrentUser, signOut } from 'aws-amplify/auth';
import { useUser } from 'use/user';
import { v4 as uuid } from 'uuid';
import { ColorVariant, SideBarItemProps } from 'types/app';
import { MainSideBarItems } from 'common/nav';
import { useSnackbar } from 'use/snackbar';
import { AppBar, Drawer, DrawerHeader } from './components/skeleton';
import UserTitle from './components/usertitle';
import SidebarItem from './components/sidebaritem';
import { ButtonBack, IconAlert, Logo } from 'components/atoms';
import { yellow } from '@mui/material/colors';
import { usePro } from 'use/pro';
import { useGetUserQuery } from 'graphql/user';
import { PlanType } from 'types/enums';
import { useDispatch } from 'react-redux';
import { resetState } from 'store';
import { Loading } from 'components/atoms';

interface AppDrawerProps {
  children: ReactNode;
  endAction?: ReactNode;
  startTitle?: ReactNode;
  backArrow?: boolean;
  backArrowPath?: string;
  middleAction?: ReactNode;
  noticeBanner?: string;
  noticeBannerSeverity?: ColorVariant;
  noticeBannerCTA?: ReactNode;
}

export default function AppDrawer({
  endAction,
  startTitle,
  children,
  backArrow,
  backArrowPath,
  middleAction,
  noticeBanner,
  noticeBannerSeverity,
  noticeBannerCTA,
}: AppDrawerProps) {
  const { drawerOpen, accountSettingsOpen, setAccountSettingsOpen } = useApp();
  const { showSnackbar } = useSnackbar();
  const { setOpenModal } = usePro();
  const { getUser } = useUser();
  const dispatch = useDispatch();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      await getUser();
    };
    fetchUser();
  }, [getUser]);

  const { data } = useGetUserQuery({
    input: {},
  });

  const { firstName, lastName } = data?.getUser ?? {};

  const navigate = useNavigate();
  const location = useLocation();

  const checkAuthState = useCallback(async () => {
    try {
      await getCurrentUser();
      return true;
    } catch {
      return false;
    }
  }, []);

  const handleSignOut = async () => {
    try {
      const isAuthenticated = await checkAuthState();
      if (!isAuthenticated) {
        navigate('/signin');
      }
      setIsLoggingOut(true);
      await signOut();
      dispatch(resetState());
      navigate('/signin');
    } catch (err) {
      showSnackbar('Failed to sign out', 'error');
    } finally {
      setIsLoggingOut(false);
    }
  };

  const handleSignOutClick = () => {
    handleSignOut();
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        elevation={1}
        position="fixed"
        open={drawerOpen}
        sx={{ minHeight: '48px!important' }}
      >
        {noticeBanner && (
          <Stack
            direction="row"
            sx={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: (theme) =>
                theme.palette[noticeBannerSeverity].dark,
              color: 'white',
              padding: 1,
              pr: 2,
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
            >
              <IconAlert severity={noticeBannerSeverity} />
              <Typography>{noticeBanner}</Typography>
            </Stack>
            {noticeBannerCTA}
          </Stack>
        )}
        <Toolbar
          disableGutters
          sx={{
            minHeight: '48px!important',
            backdropFilter: 'blur(10px)',
            WebkitBackdropFilter: 'blur(10px)',
            borderBottom: '0.1px solid #2e2e2e',
          }}
        >
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            px={backArrow ? 2 : 4}
          >
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
            >
              {backArrow && <ButtonBack backArrowPath={backArrowPath} />}
              {startTitle ? startTitle : <UserTitle />}
            </Stack>
            {middleAction && middleAction}
            {endAction && endAction}
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        elevation={0}
        variant="permanent"
        open={drawerOpen}
      >
        <DrawerHeader sx={{ mt: 1 }}>
          <Box sx={{ ml: 1.5 }}>
            <Logo size="drawer" />
          </Box>
        </DrawerHeader>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          height="100%"
        >
          <Box>
            <List>
              {MainSideBarItems.map((item: SideBarItemProps) => {
                return (
                  <SidebarItem
                    key={uuid()}
                    title={item.title}
                    iconFilled={item.iconFilled}
                    iconOutlined={item.iconOutlined}
                    path={item.path}
                  />
                );
              })}
            </List>
          </Box>
          <Box>
            <Collapse in={accountSettingsOpen}>
              <List>
                {!data?.getUser?.plan ? (
                  <ListItem
                    disablePadding
                    sx={{ display: 'block', pl: 4 }}
                  >
                    <Skeleton>
                      <Tooltip
                        title="Get Pro"
                        placement="right"
                      >
                        <ListItemButton
                          onClick={() => setOpenModal(true)}
                          sx={{
                            pl: 3,
                          }}
                        >
                          <ListItemIcon>
                            <AutoAwesome sx={{ color: yellow[300] }} />
                          </ListItemIcon>
                          <ListItemText primary="Get Pro" />
                        </ListItemButton>
                      </Tooltip>
                    </Skeleton>
                  </ListItem>
                ) : data?.getUser?.plan === PlanType.free ? (
                  <ListItem
                    disablePadding
                    sx={{ display: 'block' }}
                  >
                    <Tooltip
                      title="Get Pro"
                      placement="right"
                    >
                      <ListItemButton
                        onClick={() => setOpenModal(true)}
                        sx={{
                          pl: 3,
                        }}
                      >
                        <ListItemIcon>
                          <AutoAwesome sx={{ color: yellow[300] }} />
                        </ListItemIcon>
                        <ListItemText primary="Get Pro" />
                      </ListItemButton>
                    </Tooltip>
                  </ListItem>
                ) : null}

                <ListItem
                  disablePadding
                  sx={{ display: 'block' }}
                >
                  <Tooltip
                    title="Account"
                    placement="right"
                  >
                    <ListItemButton
                      onClick={() => navigate('/account')}
                      selected={location.pathname === '/account'}
                      sx={{ pl: 3 }}
                    >
                      <ListItemIcon>
                        {location.pathname === '/account' ? (
                          <AccountBalanceWallet />
                        ) : (
                          <AccountBalanceWalletOutlined />
                        )}
                      </ListItemIcon>
                      <ListItemText primary="Account" />
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
                <ListItem
                  disablePadding
                  sx={{ display: 'block' }}
                >
                  <Tooltip
                    title="Support Docs"
                    placement="right"
                  >
                    <ListItemButton
                      href="https://docs.duohub.ai"
                      target="_blank"
                      sx={{ pl: 3 }}
                    >
                      <ListItemIcon>
                        <HelpCenter />
                      </ListItemIcon>
                      <ListItemText primary="Docs" />
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
                <ListItem
                  disablePadding
                  sx={{ display: 'block' }}
                >
                  <Tooltip
                    title="Log Out"
                    placement="right"
                  >
                    <ListItemButton
                      sx={{ pl: 3 }}
                      onClick={handleSignOutClick}
                    >
                      <ListItemIcon>
                        <Logout />
                      </ListItemIcon>
                      <ListItemText primary="Log Out" />
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
              </List>
            </Collapse>
            <List>
              <ListItem
                disablePadding
                sx={{ display: 'block' }}
              >
                <Tooltip
                  title="Settings"
                  placement="right"
                >
                  <ListItemButton
                    onClick={() => setAccountSettingsOpen(!accountSettingsOpen)}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: drawerOpen ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <Avatar
                        sx={{
                          background:
                            'linear-gradient(45deg, #2C3539, #1C2124)',
                          opacity: 0.5,
                        }}
                      >
                        {firstName ? (
                          <Typography
                            variant="h3"
                            color="white"
                          >
                            {firstName.charAt(0)}
                          </Typography>
                        ) : (
                          <Skeleton
                            height={20}
                            width={20}
                          />
                        )}
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        firstName ? (
                          `${firstName || ''} ${lastName || ''}`
                        ) : (
                          <Skeleton width={100} />
                        )
                      }
                      primaryTypographyProps={{
                        noWrap: true,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    />
                    {accountSettingsOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, pt: noticeBanner ? 7 : 0 }}
      >
        <DrawerHeader />
        {isLoggingOut ? <Loading /> : children}
      </Box>
    </Box>
  );
}
