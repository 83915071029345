import { ChevronRight } from '@mui/icons-material';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { GetCallData } from '@duohub/types';
import { RowCall } from 'components/molecules';
import { useGetCallQuery } from 'graphql/generated';
import { SortDirection } from 'types/enums';

export default function LogCall() {
  const { data: rawCallData } = useGetCallQuery({
    input: { limit: 20, sortOrder: SortDirection.DESC, getUsers: true },
  });
  const calls = rawCallData?.getCall.data;

  return (
    <Stack
      sx={{ mt: 2 }}
      direction="column"
      spacing={1}
    >
      <Grid
        container
        alignItems="center"
      >
        <Grid
          item
          sm={3}
        >
          <Typography variant="caption">Caller Name</Typography>
        </Grid>
        <Grid
          item
          sm={4}
        >
          <Typography variant="caption">Bot</Typography>
        </Grid>
        <Grid
          item
          sm={3}
        >
          <Typography variant="caption">Date</Typography>
        </Grid>
        <Grid
          item
          sm={1}
        >
          <Typography variant="caption">Duration</Typography>
        </Grid>
        <Grid
          item
          sm={1}
          display="flex"
          justifyContent="flex-end"
        >
          <ChevronRight sx={{ fontSize: 16, opacity: 0 }} />
        </Grid>
      </Grid>
      <Box my={2}>
        <Divider sx={{ opacity: 0.2 }} />
      </Box>
      {calls?.map((call: GetCallData) => (
        <RowCall
          key={call.call.id}
          call={call.call}
          owner={call.owner}
          participant={call.participant}
        />
      ))}
    </Stack>
  );
}
