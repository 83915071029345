import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip,
  CircularProgress,
  Box,
} from '@mui/material';
import { AudioFile, VideoFile, Description, Check } from '@mui/icons-material';
import { AppFile } from 'types/app';
import { useState } from 'react';
import { UploadStatus } from 'types/enums';

interface UploadItemProps {
  file: AppFile;
  progress: number;
  onRemove: () => void;
  onCancel: () => void;
  status: UploadStatus;
  createSample?: boolean;
  sampleIndexID?: string;
}

export const UploadItem = ({
  file,
  progress,
  onRemove,
  onCancel,
  status,
  createSample = false,
  sampleIndexID,
}: UploadItemProps) => {
  const [isHovering, setIsHovering] = useState(false);

  const getFileIcon = () => {
    switch (file.category) {
      case 'audio':
        return <AudioFile />;
      case 'video':
        return <VideoFile />;
      default:
        return <Description />;
    }
  };

  return (
    <ListItem sx={{ pr: 2 }}>
      <ListItemIcon>{getFileIcon()}</ListItemIcon>
      <ListItemText
        primary={
          <Typography
            noWrap
            sx={{ maxWidth: 'calc(100% - 20px)' }}
          >
            {file.name}
          </Typography>
        }
      />
      {status !== UploadStatus.uploading &&
        status !== UploadStatus.completed && (
          <Box
            sx={{
              width: 40,
              height: 40,
              flexShrink: 0,
            }}
          />
        )}
      {status === UploadStatus.uploading && (
        <Tooltip title={isHovering ? 'Cancel upload' : `${progress}% uploaded`}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ width: 40, height: 40, flexShrink: 0 }}
          >
            <IconButton
              edge="end"
              aria-label="upload-progress"
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
              onClick={onCancel}
              sx={{
                width: 40,
                height: 40,
                position: 'relative',
                flexShrink: 0,
              }}
            >
              <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress
                  variant="determinate"
                  value={progress}
                  size={40}
                  thickness={4}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    sx={{ color: 'text.secondary' }}
                  >{`${Math.round(progress)}`}</Typography>
                </Box>
              </Box>
            </IconButton>
          </Box>
        </Tooltip>
      )}
      {status === UploadStatus.completed && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ width: 40, height: 40, flexShrink: 0 }}
        >
          <Check />
        </Box>
      )}
    </ListItem>
  );
};
