import { TextField } from '@mui/material';
import { useEffect } from 'react';

interface SingleWebsiteFormProps {
  url: string;
  title: string;
  onUrlChange: (url: string) => void;
  onTitleChange: (title: string) => void;
  isLoading: boolean;
  getWebsiteMetadata: any; // Replace 'any' with actual type from your generated types
}

export default function SingleWebsiteForm({
  url,
  title,
  onUrlChange,
  onTitleChange,
  getWebsiteMetadata,
}: SingleWebsiteFormProps) {
  useEffect(() => {
    const fetchWebsiteMetadata = async () => {
      if (url) {
        try {
          const result = await getWebsiteMetadata({ input: { url } }).unwrap();
          if (
            result.getWebsiteMetadata.success &&
            result.getWebsiteMetadata.title
          ) {
            onTitleChange(result.getWebsiteMetadata.title);
          }
        } catch (error) {
          console.error('Error fetching website metadata:', error);
        }
      }
    };

    fetchWebsiteMetadata();
  }, [url, getWebsiteMetadata, onTitleChange]);

  return (
    <>
      <TextField
        label="Website URL"
        fullWidth
        value={url}
        onChange={(e) => onUrlChange(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TextField
        label="Website Title"
        fullWidth
        value={title}
        onChange={(e) => onTitleChange(e.target.value)}
        sx={{ mb: 2 }}
      />
    </>
  );
}
