import { ArrowBack } from '@mui/icons-material';
import { IconButton, IconButtonProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function ButtonBack({
  backArrowPath,
  ...props
}: IconButtonProps & { backArrowPath?: string }) {
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (backArrowPath) {
      navigate(backArrowPath);
    } else {
      navigate(-1);
    }
  };

  return (
    <IconButton
      onClick={handleBackClick}
      {...props}
    >
      <ArrowBack />
    </IconButton>
  );
}
