import { getSignedUploadUrl } from './getSignedUrl';

interface UploadStatus {
  success: boolean;
  message: string;
  percentage: number;
}

interface UploadOptions {
  file: File;
  onProgress: (percentage: number) => void;
  key: string;
  bucketName: string;
}

export const uploadFileToS3 = async ({
  file,
  onProgress,
  key,
  bucketName,
}: UploadOptions): Promise<UploadStatus> => {
  try {
    const uploadURL = await getSignedUploadUrl({
      key,
      bucketName,
    });
    console.log('uploadURL', uploadURL);
    // must use XMLHttpRequest to get progress events
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', uploadURL.data.getSignedS3Url.url);
    xhr.setRequestHeader('Content-Type', file.type);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        onProgress(percentComplete);
      }
    };

    return new Promise((resolve) => {
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve({
            success: true,
            message: 'File uploaded successfully',
            percentage: 100,
          });
        } else {
          resolve({
            success: false,
            message: `Error uploading file: ${xhr.statusText}`,
            percentage: 0,
          });
        }
      };

      xhr.onerror = () => {
        resolve({
          success: false,
          message: 'Error uploading file',
          percentage: 0,
        });
      };

      xhr.send(file);
    });
  } catch (error) {
    return {
      success: false,
      message: `Error uploading file: ${error as string}`,
      percentage: 0,
    };
  }
};
