import { Box, Divider } from '@mui/material';
import { Voice } from '@duohub/types';
import { formatDate, getVoiceStepindex } from 'common/utils';
import {
  MetadataGroup,
  ProgressStepper,
  PreviewCard,
  PreviewHeader,
  PreviewOpenButton,
} from 'components/atoms';
import { useNavigate } from 'react-router-dom';
import { useDeleteVoiceMutation } from 'graphql/generated';
import { Status, VoiceStep } from 'types/enums';

interface VoicePreviewProps {
  voice: Voice;
}

export function VoicePreview({ voice }: VoicePreviewProps) {
  const navigate = useNavigate();
  const steps = ['Select', 'Clean', 'Review', 'Train'];
  const [deleteVoice, { isLoading: isDeletingVoice }] =
    useDeleteVoiceMutation();

  const handleDeleteVoice = async (voiceToDelete: Voice) => {
    try {
      await deleteVoice({ input: { id: voiceToDelete.id } }).unwrap();
    } catch (error) {
      console.error('Failed to delete voice:', error);
    }
  };

  const handleOpenClick = () => {
    navigate(`/studio/voice/${voice?.id}`);
  };

  const header = (
    <PreviewHeader
      title={voice?.name}
      tooltip="Delete Voice"
      deleting={isDeletingVoice}
      onDelete={() => handleDeleteVoice(voice)}
    />
  );

  const indicator = (
    <>
      <Divider />
    </>
  );

  const content = (
    <Box mt={4}>
      <ProgressStepper
        steps={steps}
        activeStep={getVoiceStepindex(
          (voice?.step as VoiceStep) || VoiceStep.select,
        )}
      />
    </Box>
  );

  const actions = (
    <>
      <MetadataGroup
        items={[
          { label: 'Language', value: 'EN' },
          {
            label: 'Updated',
            value: formatDate(voice?.updatedAt || new Date().toISOString()),
          },
        ]}
      />
      <PreviewOpenButton
        tooltip={'Open Voice'}
        action={
          voice?.step === VoiceStep.review ||
          (voice?.step === VoiceStep.train &&
            voice?.status !== Status.pending &&
            voice?.status !== Status.processing) ||
          voice?.step === VoiceStep.select
        }
        onClick={handleOpenClick}
      />
    </>
  );

  return (
    <PreviewCard
      header={header}
      indicator={indicator}
      content={content}
      actions={actions}
    />
  );
}
