import { Close, Layers } from '@mui/icons-material';
import {
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
  Skeleton,
  CircularProgress,
  Tooltip,
  Chip,
  Box,
} from '@mui/material';
import { Sample } from '@duohub/types';
import { formatDate } from 'common/utils';
import { useDeleteSampleMutation, useGetFileQuery } from 'graphql/generated';
import { FileType } from 'types/enums';
import { categoryConfig } from '../category-config';
import { getStatusIcons } from '../status-icons';
import { useEffect } from 'react';

interface RowSampleMiniProps {
  sample: Sample;
}

export default function RowSampleMini({ sample }: RowSampleMiniProps) {
  const [deleteSample, { isLoading: isLoadingDelete }] =
    useDeleteSampleMutation();
  const {
    data,
    isLoading: isLoadingFile,
    refetch,
  } = useGetFileQuery(
    { input: { id: sample?.fileID } },
    { skip: !sample?.fileID },
  );
  const file = data?.getFile.data;

  const handleDeleteSample = async () => {
    await deleteSample({ input: { id: sample?.id } }).unwrap();
  };

  const getCategoryIcon = (fileType: FileType) =>
    categoryConfig[fileType]?.icon || null;
  const getCategoryText = (fileType: FileType) =>
    categoryConfig[fileType]?.text || 'Unknown';

  useEffect(() => {
    if (!file?.tokens) {
      const intervalId = setInterval(() => {
        refetch();
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [file?.tokens, refetch]);

  return (
    <Card elevation={2}>
      <CardContent
        sx={{ px: 2, py: 1 }}
        style={{ paddingBottom: 1 * 8, paddingTop: 1 * 8 }}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          {isLoadingFile ? (
            <>
              <Skeleton
                variant="text"
                width="15%"
                height={20}
              />
              <Skeleton
                variant="text"
                width="45%"
                height={24}
              />
              <Skeleton
                variant="text"
                width="10%"
                height={20}
              />
              <Skeleton
                variant="text"
                width="10%"
                height={20}
              />
              <Skeleton
                variant="text"
                width="15%"
                height={20}
              />
              <Skeleton
                variant="circular"
                width={24}
                height={24}
              />
            </>
          ) : (
            <>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                width="15%"
              >
                {getCategoryIcon(file?.fileType as FileType)}
                {file?.fileType === FileType.sitemap && (
                  <Tooltip title={(file?.hiddenFilesTotal || 0) + '- pages'}>
                    <Chip
                      label={file?.hiddenFilesTotal || '-'}
                      icon={<Layers />}
                      size="small"
                    />
                  </Tooltip>
                )}
                {file?.fileType === FileType.website_bulk && (
                  <Tooltip title={(file?.hiddenFilesTotal || 0) + '- pages'}>
                    <Chip
                      label={file?.hiddenFilesTotal || '-'}
                      icon={<Layers />}
                      size="small"
                    />
                  </Tooltip>
                )}
                <Typography variant="subtitle2">
                  {getCategoryText(file?.fileType as FileType)}
                </Typography>
              </Stack>
              <Stack
                width="45%"
                direction="row"
                spacing={1}
              >
                <Tooltip title={file?.name || ''}>
                  <Typography
                    variant="h6"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      maxWidth: '50ch',
                    }}
                  >
                    {file?.name}
                  </Typography>
                </Tooltip>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                sx={{ width: '10%' }}
              >
                {getStatusIcons(file)}
              </Stack>
              <Box width="10%">
                <Chip
                  label={file?.tokens?.toLocaleString() || '-'}
                  size="small"
                />
              </Box>
              <Typography
                variant="body2"
                width="15%"
              >
                {formatDate(sample?.updatedAt)}
              </Typography>
              <Tooltip
                title="Remove Sample"
                placement="left"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      color: 'black',
                      backgroundColor: 'warning.main',
                      fontWeight: 'bold',
                      fontSize: '12px',
                    },
                  },
                  arrow: {
                    sx: {
                      color: 'warning.main',
                    },
                  },
                }}
              >
                <IconButton
                  size="small"
                  onClick={handleDeleteSample}
                  disabled={isLoadingDelete}
                >
                  {isLoadingDelete ? <CircularProgress size={24} /> : <Close />}
                </IconButton>
              </Tooltip>
            </>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
