import { Check } from '@mui/icons-material';
import {
  Container,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
  LinearProgress,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

export default function SuccessPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [planType, setPlanType] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const plan = params.get('planType');
    setPlanType(plan || '');
  }, [location]);

  const exit = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const totalDuration = 5000;
    const interval = 50;
    const increment = (interval / totalDuration) * 100;

    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(timer);
          exit();
          return 100;
        }
        return prevProgress + increment;
      });
    }, interval);

    return () => clearInterval(timer);
  }, [exit]);

  const planFeatures = {
    free: [
      { feature: '10,000 graph tokens in', available: true },
      { feature: '30,000 graph tokens out', available: true },
      { feature: '1 graph', available: true },
    ],
    starter: [
      { feature: '100,000 graph tokens in', available: true },
      { feature: '200,000 graph tokens out', available: true },
      { feature: '10 graphs', available: true },
    ],
    pro: [
      { feature: '500,000 graph tokens in', available: true },
      { feature: '1,000,000 graph tokens out', available: true },
      { feature: 'Unlimited graphs', available: true },
      { feature: 'Priority support', available: true },
    ],
  };

  const currentPlanFeatures =
    planFeatures[planType as keyof typeof planFeatures] || planFeatures.free;

  return (
    <Paper
      sx={{
        width: '100vw',
        height: '100vh',
        p: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        sx={{
          width: '100%',
        }}
      >
        <img
          src="/img/icon-blue.svg"
          alt="duohub"
          style={{ width: 32, height: 32 }}
        />
        <Button onClick={exit}>Skip</Button>
      </Stack>
      <Container
        maxWidth="md"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          spacing={4}
        >
          <Typography variant="h1">
            Welcome to {planType.charAt(0).toUpperCase() + planType.slice(1)},
            Oseh!
          </Typography>
          <List sx={{ my: 2 }}>
            {currentPlanFeatures.map((item, index) => (
              <ListItem
                dense
                key={index}
              >
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
                <ListItemText primary={item.feature} />
              </ListItem>
            ))}
          </List>
          <LinearProgress
            variant="determinate"
            value={progress}
          />
        </Stack>
      </Container>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      ></Stack>
    </Paper>
  );
}
