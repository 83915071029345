import { Grid } from '@mui/material';
import { DocumentAdd } from 'components/organisms';

export default function MemoryNewStepSelect() {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
      >
        <DocumentAdd />
      </Grid>
    </Grid>
  );
}
