import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { Loading } from 'components/atoms';
import { RowCall } from 'components/molecules';
import { useParams } from 'react-router-dom';
import { useGetCallQuery } from 'graphql/generated';
import { GetCallData } from '@duohub/types';
import { SortDirection } from 'types/enums';
import { useEffect } from 'react';

export default function BotActivity() {
  const { botID } = useParams<{ botID: string }>();
  const { data, isLoading, refetch } = useGetCallQuery({
    input: { botID, getUsers: true, sortOrder: SortDirection.DESC },
  });
  const calls = data?.getCall?.data;

  useEffect(() => {
    if (botID) {
      refetch();
    }
  }, [botID, refetch]);

  return (
    <Box
      p={2}
      height="calc(100vh - 64px)"
      sx={{ overflowY: 'auto' }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Box mb={1}>
            <Grid
              container
              alignItems="center"
            >
              <Grid
                item
                sm={7}
              >
                <Typography variant="caption">Participant</Typography>
              </Grid>
              <Grid
                item
                sm={3}
              >
                <Typography variant="caption">Date</Typography>
              </Grid>
              <Grid
                item
                sm={1}
              >
                <Typography variant="caption">Duration</Typography>
              </Grid>
              <Grid
                item
                sm={1}
              >
                {/* Empty grid item for the chevron */}
              </Grid>
            </Grid>
            <Divider sx={{ opacity: 0.3 }} />
          </Box>
          {calls?.length === 0 && (
            <Box
              sx={{
                minHeight: '50vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                align="center"
                variant="body2"
              >
                No activity found.
              </Typography>
            </Box>
          )}
          <Stack
            direction="column"
            spacing={1}
          >
            {calls?.map((call: GetCallData) => (
              <RowCall
                key={call.call.id}
                call={call.call}
                owner={call.owner}
                participant={call.participant}
              />
            ))}
          </Stack>
        </>
      )}
    </Box>
  );
}
