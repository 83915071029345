import React from 'react';
import { IconButton, Button, Stack, Typography } from '@mui/material';
import { PlayArrow, Pause, FiberManualRecord } from '@mui/icons-material';
import { useAudio } from 'use/audio';

interface RecordButtonProps {
  onRecordClick: () => void;
  onStopClick: () => void;
}

const RecordButton: React.FC<RecordButtonProps> = ({
  onRecordClick,
  onStopClick,
}) => {
  const { isRecording, isPaused, isPlaying, recordedAudio, recordingTime } =
    useAudio();
  const getButtonColor = () => {
    if (isRecording || !recordedAudio) return 'error';
    if (isPaused || isPlaying || !!recordedAudio) return 'success';
    return 'default';
  };

  const getButtonIcon = () => {
    if (isRecording) {
      return isPaused ? <FiberManualRecord /> : <Pause />;
    }

    if (!!recordedAudio) {
      return isPlaying ? <Pause /> : <PlayArrow />;
    }
    return <FiberManualRecord />;
  };

  return (
    <Stack
      direction="column"
      spacing={1}
      alignItems="center"
    >
      <Typography>{recordingTime}</Typography>
      <IconButton
        color={getButtonColor()}
        onClick={onRecordClick}
        sx={{
          width: 56,
          height: 56,
          borderRadius: '50%',
        }}
      >
        {getButtonIcon()}
      </IconButton>
      <Button
        size="small"
        onClick={onStopClick}
        disabled={!isRecording}
        sx={{
          opacity: isRecording ? 1 : 0.3,
          transition: 'opacity 0.3s',
        }}
      >
        Stop
      </Button>
    </Stack>
  );
};

export default RecordButton;
