import { Bolt } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import { getColorVariantFromStatus } from 'common/utils';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetBatchesQuery,
  useGetBatchStatusQuery,
  useGetMemoryQuery,
  useGetUserQuery,
} from 'graphql/generated';
import { PlanType, Status } from 'types/enums';
import { useMemory } from 'use/memory';
import { usePro } from 'use/pro';

export default function MemoryNewStepIngest() {
  const { setOpenModal } = usePro();
  const { memoryID } = useParams<{ memoryID: string }>();
  const { setIngestionComplete } = useMemory();
  const { data: processingStatusData, refetch: refetchProcessingStatus } =
    useGetBatchStatusQuery({
      input: {
        memoryID,
      },
    });
  const { data: batchData, refetch: refetchBatchData } = useGetBatchesQuery({
    input: {
      memoryID,
    },
  });
  const { data: userData } = useGetUserQuery({
    input: {},
  });
  const user = userData?.getUser;
  const isFree = user?.plan === PlanType.free;
  console.log('batchData', batchData);
  const { refetch: refetchMemoryData } = useGetMemoryQuery({
    input: {
      id: memoryID,
    },
  });

  const batch = useMemo(() => {
    return batchData?.getBatches.data[0];
  }, [batchData]);

  useEffect(() => {
    console.log('useEffect triggered');
    let intervalId: NodeJS.Timeout;

    if (processingStatusData) {
      setIngestionComplete(false);
      intervalId = setInterval(() => {
        console.log('Refetching processing status');
        refetchProcessingStatus();
        refetchBatchData();
      }, 5000);
    }

    if (batch?.status === Status.success) {
      setIngestionComplete(true);
      refetchMemoryData();
    }

    return () => {
      if (intervalId) {
        console.log('Clearing refetch interval');
        clearInterval(intervalId);
      }
    };
  }, [
    refetchMemoryData,
    processingStatusData,
    refetchProcessingStatus,
    refetchBatchData,
    setIngestionComplete,
    batch?.status,
  ]);

  const completed = processingStatusData?.getBatchStatus.completed || 0;
  const total = processingStatusData?.getBatchStatus.total || 0;
  const progressPercentage = (completed / total) * 100;

  return (
    <Box>
      <Stack
        direction="column"
        spacing={2}
      >
        <Card>
          <CardContent>
            <Stack
              direction="row"
              spacing={1.5}
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={1.5}
              >
                <Typography variant="h5">Processing Files</Typography>
                <Chip
                  size="small"
                  label={`${completed || 0}/${total || 0}`}
                />
              </Stack>
              {isFree && (
                <Button
                  startIcon={<Bolt />}
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ height: 24, fontSize: '0.7rem', fontWeight: 'bold' }}
                  onClick={() => setOpenModal(true)}
                >
                  Faster Ingestion
                </Button>
              )}
            </Stack>
            <Divider sx={{ my: 2 }} />
            <LinearProgress
              variant={
                !processingStatusData?.getBatchStatus.completed
                  ? 'indeterminate'
                  : 'determinate'
              }
              value={progressPercentage}
            />
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1.5}
            >
              <Typography variant="h5">Memory Status</Typography>
              <Chip
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  fontSize: '0.7rem',
                }}
                color={
                  getColorVariantFromStatus(batch?.status as Status) ||
                  'default'
                }
                size="small"
                label={batch?.status || 'submitted'}
              />
            </Stack>

            <Divider sx={{ my: 2 }} />
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2">{batch?.message}</Typography>
            </Box>
          </CardContent>
        </Card>
      </Stack>
    </Box>
  );
}
