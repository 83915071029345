import {
  Box,
  Divider,
  TextField,
  Paper,
  Typography,
  Skeleton,
  Chip,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import { Memory } from '@duohub/types';
import { formatDate, getMemoryStepindex } from 'common/utils';
import {
  MetadataGroup,
  PreviewCard,
  PreviewHeader,
  PreviewOpenButton,
} from 'components/atoms';
import { useNavigate } from 'react-router-dom';
import {
  useDeleteMemoryMutation,
  useGetMemoriesQuery,
  useGetSamplesQuery,
  useUpdateMemoryMutation,
} from 'graphql/generated';
import { MemoryStep, MemoryType } from 'types/enums';
import { useMemo, useState } from 'react';
import { useSnackbar } from 'use/snackbar';
import { BubbleChart } from '@mui/icons-material';
import { Hub } from '@mui/icons-material';

interface MemoryPreviewProps {
  memory: Memory;
}

export function MemoryPreview({ memory }: MemoryPreviewProps) {
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [memoryName, setMemoryName] = useState(memory.name);
  const [updateMemory] = useUpdateMemoryMutation();
  const steps = ['Select', 'Settings', 'Ingest'];

  const [deleteMemory, { isLoading: isDeletingMemory }] =
    useDeleteMemoryMutation();
  const { refetch: refetchMemories } = useGetMemoriesQuery({ input: {} });
  const { showSnackbar } = useSnackbar();

  const handleDeleteMemory = async (memoryToDelete: Memory) => {
    try {
      await deleteMemory({ input: { id: memoryToDelete.id } }).unwrap();
      await refetchMemories();
    } catch (error) {
      console.error('Failed to delete voice:', error);
    }
  };

  const { data: samplesData, isLoading: isLoadingSamples } = useGetSamplesQuery(
    {
      input: { memoryID: memory.id },
    },
  );
  const samples = useMemo(() => samplesData?.getSamples?.data, [samplesData]);

  const handleOpenClick = () => {
    navigate(`/memory/${memory?.id}`);
  };

  const handleTextClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMemoryName(event.target.value);
  };

  const handleSave = async () => {
    setIsEditing(false);
    try {
      await updateMemory({
        input: { id: memory.id, name: memoryName },
      }).unwrap();
      await refetchMemories();
    } catch (error) {
      console.error('Failed to update memory name:', error);
      setMemoryName(memory.name); // Revert to original name if update fails
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  const handleCopyMemory = (memoryId: string) => {
    navigator.clipboard.writeText(memoryId);
    showSnackbar('Memory ID copied to clipboard', 'success');
  };

  const header = (
    <PreviewHeader
      title={
        <>
          {memory.memoryType && (
            <Chip
              icon={
                memory.memoryType === MemoryType.graph ? (
                  <Hub />
                ) : (
                  <BubbleChart />
                )
              }
              label={memory.memoryType?.toString()?.toUpperCase()}
              size="small"
              sx={{
                fontWeight: 'bold',
                fontSize: '10px',
              }}
            />
          )}
          {isEditing ? (
            <Paper
              elevation={3}
              style={{ display: 'inline-block' }}
            >
              <TextField
                placeholder="Memory name"
                value={memoryName}
                onChange={handleInputChange}
                onBlur={handleSave}
                onKeyDown={handleKeyDown}
                autoFocus
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                }}
                size="small"
                inputProps={{
                  style: {
                    fontSize: 'inherit',
                    padding: '6px 10px',
                    border: 'none',
                  },
                  maxLength: 70,
                }}
              />
            </Paper>
          ) : (
            <Typography
              variant="h4"
              onClick={handleTextClick}
              style={{ cursor: 'pointer', fontWeight: 'bold' }}
            >
              {memoryName}
            </Typography>
          )}
        </>
      }
      tooltip="Delete Memory"
      deleting={isDeletingMemory}
      onDelete={() => handleDeleteMemory(memory)}
      showCopy={
        memory.step === MemoryStep.complete || memory.step === MemoryStep.review
      }
      onCopy={() => handleCopyMemory(memory.id)}
    />
  );

  const indicator = (
    <>
      <Divider />
    </>
  );

  const content = (
    <Box
      mt={4}
      py={2}
    >
      <Stepper
        activeStep={getMemoryStepindex(
          (memory?.step as MemoryStep) || MemoryStep.select,
        )}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel>
              {isLoadingSamples ? (
                <Skeleton
                  variant="text"
                  width={step.length * 10} // Approximate width based on text length
                  height={20} // Match typography height
                  sx={{
                    transform: 'scale(1, 0.8)', // Adjust the height scale
                    marginTop: '4px', // Fine-tune vertical alignment
                  }}
                />
              ) : (
                <Typography variant="body2">{step}</Typography>
              )}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );

  const actions = (
    <>
      <MetadataGroup
        items={[
          {
            label: 'Updated',
            value: formatDate(memory?.updatedAt || new Date().toISOString()),
          },
          {
            label: 'Samples',
            value: isLoadingSamples ? (
              <Skeleton
                width={24}
                height={24}
              />
            ) : (
              samples?.length || 0
            ),
          },
        ]}
      />
      <PreviewOpenButton
        tooltip="Manage Memory"
        action={
          memory?.step === MemoryStep.select ||
          memory?.step === MemoryStep.ontology
        }
        onClick={handleOpenClick}
      />
    </>
  );

  return (
    <PreviewCard
      header={header}
      indicator={indicator}
      content={content}
      actions={actions}
    />
  );
}
