import { CheckCircle, Error, Info, Warning } from '@mui/icons-material';
import { ColorVariant } from 'types/app';

interface IconAlertProps {
  severity: ColorVariant;
}
export default function IconAlert({ severity }: IconAlertProps) {
  switch (severity) {
    case 'error':
      return <Error />;
    case 'warning':
      return <Warning />;
    case 'info':
      return <Info />;
    case 'success':
      return <CheckCircle />;
    default:
      return null;
  }
}
