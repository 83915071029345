import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useUpdateFileMutation } from 'graphql/generated';
import { FileCategory, FileType } from 'types/enums';

interface DialogContentAddYoutubeProps {
  open: boolean;
  onClose: () => void;
  onFileAdded?: () => void;
}

export default function DialogContentAddYoutube({
  open,
  onClose,
  onFileAdded,
}: DialogContentAddYoutubeProps) {
  const [updateFile, { isLoading: isLoadingUpdateFile }] =
    useUpdateFileMutation();
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [authorName, setAuthorName] = useState('');

  useEffect(() => {
    const fetchYoutubeInfo = async () => {
      if (url) {
        try {
          const response = await fetch(`https://noembed.com/embed?url=${url}`);
          const data = await response.json();
          setTitle(data.title || '');
          setThumbnail(data.thumbnail_url || '');
          setAuthorName(data.author_name || '');
          console.log(data);
        } catch (error) {
          console.error('Error fetching YouTube info:', error);
          setTitle('');
          setThumbnail('');
          setAuthorName('');
        }
      } else {
        setTitle('');
        setThumbnail('');
        setAuthorName('');
      }
    };

    fetchYoutubeInfo();
  }, [url]);

  useEffect(() => {
    if (!open) {
      clearFields();
    }
  }, [open]);

  const createYoutubeFile = async () => {
    try {
      const response = await updateFile({
        input: {
          externalURI: url,
          category: FileCategory.video,
          fileType: FileType.youtube,
          name: title,
          originRegion: process.env.REACT_APP_AWS_REGION || 'eu-west-2',
        },
      }).unwrap();
      console.log(response);

      if (onFileAdded) {
        onFileAdded();
      }
    } catch (error) {
      console.error('Error creating YouTube file:', error);
    } finally {
      clearFields();
      onClose();
    }
  };

  const clearFields = () => {
    setUrl('');
    setTitle('');
    setThumbnail('');
    setAuthorName('');
  };

  const handleClose = () => {
    clearFields();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        p={2}
      >
        <Typography variant="h4">Add YouTube Video</Typography>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Stack>
      <Divider />
      <Box p={2}>
        <TextField
          label="YouTube URL"
          fullWidth
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          sx={{ mb: 2 }}
        />
        {thumbnail && title && (
          <Card sx={{ display: 'flex', alignItems: 'center' }}>
            <CardMedia
              component="img"
              sx={{ width: '50%', height: 'auto' }}
              image={thumbnail}
              alt="Video thumbnail"
            />
            <CardContent sx={{ width: '50%' }}>
              <Typography variant="body1">{title}</Typography>
              <Typography
                variant="body2"
                color="text.secondary"
              >
                {authorName}
              </Typography>
            </CardContent>
          </Card>
        )}
      </Box>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        p={2}
      >
        <LoadingButton
          size="small"
          sx={{ fontWeight: 'bold', height: 28 }}
          variant="contained"
          onClick={createYoutubeFile}
          loading={isLoadingUpdateFile}
          disabled={!url || !title}
        >
          Add
        </LoadingButton>
      </Stack>
    </Dialog>
  );
}
