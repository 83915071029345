import React, { useCallback, useRef, useEffect } from 'react';
import { Stack } from '@mui/material';
import { AudioButtonIconRecord } from 'components/atoms';
import { AudioControls, AudioWaveform } from 'components/atoms';
import { useAudio } from 'use/audio';
import { AudioWaveformRef } from 'types/app';

const InterfaceRecord: React.FC = () => {
  const {
    isRecording,
    isPaused,
    recordedAudio,
    isPlaying,
    setIsPlaying,
    setIsRecording,
    setIsPaused,
    setRecordingTime,
    setRecordedAudio,
  } = useAudio();

  const waveformRef = useRef<AudioWaveformRef>(null);

  useEffect(() => {
    return () => {
      if (recordedAudio) {
        URL.revokeObjectURL(recordedAudio);
      }
    };
  }, [recordedAudio]);

  const handleRecordClick = () => {
    if (recordedAudio) {
      if (isPlaying) {
        waveformRef.current?.pause();
        setIsPlaying(false);
      } else {
        waveformRef.current?.play();
        setIsPlaying(true);
      }
    } else {
      if (isRecording) {
        if (isPaused) {
          waveformRef.current?.resumeRecording();
          setIsPaused(false);
        } else {
          waveformRef.current?.pauseRecording();
          setIsPaused(true);
        }
      } else {
        console.log('Starting new recording');
        waveformRef.current?.startRecording();
        setIsRecording(true);
        setIsPaused(false);
      }
    }
  };

  const handleStopClick = () => {
    if (isPlaying) {
      waveformRef.current?.pause();
      waveformRef.current?.seekTo(0);
      setIsPlaying(false);
    } else if (isRecording || isPaused) {
      waveformRef.current?.stopRecording();
      setIsRecording(false);
      setIsPaused(false);
    }
  };

  const handleRecordEnd = useCallback(
    (blob: Blob) => {
      const url = URL.createObjectURL(blob);
      setRecordedAudio(url);
      setIsRecording(false);
      setIsPaused(false);
    },
    [setIsPaused, setIsRecording, setRecordedAudio],
  );

  const handleRecordProgress = useCallback(
    (time: number) => {
      const minutes = Math.floor(time / 60000);
      const seconds = Math.floor((time % 60000) / 1000);
      setRecordingTime(
        `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`,
      );
    },
    [setRecordingTime],
  );

  const handleClearAudio = () => {
    if (recordedAudio) {
      URL.revokeObjectURL(recordedAudio);
    }
    setRecordedAudio(null);
    setIsPlaying(false);
    setIsRecording(false);
    setIsPaused(false);
    setRecordingTime('00:00');
    waveformRef.current?.destroy();
  };

  return (
    <Stack
      direction="column"
      spacing={1.5}
      sx={{ minHeight: '200px' }}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
      >
        <AudioButtonIconRecord
          onRecordClick={handleRecordClick}
          onStopClick={handleStopClick}
        />

        <AudioWaveform
          ref={waveformRef}
          onRecordEnd={handleRecordEnd}
          onRecordProgress={handleRecordProgress}
        />
      </Stack>
      <AudioControls
        onClearAudio={handleClearAudio}
        recordedAudio={recordedAudio}
      />
    </Stack>
  );
};

export default InterfaceRecord;
