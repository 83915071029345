import { api as generatedApi } from 'graphql/generated';

export const usersApi = generatedApi.enhanceEndpoints({
  addTagTypes: ['User'],
  endpoints: {
    GetUser: {
      providesTags: ['User'],
      transformResponse: (res: any) => {
        return res;
      },
    },
    UpdateUser: {
      invalidatesTags: ['User'],
      async onQueryStarted(
        { input: updatedUser },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          usersApi.util.updateQueryData(
            'GetUser',
            {
              input: {},
            },
            (draft) => {
              draft.getUser = {
                ...draft.getUser,
                ...updatedUser,
              };
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          console.error('UsersUpdateFile optimistic update failed:', error);
          patchResult.undo();
        }
      },
    },
  },
});

export const { useGetUserQuery, useUpdateUserMutation } = usersApi;
