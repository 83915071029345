import { Box, Divider } from '@mui/material';
import {
  ModelLLM,
  ModelTranscriber,
  ModelVoiceOptions,
} from 'components/molecules';

export default function ModelModels() {
  return (
    <Box>
      <ModelVoiceOptions />
      <Box sx={{ my: 5 }}>
        <Divider />
      </Box>
      <ModelTranscriber />
      <Box sx={{ my: 5 }}>
        <Divider />
      </Box>
      <ModelLLM />
    </Box>
  );
}
