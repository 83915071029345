import React from 'react';
import {
  Drawer as MuiDrawer,
  AppBar as MuiAppBar,
  Theme,
  AppBarProps as MuiAppBarProps,
  Box,
} from '@mui/material';
import { CSSObject } from '@mui/system';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

function AppBar({ open, ...props }: AppBarProps) {
  return (
    <MuiAppBar
      {...props}
      sx={(theme: Theme) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
          height: 48,
          marginLeft: drawerWidth,
          background: 'none',
          boxShadow: 'none',
          width: `calc(100% - ${drawerWidth}px)`,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
      })}
    />
  );
}

interface DrawerProps {
  open?: boolean;
  elevation?: number;
  variant?: 'permanent' | 'persistent' | 'temporary';
  children: React.ReactNode;
}

function Drawer({
  open,
  elevation = 0,
  variant = 'permanent',
  ...props
}: DrawerProps) {
  return (
    <MuiDrawer
      {...props}
      elevation={elevation}
      variant={variant}
      sx={(theme: Theme) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        '& .MuiDrawer-paper': {
          borderRight: '0.1px solid #2e2e2e',
        },
        ...(open && {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': {
            ...openedMixin(theme),
            borderRight: '0.1px solid #2e2e2e',
          },
        }),
        ...(!open && {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': {
            ...closedMixin(theme),
            borderRight: '0.1px solid #2e2e2e',
          },
        }),
      })}
    />
  );
}

interface DrawerHeaderProps {
  sx?: CSSObject;
  children?: React.ReactNode;
}
function DrawerHeader({ sx, children }: DrawerHeaderProps) {
  return (
    <Box
      sx={(theme: Theme) => ({
        ...sx,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '48px',
        padding: theme.spacing(0, 1),
      })}
    >
      {children}
    </Box>
  );
}

export { AppBar, Drawer, DrawerHeader };
