import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { TextField, Container, Typography, Box, Stack } from '@mui/material';
import { confirmSignUp, getCurrentUser, signIn } from 'aws-amplify/auth';
import { Logo } from 'components/atoms';
import { WavingHand } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'use/snackbar';
import mixpanel from 'mixpanel-browser';
import { checkAuthState } from 'common/utils';

const ConfirmSignUp: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { showSnackbar } = useSnackbar();
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const username = location.state?.username || '';
  const password = location.state?.password || '';

  const handleManualSignIn = async () => {
    try {
      await signIn({ username, password });
      const currentUser = await getCurrentUser();
      console.log('User signed in manually:', currentUser);
      showSnackbar('Account confirmed and signed in successfully!', 'success');
      navigate('/');
    } catch (error) {
      console.error('Error signing in manually:', error);
      showSnackbar(
        'Account confirmed, but sign-in failed. Please try signing in manually.',
        'warning',
      );
      navigate('/signin');
    }
  };

  const handleConfirmSignUp = async (event: React.FormEvent) => {
    event.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const isAuthenticated = await checkAuthState();
      if (isAuthenticated) {
        navigate('/', { replace: true });
        return;
      }
      await confirmSignUp({
        username,
        confirmationCode: code,
        options: {
          autoSignIn: true,
        },
      });
      console.log('Sign up confirmed successfully');

      try {
        const currentUser = await getCurrentUser();
        console.log('User signed in automatically:', currentUser);
        showSnackbar(
          'Account confirmed and signed in successfully!',
          'success',
        );
        mixpanel.track('sign_up', {
          'Signup Type': 'Email',
        });
        navigate('/');
      } catch (error) {
        console.error('Error getting current user:', error);
        // Try manual sign-in if auto sign-in fails
        await handleManualSignIn();
      }
    } catch (err) {
      console.error('Confirmation error:', err);
      if (err instanceof Error) {
        setError(`Failed to confirm sign up: ${err.name} - ${err.message}`);
      } else {
        setError('Failed to confirm sign up. An unknown error occurred.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh',
      }}
    >
      <Container maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Stack
            direction="column"
            spacing={2}
            alignItems="center"
          >
            <Logo size="medium" />
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
            >
              <Typography
                component="h1"
                variant="h3"
              >
                Confirm your account
              </Typography>
              <WavingHand />
            </Stack>
          </Stack>
          <Box
            component="form"
            onSubmit={handleConfirmSignUp}
            noValidate
            sx={{ mt: 4, width: '100%' }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="code"
              label="Confirmation Code"
              name="code"
              autoComplete="off"
              autoFocus
              value={code}
              onChange={(e) => setCode(e.target.value)}
              size="small"
            />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, fontWeight: 'bold' }}
              size="small"
              loading={isLoading}
            >
              Confirm Sign Up
            </LoadingButton>
          </Box>
        </Box>
        {error && (
          <Typography
            color="error"
            align="center"
            sx={{ mt: 2 }}
          >
            {error}
          </Typography>
        )}
      </Container>
    </Box>
  );
};

export default ConfirmSignUp;
