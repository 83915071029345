import { Sample } from '@duohub/types';
import { useCallback } from 'react';
import {
  useLazyGetCleanAudioQuery,
  useLazyGetSampleQuery,
} from 'graphql/generated';

export const useAudioAPI = () => {
  const [cleanAudio] = useLazyGetCleanAudioQuery();
  const [getSample] = useLazyGetSampleQuery();

  const cleanSamples = useCallback(
    async (
      samples: Sample[],
      onCleanStart: (sampleId: string) => void,
      onCleanComplete: (sampleId: string, updatedSample: any) => void,
    ) => {
      const sortedSamples = [...samples].sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      );

      const batchSize = 10;
      for (let i = 0; i < sortedSamples.length; i += batchSize) {
        const batchStartTime = Date.now();
        const batch = sortedSamples.slice(i, i + batchSize);

        await Promise.all(
          batch.map(async (sample) => {
            if (sample?.id) {
              onCleanStart(sample.id);

              await cleanAudio({
                input: {
                  sampleID: sample.id,
                },
              });

              const updatedSample = await getSample({
                input: {
                  id: sample.id,
                },
              });

              onCleanComplete(sample.id, updatedSample.data?.getSample?.data);
            }
          }),
        );

        // Ensure at least 1 second has passed since the start of this batch
        const elapsedTime = Date.now() - batchStartTime;
        if (elapsedTime < 1000) {
          await new Promise((resolve) =>
            setTimeout(resolve, 1000 - elapsedTime),
          );
        }
      }
    },
    [cleanAudio, getSample],
  );

  return {
    cleanSamples,
  };
};
