import React, { forwardRef } from 'react';
import { Box, BoxProps } from '@mui/material';

interface AudioContainerWavesurferProps extends BoxProps {
  onRecordEnd: (blob: Blob) => void;
  onRecordProgress: (time: number) => void;
  height?: number;
}

const AudioContainerWavesurfer = forwardRef<
  HTMLDivElement,
  AudioContainerWavesurferProps
>(({ onRecordEnd, onRecordProgress, height, ...boxProps }, ref) => (
  <Box
    ref={ref}
    {...boxProps}
    style={{
      border: '1px solid #373737',
      borderRadius: 8,
      overflow: 'hidden',
      width: '100%',
      height: height || 144,
      ...boxProps.style,
    }}
  />
));

AudioContainerWavesurfer.displayName = 'AudioContainerWavesurfer';

export default AudioContainerWavesurfer;
