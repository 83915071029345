import {
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
  CircularProgress,
  Skeleton,
} from '@mui/material';
import { AiSampleFile } from '@duohub/types';
import { useGetSignedCloudFrontUrlQuery } from 'graphql/generated';
import { AudioWaveform } from 'components/atoms';
import { useEffect, useRef, useState } from 'react';
import { DownloadForOffline, Pause, PlayArrow } from '@mui/icons-material';
import { formatDate } from 'common/utils';
import { AudioWaveformRef } from 'types/app';

interface RowMiniSampleVoiceAIProps {
  aiSampleFile: AiSampleFile;
}

const RowMiniSampleVoiceAI: React.FC<RowMiniSampleVoiceAIProps> = ({
  aiSampleFile,
}) => {
  const { data: signedCloudfrontUrl, isLoading } =
    useGetSignedCloudFrontUrlQuery(
      {
        input: {
          key: aiSampleFile?.s3Key,
          type: 'CONTENT',
        },
      },
      { skip: !aiSampleFile?.s3Key },
    );
  console.log('signedCloudfrontUrl:', signedCloudfrontUrl);
  const waveformRef = useRef<AudioWaveformRef>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    console.log('waveformRef:', waveformRef);
    if (
      signedCloudfrontUrl?.getSignedCloudFrontUrl.uri &&
      waveformRef.current
    ) {
      try {
        console.log(
          'Loading audio from URL:',
          signedCloudfrontUrl.getSignedCloudFrontUrl.uri,
        );
        waveformRef.current.load(
          signedCloudfrontUrl.getSignedCloudFrontUrl.uri,
        );
      } catch (error) {
        console.error('Error loading audio:', error);
      }
    }
  }, [signedCloudfrontUrl]);

  const handlePlayPause = () => {
    if (waveformRef.current) {
      try {
        if (isPlaying) {
          waveformRef.current.pause();
        } else {
          waveformRef.current.play();
        }
        setIsPlaying(!isPlaying);
      } catch (error) {
        console.error('Error during play/pause:', error);
      }
    }
  };

  const handleDownload = async () => {
    if (signedCloudfrontUrl?.getSignedCloudFrontUrl.uri) {
      setIsDownloading(true);
      try {
        const response = await fetch(
          signedCloudfrontUrl.getSignedCloudFrontUrl.uri,
        );
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'download';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading audio:', error);
      } finally {
        setIsDownloading(false);
      }
    }
  };

  return (
    <Card elevation={2}>
      <CardContent
        style={{ paddingBottom: 16 }}
        sx={{ py: 1 }}
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ pb: 2 }}
        >
          <Stack
            direction="row"
            spacing={2}
          >
            {isLoading ? (
              <>
                <Skeleton
                  width={150}
                  height={24}
                />
                <Skeleton
                  width={200}
                  height={24}
                />
              </>
            ) : (
              <>
                <Typography>{aiSampleFile?.name}</Typography>
                <Typography>{formatDate(aiSampleFile.updatedAt)}</Typography>
              </>
            )}
          </Stack>
          <IconButton onClick={handleDownload}>
            {isDownloading ? (
              <CircularProgress size={24} />
            ) : (
              <DownloadForOffline />
            )}
          </IconButton>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
        >
          <Stack
            direction="column"
            justifyContent="center"
          >
            <IconButton onClick={handlePlayPause}>
              {isPlaying ? <Pause /> : <PlayArrow />}
            </IconButton>
          </Stack>
          <AudioWaveform ref={waveformRef} />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default RowMiniSampleVoiceAI;
