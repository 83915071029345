import {
  Card,
  CardActionArea,
  CardHeader,
  CircularProgress,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Voice } from '@duohub/types';
import { formatDate, getVoiceStepindex } from 'common/utils';
import { useNavigate } from 'react-router-dom';
import { VoiceStep } from 'types/enums';
import { CheckCircle } from '@mui/icons-material';

interface VoicePreviewProps {
  voice: Voice;
  slim?: boolean;
}

const getTooltipTitle = (percentage: number): string => {
  if (percentage >= 100) return 'Voice is ready';
  if (percentage === 25) return 'Select voice samples';
  if (percentage === 50) return 'Cleaning voice samples';
  if (percentage === 75) return 'Training voice';
  return 'Voice is ready';
};

export function VoicePreviewSlim({ voice }: VoicePreviewProps) {
  const navigate = useNavigate();
  const steps = ['Select', 'Clean', 'Review', 'Train'];
  const activeStep = getVoiceStepindex(
    (voice?.step as VoiceStep) || VoiceStep.select,
  );

  const percentage = Math.min((activeStep + 1 / steps.length) * 100, 100);

  return (
    <Grid
      item
      xs={12}
      sm={6}
    >
      <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <CardActionArea
          sx={{
            p: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            height: '100%',
          }}
          onClick={() => navigate(`/studio/voice/${voice.id}`)}
        >
          <CardHeader
            avatar={
              <Tooltip title={getTooltipTitle(percentage)}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  position="relative"
                >
                  <CircularProgress
                    variant="determinate"
                    value={100}
                    size={80}
                    sx={{
                      position: 'absolute',
                      opacity: 0.2,
                      zIndex: 0,
                    }}
                  />
                  <CircularProgress
                    variant="determinate"
                    value={percentage}
                    size={80}
                    sx={{ zIndex: 1 }}
                  />
                  {percentage >= 100 && (
                    <CheckCircle
                      sx={{
                        position: 'absolute',
                        color: 'success.main',
                        width: 30,
                        height: 30,
                      }}
                    />
                  )}
                </Stack>
              </Tooltip>
            }
            titleTypographyProps={{
              variant: 'h3',
              sx: { whiteSpace: 'nowrap', textOverflow: 'ellipsis' },
            }}
            subheaderTypographyProps={{
              variant: 'body1',
              sx: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '100%',
                display: 'block',
              },
            }}
            title={voice.name}
            subheader={voice.description}
            sx={{
              width: '100%',
              '& .MuiCardHeader-content': {
                overflow: 'hidden',
              },
            }}
          />
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="flex-end"
            sx={{ px: 1, width: '100%' }}
          >
            <Typography variant="caption">
              {formatDate(voice.updatedAt)}
            </Typography>
          </Stack>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
