const backgroundColors = [
  'b6e3f4',
  'c0aede',
  'd1d4f9',
  'ffd5dc',
  'ffdfbf',
  'f0f8ff',
  'faebd7',
  '00ffff',
  '7fffd4',
  'f0ffff',
  'f5f5dc',
  'ffe4c4',
  'ffebcd',
  '8a2be2',
  'a52a2a',
  'deb887',
  '5f9ea0',
  '7fff00',
  'd2691e',
  'ff7f50',
  '6495ed',
  'fff8dc',
  'dc143c',
  '00ffff',
  '00008b',
  '008b8b',
  'b8860b',
  'a9a9a9',
  '006400',
  'bdb76b',
];
const eyeStyles = [
  'bulging',
  'dizzy',
  'eva',
  'frame1',
  'frame2',
  'glow',
  'happy',
  'hearts',
  'robocop',
  'round',
  'roundFrame01',
  'roundFrame02',
  'sensor',
  'shade01',
];
const mouthStyles = [
  'bite',
  'diagram',
  'grill01',
  'grill02',
  'grill03',
  'smile01',
  'smile02',
  'square01',
  'square02',
];

export { backgroundColors, eyeStyles, mouthStyles };
