import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { RowSampleMini } from 'components/atoms';
import { useParams } from 'react-router-dom';
import { useGetSamplesQuery, useGetVoiceQuery } from 'graphql/generated';
import { VoiceStep } from 'types/enums';

interface SamplesProps {
  height?: string;
}

export default function Samples({ height = '200px' }: SamplesProps) {
  const { voiceID } = useParams<{ voiceID: string }>();

  const { data: sampleData } = useGetSamplesQuery({
    input: {
      voiceID,
    },
  });
  const samples = sampleData?.getSamples?.data;
  console.log('samples:', samples);

  const { data: voiceData } = useGetVoiceQuery(
    {
      input: {
        id: voiceID,
      },
    },
    {
      skip: !voiceID,
    },
  );
  console.log('voiceData:', voiceData);
  const voice = voiceData?.getVoice?.data;
  console.log(
    'voice?.step === VoiceStep.select:',
    voice?.step === VoiceStep.select,
  );
  console.log('voice?.step:', voice?.step);

  return (
    <Card>
      <CardContent>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1.5}
        >
          <Typography variant="h5">Samples</Typography>
          <Chip
            size="small"
            label={`${samples?.length || 0}`}
          />
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Box
          sx={{
            height,
            width: '100%',
            borderRadius: '4px',
            padding: '8px',
            '&::-webkit-scrollbar': {
              width: '10px',
              backgroundColor: grey[900],
              borderRadius: 8,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: grey[500],
              borderRadius: 8,
            },
            overflowY: 'scroll',
          }}
        >
          {voice?.step === VoiceStep.select && (
            <Stack
              spacing={1.5}
              sx={{
                paddingRight: '10px',
              }}
            >
              {samples?.map((sample) => (
                <RowSampleMini
                  key={sample?.id}
                  sample={sample}
                />
              ))}
            </Stack>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
