import React, { useState } from 'react';
import {
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  Stack,
  Tooltip,
  IconButton,
  Dialog,
  Box,
  Divider,
  Alert,
  Link,
} from '@mui/material';
import { AudioSource } from 'types/enums';
import { useAudio } from 'use/audio';
import { Close, Info } from '@mui/icons-material';

const AudioToggleButtonSource: React.FC = () => {
  const { audioSource, handleSourceChange } = useAudio();

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newSource: AudioSource,
  ) => {
    if (newSource !== null) {
      handleSourceChange(newSource);
    }
  };

  const [openModal, setOpenModal] = useState(false);

  return (
    <Box>
      <Stack
        direction="row"
        spacing={4}
        alignItems="center"
      >
        <Tooltip
          title="How to make an awesome voice clone"
          placement="top"
          arrow
        >
          <IconButton onClick={() => setOpenModal(true)}>
            <Info />
          </IconButton>
        </Tooltip>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
        >
          <Typography
            variant="caption"
            textTransform="uppercase"
          >
            Source
          </Typography>
          <ToggleButtonGroup
            size="small"
            value={audioSource}
            exclusive
            onChange={handleChange}
          >
            <ToggleButton
              value={AudioSource.RECORD}
              sx={{ borderRadius: 8, height: 28, fontSize: 12, px: 1.5 }}
            >
              Record
            </ToggleButton>
            <ToggleButton
              value={AudioSource.UPLOAD}
              sx={{ borderRadius: 8, height: 28, fontSize: 12, px: 1.5 }}
            >
              Upload
            </ToggleButton>
            <Tooltip title="Requires your own API key">
              <ToggleButton
                value={AudioSource.CONTENT}
                sx={{ borderRadius: 8, height: 28, fontSize: 12, px: 1.5 }}
              >
                Content
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
        </Stack>
      </Stack>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        fullWidth
        maxWidth="md"
      >
        <Stack
          direction="row"
          sx={{ px: 2, py: 1 }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4">
            How to make an awesome voice clone
          </Typography>
          <Tooltip
            title="Close"
            arrow
            placement="top"
          >
            <IconButton onClick={() => setOpenModal(false)}>
              <Close />
            </IconButton>
          </Tooltip>
        </Stack>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Stack
            direction="column"
            spacing={3}
          >
            <Typography>
              Voice training technique varies by provider. You are using
              Cartesia, our default model. Cartesia is a Solid State Model; its
              architecture varies from other providers so the training rules are
              a little different.
            </Typography>
            <Stack
              direction="column"
              spacing={1}
            >
              <Typography
                fontWeight="bold"
                variant="h5"
              >
                1. 15-20 seconds is all you need
              </Typography>
              <Typography>
                Yes, really. You only need 15-20 seconds of audio to train a
                voice with Cartesia. You can use one sample or multiple. No
                matter what you provide, we'll crop it down to ~ 15 seconds.
              </Typography>
            </Stack>
            <Stack
              direction="column"
              spacing={1}
            >
              <Typography
                fontWeight="bold"
                variant="h5"
              >
                2. Use the emotion you want to emulate
              </Typography>
              <Typography>
                The model will attempt to mimic the input audio in future
                generations. You can alter your intonation and tone to produce
                voices that have different emotions.
              </Typography>
            </Stack>
            <Stack
              direction="column"
              spacing={1}
            >
              <Typography
                fontWeight="bold"
                variant="h5"
              >
                3. Background noise is fine
              </Typography>
              <Typography>
                Don't worry about recording studio quality voice (although
                obviously, if you have it you should use it). Your audio will be
                cleaned to enhance your voice prior to training.
              </Typography>
            </Stack>
            <Stack
              direction="column"
              spacing={1}
            >
              <Typography
                fontWeight="bold"
                variant="h5"
              >
                4. Only one person
              </Typography>
              <Typography>
                This is important. You should only have one person present in
                your selected samples.
              </Typography>
            </Stack>
          </Stack>
          <Alert
            sx={{ mt: 4 }}
            severity="info"
          >
            Need a higher quality clone?{' '}
            <Link
              href="https://duohub.ai/contact"
              target="blank"
            >
              Get in touch
            </Link>{' '}
            with us to discuss your requirements. We have worked with
            celebrities worldwide to create exceptional voice clones and can
            tailor your voice to your needs.
          </Alert>
        </Box>
      </Dialog>
    </Box>
  );
};

export default AudioToggleButtonSource;
