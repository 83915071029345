import React from 'react';
import {
  CardContent,
  ToggleButton,
  Box,
  Stack,
  Paper,
  Card,
  ToggleButtonGroup,
} from '@mui/material';

import { TabPanel } from 'components/atoms';
import { ModelTabView } from 'types/app';
import { tabs } from './tab-definition';
import { Call } from 'components/organisms';

export default function BotConfig() {
  const [value, setValue] = React.useState<ModelTabView>('instructions');

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: ModelTabView,
  ) => {
    if (newValue !== null) {
      setValue(newValue);
    }
  };

  return (
    <Card sx={{ mt: 2, p: 2, minHeight: '85vh' }}>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Paper
          elevation={0}
          sx={{
            display: 'flex',
            borderRadius: 40,
            height: 28,
            mb: 2,
          }}
        >
          <ToggleButtonGroup
            size="small"
            value={value}
            onChange={handleChange}
            exclusive
          >
            {tabs.map((tab) => (
              <ToggleButton
                key={tab.value}
                value={tab.value}
                aria-label={tab.label}
                sx={{
                  borderRadius: 40,
                  height: 28,
                  fontSize: 12,
                  px: 1.5,
                }}
              >
                {tab.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Paper>
        <Stack
          direction="row"
          spacing={1.5}
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Call />
        </Stack>
      </Box>
      <Box pt={4}>
        <CardContent style={{ padding: 0, minHeight: '78vh' }}>
          {tabs.map((tab, index) => (
            <TabPanel
              key={tab.label}
              index={index}
              value={tab.key}
            >
              {value === tab.value && tab.component}
            </TabPanel>
          ))}
        </CardContent>
      </Box>
    </Card>
  );
}
