import { useReducer } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FileCategory } from 'types/enums';

export type QueueItem = {
  id: string;
  file: File;
  category: FileCategory;
  numSpeakers: number;
  memoryID?: string;
};

export type State = {
  queue: QueueItem[];
  currentIndex: number;
  isProcessing: boolean;
  speakerDialogOpen: boolean;
  files: any[];
  tempNumSpeakers: number;
  pendingReset: boolean;
};

export type Action =
  | {
      type: 'ADD_FILES';
      payload: { files: File[]; category: FileCategory; memoryID?: string };
    }
  | { type: 'SET_SPEAKERS'; payload: { numSpeakers: number } }
  | { type: 'NEXT_FILE' }
  | { type: 'OPEN_SPEAKER_DIALOG' }
  | { type: 'CLOSE_SPEAKER_DIALOG' }
  | { type: 'RESET' }
  | { type: 'SET_TEMP_SPEAKERS'; payload: { numSpeakers: number } }
  | { type: 'SAVE_SPEAKERS' }
  | { type: 'CANCEL_CURRENT_FILE' }
  | { type: 'RESET_AFTER_CLOSE' }
  | { type: 'PROMPT_SPEAKER_SELECTION' };

export const initialState: State = {
  queue: [],
  currentIndex: 0,
  isProcessing: false,
  speakerDialogOpen: false,
  files: [],
  tempNumSpeakers: 1,
  pendingReset: false,
};

function reducer(state: State, action: Action): State {
  console.log('Reducer called with action:', action.type, 'Payload:', action);
  switch (action.type) {
    case 'ADD_FILES':
      console.log('Adding files to queue:', action.payload.files);
      const newQueue = [
        ...state.queue,
        ...action.payload.files.map((file) => ({
          id: uuidv4(),
          file,
          category: action.payload.category,
          numSpeakers: 1,
          memoryID: action.payload.memoryID,
        })),
      ];
      console.log('New queue:', newQueue);
      return {
        ...state,
        queue: newQueue,
        isProcessing: true,
      };
    case 'OPEN_SPEAKER_DIALOG':
      console.log('Opening speaker dialog');
      return {
        ...state,
        speakerDialogOpen: true,
      };
    case 'CLOSE_SPEAKER_DIALOG':
      console.log('Closing speaker dialog');
      return {
        ...state,
        speakerDialogOpen: false,
        tempNumSpeakers: 1,
      };
    case 'RESET':
      console.log('Resetting state');
      return {
        ...initialState,
      };
    case 'SET_SPEAKERS':
      console.log('Setting speakers:', action.payload.numSpeakers);
      return {
        ...state,
        queue: state.queue.map((item, index) =>
          index === state.currentIndex
            ? { ...item, numSpeakers: action.payload.numSpeakers }
            : item,
        ),
      };
    case 'NEXT_FILE':
      console.log('Moving to next file');
      return {
        ...state,
        currentIndex: state.currentIndex + 1,
        speakerDialogOpen: false,
        tempNumSpeakers: 1,
      };
    case 'SET_TEMP_SPEAKERS':
      console.log('Setting temporary speakers:', action.payload.numSpeakers);
      return {
        ...state,
        tempNumSpeakers: action.payload.numSpeakers,
      };
    case 'SAVE_SPEAKERS':
      console.log('Saving speakers:', state.tempNumSpeakers);
      return {
        ...state,
        queue: state.queue.map((item, index) =>
          index === state.currentIndex
            ? { ...item, numSpeakers: state.tempNumSpeakers }
            : item,
        ),
        speakerDialogOpen: false,
      };
    case 'CANCEL_CURRENT_FILE':
      console.log('Cancelling current file');
      return {
        ...state,
        queue: state.queue.filter((_, index) => index !== state.currentIndex),
        speakerDialogOpen: false,
        tempNumSpeakers: 1,
        pendingReset: true,
      };
    case 'RESET_AFTER_CLOSE':
      console.log('Resetting state after close');
      return {
        ...state,
        pendingReset: false,
        tempNumSpeakers: 1,
      };
    case 'PROMPT_SPEAKER_SELECTION':
      console.log('Prompting for speaker selection');
      return {
        ...state,
        speakerDialogOpen: true,
      };
    default:
      return state;
  }
}

export function useContentReducer() {
  return useReducer(reducer, initialState);
}
export { ContentContextProvider } from './state';
export { useContent } from './state';
