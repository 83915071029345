import { createContext, useContext, useState, ReactNode, useMemo } from 'react';

interface ProContextState {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
}

const ProContext = createContext<ProContextState | undefined>(undefined);

export const useProContext = () => {
  const context = useContext(ProContext);
  if (!context) {
    throw new Error('useProContext must be used within a ProContextProvider');
  }
  return context;
};

interface ProContextProviderProps {
  children: ReactNode;
}

export function ProContextProvider({
  children,
}: ProContextProviderProps): JSX.Element {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const contextValue = useMemo(
    () => ({
      setOpenModal,
      openModal,
    }),
    [openModal],
  );

  return (
    <ProContext.Provider value={contextValue}>{children}</ProContext.Provider>
  );
}
