import { LoadingButton } from '@mui/lab';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'use/snackbar';
import { useState } from 'react';
import { useDefaultTemplate } from 'components/templates';
import { ButtonIconTooltip } from 'components/atoms';
import {
  useGetBotsQuery,
  useGetUserQuery,
  useUpdateModelMutation,
  useUpdatePromptMutation,
  useUpdateBotMutation,
  UpdateBotInput,
} from 'graphql/generated';
import { backgroundColors, eyeStyles, mouthStyles } from 'common/avatar';
import mixpanel from 'mixpanel-browser';
import { useNavigate } from 'react-router-dom';

interface NewBotProps {
  title: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onUpdate: () => void;
}

export function NewBot({ title, open, setOpen, onUpdate }: NewBotProps) {
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  const [botName, setBotName] = useState<string>('');
  const [botDescription, setBotDescription] = useState<string>('');
  const { showSnackbar } = useSnackbar();
  const { data: userData } = useGetUserQuery({
    input: {},
  });
  const [updateBot] = useUpdateBotMutation();
  const [updateModel] = useUpdateModelMutation();
  const [updatePrompt] = useUpdatePromptMutation();
  const { refetch: refetchBots } = useGetBotsQuery({
    input: {},
  });
  const user = userData?.getUser;
  const { botID, template } = useDefaultTemplate();

  const getRandomElement = (array: string[]) => {
    return array[Math.floor(Math.random() * array.length)];
  };

  const handleToggleOpen = () => {
    setOpen(!open);
  };

  const createTwin = async () => {
    try {
      setSaving(true);

      await updatePrompt({ input: template.startPrompt }).unwrap();
      await updatePrompt({ input: template.mainPrompt }).unwrap();
      await updateModel({ input: template.llm }).unwrap();
      await updateModel({ input: template.asr }).unwrap();
      await updateModel({ input: template.tts }).unwrap();

      const modelConfigInput: UpdateBotInput = {
        id: botID,
        name: botName,
        description: botDescription,
        unread: 0,
        active: true,
        userID: user?.id,
        backgroundColor: getRandomElement(backgroundColors),
        eyes: getRandomElement(eyeStyles),
        mouth: getRandomElement(mouthStyles),
      };

      const result = await updateBot({ input: modelConfigInput }).unwrap();
      await refetchBots();
      if (result.updateBot?.success) {
        mixpanel.track('create_bot');
        showSnackbar('Created AI bot', 'success');
        onUpdate();
        setOpen(false);
        setBotName('');
        setBotDescription('');
        navigate(`/studio/bot/${botID}`);
      } else {
        showSnackbar('Failed to create AI bot', 'error');
      }
    } catch (e) {
      console.error(e);
      showSnackbar(`Failed to create twin: ${e as string}`, 'error');
    } finally {
      setSaving(false);
    }
  };

  const handleClick = () => {
    createTwin();
  };

  const disable = () => {
    if (!botName || !botDescription) {
      return true;
    }
    return false;
  };

  return (
    <Box>
      <Dialog
        maxWidth="xs"
        fullWidth
        onClose={handleToggleOpen}
        open={open}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ px: 2, py: 1 }}
        >
          <Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h4"
            component="div"
          >
            {title || 'New Model'}
          </Typography>

          <ButtonIconTooltip
            onClick={() => setOpen(false)}
            isLoading={false}
            tooltipTitle="Close"
            icon="close"
            tooltipColorVariant="info"
          />
        </Stack>
        <Divider />
        <DialogContent>
          <Stack
            sx={{ pt: 2 }}
            spacing={2}
          >
            <TextField
              size="small"
              fullWidth
              label="Name"
              variant="outlined"
              placeholder="What is this bot's name?"
              value={botName}
              onChange={(e) => {
                setBotName(e.target.value);
              }}
            />
            <TextField
              size="small"
              fullWidth
              label="Subject"
              variant="outlined"
              placeholder="What will this bot talk about?"
              value={botDescription}
              onChange={(e) => {
                setBotDescription(e.target.value);
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <LoadingButton
            disabled={disable()}
            loading={saving}
            size="small"
            onClick={handleClick}
            variant="contained"
            sx={{ fontWeight: 'bold', height: 28 }}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
