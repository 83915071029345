import { MemoryContextProvider, useMemoryContext } from './state';

export const useMemory = () => {
  const context = useMemoryContext();

  return {
    ...context,
  };
};

export { MemoryContextProvider };
