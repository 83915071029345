import {
  Box,
  Card,
  CardContent,
  Chip,
  Stack,
  Typography,
  Button,
  Skeleton,
} from '@mui/material';
import {
  AutoAwesome,
  AutoFixHigh,
  CreditCard,
  Flare,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { blue, indigo, cyan, teal, purple, grey } from '@mui/material/colors';
import { usePro } from 'use/pro';
import {
  useGetUserQuery,
  useLazyGetCheckoutQuery,
  useLazyGetCustomerPortalQuery,
} from 'graphql/generated';
import { useMemo } from 'react';
import { PlanType } from 'types/enums';
import mixpanel from 'mixpanel-browser';

export default function Billing() {
  const [getCheckout, { isLoading: isLoadingCheckout }] =
    useLazyGetCheckoutQuery();
  const [getCustomerPortal, { isLoading: isLoadingCustomerPortal }] =
    useLazyGetCustomerPortalQuery();
  const { data } = useGetUserQuery({
    input: {},
  });
  const user = useMemo(() => data?.getUser, [data]);
  const { firstName } = user ?? {};

  const isPro = user?.plan === PlanType.pro;
  const isStarter = user?.plan === PlanType.starter;
  const loading = !user?.plan;
  const background = loading
    ? grey[700]
    : isStarter
      ? `linear-gradient(45deg, ${purple[700]}, ${indigo[800]})`
      : isPro
        ? `linear-gradient(45deg, ${indigo[700]}, ${blue[800]})`
        : `linear-gradient(45deg, ${cyan[700]}, ${teal[400]})`;
  const chipLabel = isStarter
    ? 'Starter Subscriber'
    : isPro
      ? 'Pro Subscriber'
      : 'Basic Subscriber';
  const chipColor = isStarter ? purple[200] : isPro ? indigo[200] : teal[900];
  const message = isStarter
    ? `Here's to starting up${firstName ? `, ${firstName}` : ''}`
    : isPro
      ? `Thanks for being a pro${firstName ? `, ${firstName}` : ''}`
      : `Let's make magic happen${firstName ? `, ${firstName}` : ''}`;
  const icon = isStarter ? (
    <AutoAwesome sx={{ fontSize: '48px' }} />
  ) : isPro ? (
    <Flare sx={{ fontSize: '48px' }} />
  ) : (
    <AutoFixHigh sx={{ fontSize: '48px' }} />
  );
  const { setOpenModal } = usePro();

  const handleGetCheckout = async () => {
    try {
      const priceID = process.env.REACT_APP_PRO_PRICE_ID;
      const { getCheckout: checkout } = await getCheckout({
        input: {
          priceID,
          planType: PlanType.pro,
        },
      }).unwrap();
      console.log('Checkout data:', checkout);
      mixpanel.track('get_checkout', {
        'Plan Type': PlanType.pro,
      });
      window.location.href = checkout.url;
    } catch (error) {
      console.error('Error fetching checkout data:', error);
    }
  };

  const handleGetCustomerPortal = async () => {
    try {
      const { getCustomerPortal: customerPortal } = await getCustomerPortal({
        input: {},
      }).unwrap();
      window.location.href = customerPortal.url;
    } catch (error) {
      console.error('Error fetching customer portal data:', error);
    }
  };

  const isLoading = !data || !user;

  return (
    <Box>
      <Card
        sx={{
          height: 200,
          position: 'relative',
          background: background,
        }}
      >
        <CardContent
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            position: 'relative',
          }}
        >
          <Stack
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
            direction="column"
            spacing={2}
            alignItems="flex-start"
          >
            {isLoading ? (
              <Skeleton
                variant="rectangular"
                width={100}
                height={24}
                sx={{ borderRadius: 40 }}
              />
            ) : (
              <Chip
                sx={{
                  fontWeight: 'bold',
                  color: chipColor,
                }}
                label={chipLabel}
              />
            )}
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
            >
              {isLoading ? (
                <>
                  <Skeleton
                    variant="text"
                    width={500}
                    height={48}
                    sx={{ borderRadius: 40 }}
                  />
                  <Skeleton
                    variant="circular"
                    width={48}
                    height={48}
                    sx={{ borderRadius: 40 }}
                  />
                </>
              ) : (
                <>
                  <Typography
                    variant="h1"
                    sx={{ fontSize: 40 }}
                  >
                    {message}
                  </Typography>
                  {icon}
                </>
              )}
            </Stack>
            <Stack
              direction="row"
              spacing={2}
            >
              {isLoading ? (
                <>
                  <Skeleton
                    variant="rectangular"
                    width={100}
                    height={24}
                    sx={{ borderRadius: 40 }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={120}
                    height={24}
                    sx={{ borderRadius: 40 }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={130}
                    height={24}
                    sx={{ borderRadius: 40 }}
                  />
                </>
              ) : (
                <>
                  {user?.plan === PlanType.free ? (
                    <LoadingButton
                      sx={{ borderRadius: 40, height: 24, fontWeight: 'bold' }}
                      variant="contained"
                      size="small"
                      onClick={() => setOpenModal(true)}
                      loading={isLoadingCheckout}
                      startIcon={<AutoAwesome />}
                    >
                      Upgrade
                    </LoadingButton>
                  ) : user?.plan === PlanType.starter ? (
                    <LoadingButton
                      sx={{ borderRadius: 40, height: 24, fontWeight: 'bold' }}
                      variant="contained"
                      size="small"
                      startIcon={<Flare />}
                      onClick={handleGetCheckout}
                      loading={isLoadingCheckout}
                    >
                      Get Pro
                    </LoadingButton>
                  ) : null}
                  <Button
                    sx={{ borderRadius: 40, height: 24, fontWeight: 'bold' }}
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    Compare Plans
                  </Button>
                  {(isPro || isStarter) && (
                    <LoadingButton
                      sx={{ borderRadius: 40, height: 24, fontWeight: 'bold' }}
                      variant="outlined"
                      size="small"
                      startIcon={<CreditCard />}
                      onClick={handleGetCustomerPortal}
                      loading={isLoadingCustomerPortal}
                    >
                      Manage Billing
                    </LoadingButton>
                  )}
                </>
              )}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}
