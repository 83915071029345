import { FiberManualRecord } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { AppFile } from 'types/app';

export const getStatusIcons = (file: AppFile) => {
  const statusConfig = [
    {
      key: 'bronze',
      filled: file?.bronzeKey || file?.externalURI,
      color: '#CD7F32',
      title: 'File Received',
    },
    {
      key: 'silver',
      filled:
        file?.silverTranscriptKey ||
        file?.silverAudioKey ||
        (file?.hiddenFilesTotal !== null &&
          file?.hiddenFilesTotal <= file?.hiddenFilesCreated),
      color: '#C0C0C0',
      title: 'File Processed',
    },
    {
      key: 'gold',
      filled:
        file?.goldAudioKey ||
        file?.goldGraphKey ||
        file?.goldChunkKey ||
        (file?.hiddenFilesTotal !== null &&
          file?.hiddenFilesTotal <= file?.hiddenFilesProcessed),
      color: '#FFD700',
      title: 'Ready for Use',
    },
  ];

  return statusConfig.map(({ key, filled, color, title }) => (
    <Tooltip
      title={title}
      key={`${key}-${file?.id}`}
    >
      <FiberManualRecord
        sx={{
          color: 'white',
          opacity: filled ? 1 : 0.2,
          fontSize: 'small',
        }}
      />
    </Tooltip>
  ));
};
