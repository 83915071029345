import { Area } from 'recharts';
import { DateTime } from 'luxon';

import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Paper,
  Typography,
} from '@mui/material';
import { formatCallDuration } from 'common/utils';
import {
  AreaChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import { useGetCallUsageQuery } from 'graphql/generated';

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
};

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <Paper
        elevation={3}
        sx={{ p: 2, border: '1px solid #2a2a2a' }}
      >
        <Typography variant="body2">{formatDate(label)}</Typography>
        <Typography variant="body2">
          {formatCallDuration(payload[0].value)}
        </Typography>
      </Paper>
    );
  }
  return null;
};

export default function UsageCall() {
  const userTimezone = DateTime.local().zoneName;
  const { data: rawUsageData, isLoading: isUsageLoading } =
    useGetCallUsageQuery({
      input: {
        timeZone: userTimezone,
      },
    });
  const callUsageData = rawUsageData?.getCallUsage?.data;
  return (
    <Card sx={{ minHeight: 200 }}>
      <CardContent>
        <Typography variant="h6">Call Minutes Usage</Typography>
        <Box
          sx={{
            height: 150,
            width: '100%',
            mt: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {isUsageLoading ? (
            <CircularProgress />
          ) : (
            <ResponsiveContainer
              width="100%"
              height="100%"
            >
              <AreaChart
                data={callUsageData}
                margin={{ top: 5, right: 0, left: -40, bottom: 5 }}
              >
                <XAxis
                  dataKey="date"
                  tick={{ fontSize: 10 }}
                  tickFormatter={formatDate}
                />
                <YAxis
                  dataKey="seconds"
                  tick={{ fontSize: 10 }}
                />
                <Tooltip content={<CustomTooltip />} />
                <defs>
                  <linearGradient
                    id="colorSeconds"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop
                      offset="5%"
                      stopColor="#8884d8"
                      stopOpacity={0.8}
                    />
                    <stop
                      offset="95%"
                      stopColor="#8884d8"
                      stopOpacity={0}
                    />
                  </linearGradient>
                </defs>
                <Area
                  type="monotone"
                  dataKey="seconds"
                  stroke="#8884d8"
                  fillOpacity={1}
                  fill="url(#colorSeconds)"
                />
              </AreaChart>
            </ResponsiveContainer>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
