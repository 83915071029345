import { Close, DeleteOutline, MoreVert } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Link,
  Typography,
} from '@mui/material';
import { getVoiceStepindex } from 'common/utils';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useDeleteVoiceMutation,
  useGetSamplesQuery,
  useGetVoiceQuery,
  useLazyGetSampleBatchQuery,
  useLazyGetTrainedVoiceQuery,
  useUpdateVoiceMutation,
} from 'graphql/generated';
import { AIProvider, SampleType, VoiceStep } from 'types/enums';
import { useAudio } from 'use/audio';

export default function EndAction() {
  const navigate = useNavigate();
  const { cleaningFinished, setCleaningFinished } = useAudio();
  const { voiceID } = useParams<{ voiceID: string }>();
  console.log('voiceID', voiceID);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [updateVoice, { isLoading }] = useUpdateVoiceMutation();
  const [deleteVoice, { isLoading: isDeleting }] = useDeleteVoiceMutation();
  const {
    data: sampleData,
    isLoading: isLoadingSamples,
    refetch: refetchSamples,
  } = useGetSamplesQuery({
    input: {
      voiceID,
      type: SampleType.human,
    },
  });
  const [getTrainedVoice] = useLazyGetTrainedVoiceQuery();
  const { data: voiceData, isLoading: isLoadingVoice } = useGetVoiceQuery({
    input: {
      id: voiceID,
    },
  });
  const voice = voiceData?.getVoice?.data;
  const samples = useMemo(
    () => sampleData?.getSamples?.data || [],
    [sampleData],
  );
  const [apiKeyDialogOpen, setApiKeyDialogOpen] = useState<boolean>(false);
  const [apiKey, setApiKey] = useState<string>('');
  const [cleaningSamples, setCleaningSamples] = useState<string[]>([]);
  const handleVoiceDelete = async () => {
    await deleteVoice({ input: { id: voiceID } });
    navigate('/studio/voice');
  };
  const [getSampleBatch] = useLazyGetSampleBatchQuery();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const progressVoice = useCallback(async () => {
    switch (voice?.step) {
      case VoiceStep.select:
        setCleaningFinished(false);
        await updateVoice({
          input: {
            id: voiceID,
            step: VoiceStep.clean,
          },
        }).unwrap();
        setCleaningSamples([]);
        await getSampleBatch({
          input: {
            voiceID,
          },
        }).unwrap();
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await refetchSamples();
        break;
      case VoiceStep.clean:
        await updateVoice({
          input: {
            id: voiceID,
            step: VoiceStep.train,
          },
        }).unwrap();
        await getTrainedVoice({ input: { voiceID } });
        setCleaningFinished(false);
        break;
      case VoiceStep.train:
        await updateVoice({
          input: {
            id: voiceID,
            step: VoiceStep.review,
          },
        }).unwrap();
        break;
      case VoiceStep.review:
        await updateVoice({
          input: {
            id: voiceID,
            step: VoiceStep.complete,
          },
        }).unwrap();
        break;
      case VoiceStep.complete:
        navigate(`/studio/voice/`);
        break;
    }
  }, [
    voice?.step,
    setCleaningFinished,
    updateVoice,
    voiceID,
    getSampleBatch,
    refetchSamples,
    getTrainedVoice,
    navigate,
  ]);

  const updateVoiceProvider = async (provider: AIProvider) => {
    console.log('updateVoiceProvider', provider);
    await updateVoice({
      input: {
        id: voiceID,
        provider,
      },
    });
  };

  const handleAddAPIKey = async () => {
    await updateVoice({
      input: {
        id: voiceID,
        apiKey,
      },
    });
    setApiKey('');
    setApiKeyDialogOpen(false);
  };

  const disableNext = useMemo(() => {
    if (voice?.step === VoiceStep.select) {
      return samples?.length < 1;
    }
    if (voice?.step === VoiceStep.clean) {
      return !cleaningFinished;
    }
    if (voice?.step === VoiceStep.train) {
      return !Boolean(voice?.apiVoiceID);
    }
    return false;
  }, [voice?.step, voice?.apiVoiceID, samples?.length, cleaningFinished]);

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      sx={{ pr: 1 }}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ pr: 2 }}
      >
        <Typography
          textTransform="uppercase"
          variant="caption"
        >
          Provider
        </Typography>
        <Box>
          <ToggleButtonGroup
            disabled={
              getVoiceStepindex(voice?.step as VoiceStep) >= 2 || isLoadingVoice
            }
            onChange={(event, value) => {
              if (value) {
                updateVoiceProvider(value);
              }
            }}
            value={voice?.provider}
            size="small"
            exclusive
          >
            <Tooltip
              arrow
              title={
                <Stack direction="column">
                  <Typography
                    variant="caption"
                    sx={{ fontSize: 12 }}
                  >
                    Can be used with your API key or ours.
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ fontSize: 12 }}
                  >
                    <Link
                      href="https://duohub.ai/contact"
                      target="_blank"
                    >
                      Contact us
                    </Link>{' '}
                    to enable PlayHT in your account.
                  </Typography>
                </Stack>
              }
            >
              <span>
                <ToggleButton
                  disabled
                  sx={{ borderRadius: 8, height: 28, fontSize: 12, px: 1.5 }}
                  value={AIProvider.PLAYHT}
                  selected={voice?.provider === AIProvider.PLAYHT}
                >
                  PlayHT
                </ToggleButton>
              </span>
            </Tooltip>
            <ToggleButton
              sx={{ borderRadius: 8, height: 28, fontSize: 12, px: 1.5 }}
              value={AIProvider.CARTESIA}
              selected={voice?.provider === AIProvider.CARTESIA}
            >
              Cartesia
            </ToggleButton>
            <Tooltip
              arrow
              title={
                <Stack direction="column">
                  <Typography
                    variant="caption"
                    sx={{ fontSize: 12 }}
                  >
                    Requires your own API key.
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ fontSize: 12 }}
                  >
                    <Link
                      href="https://duohub.ai/contact"
                      target="_blank"
                    >
                      Contact us
                    </Link>{' '}
                    to enable ElevenLabs in your account.
                  </Typography>
                </Stack>
              }
            >
              <span>
                <ToggleButton
                  disabled
                  sx={{
                    borderRadius: 8,
                    height: 28,
                    fontSize: 12,
                    px: 1.5,
                  }}
                  onClick={() => setApiKeyDialogOpen(true)}
                  value={AIProvider.ELEVENLABS}
                  selected={voice?.provider === AIProvider.ELEVENLABS}
                >
                  Eleven Labs
                </ToggleButton>
              </span>
            </Tooltip>
          </ToggleButtonGroup>
        </Box>
      </Stack>

      <Tooltip title={samples?.length < 1 && 'Add samples to train your voice'}>
        <span>
          <LoadingButton
            disabled={disableNext || isLoadingVoice || isLoadingSamples}
            size="small"
            variant="contained"
            color="primary"
            loading={isLoading || cleaningSamples.length > 0}
            sx={{ fontWeight: 'bold', height: 28 }}
            onClick={progressVoice}
          >
            {voice?.step === VoiceStep.review
              ? 'Done'
              : voice?.step === VoiceStep.complete
                ? 'Exit'
                : 'Next'}
          </LoadingButton>
        </span>
      </Tooltip>
      <Box>
        <IconButton onClick={handleMenuOpen}>
          <MoreVert />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          slotProps={{
            paper: {
              sx: {
                minWidth: 140,
              },
            },
          }}
        >
          <MenuItem onClick={handleVoiceDelete}>
            <ListItemIcon>
              {isDeleting ? (
                <CircularProgress size={20} />
              ) : (
                <DeleteOutline
                  color="error"
                  fontSize="small"
                />
              )}
            </ListItemIcon>
            <ListItemText
              secondaryTypographyProps={{ color: 'error' }}
              secondary="Delete"
            />
          </MenuItem>
        </Menu>
      </Box>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={apiKeyDialogOpen}
        onClose={() => {
          setApiKeyDialogOpen(false);
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          sx={{ px: 2, py: 1 }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography>Add API Key</Typography>
          <IconButton onClick={() => setApiKeyDialogOpen(false)}>
            <Close />
          </IconButton>
        </Stack>
        <Divider />
        <DialogContent>
          <Stack
            direction="column"
            spacing={1.5}
          >
            <TextField
              size="small"
              fullWidth
              label="API Key"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton
                variant="contained"
                color="primary"
                size="small"
                loading={isLoading}
                onClick={handleAddAPIKey}
              >
                Save
              </LoadingButton>
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
    </Stack>
  );
}
