import {
  FormSection,
  SliderSetting,
  VoiceSettings,
} from '../model-voice/local';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { Model, ModelOptions } from '@duohub/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetModelQuery, useUpdateModelMutation } from 'graphql/generated';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'use/snackbar';
import { AIType } from 'types/enums';

export default function ModelVoiceOptions() {
  const { botID } = useParams();
  const [updateModel] = useUpdateModelMutation();
  const { data } = useGetModelQuery({
    input: {
      botID,
    },
  });
  const { showSnackbar } = useSnackbar();
  const models = useMemo(
    () => data?.getModel?.data || [],
    [data?.getModel?.data],
  );
  const [ttsModel, setTtsModel] = useState<Model>();

  const isModelOptions = (options: unknown): options is ModelOptions => {
    return (
      typeof options === 'object' &&
      options !== null &&
      'stability' in options &&
      typeof (options as Record<string, unknown>).stability === 'number'
    );
  };
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    if (ttsModel?.options && typeof ttsModel.options === 'string') {
      try {
        const parsedOptions = JSON.parse(ttsModel.options) as ModelOptions;
        setTtsModel({
          ...ttsModel,
          options: parsedOptions,
        });
      } catch (error) {
        showSnackbar(
          `Failed to parse model options: ${error as string}`,
          'error',
        );
      }
    }
  }, [showSnackbar, ttsModel]);

  useEffect(() => {
    const ttsModelIndex = models?.findIndex((m) => m.type === AIType.TTS);
    if (ttsModelIndex !== -1 && models[ttsModelIndex]) {
      setTtsModel(models[ttsModelIndex]);
    }
  }, [models]);

  const changeStatus = useCallback(
    (value: boolean): void => {
      if (!changed) {
        setChanged(value);
      }
    },
    [changed, setChanged],
  );
  const handleChange = async (
    field: keyof Model | keyof ModelOptions,
    value: unknown,
  ): Promise<void> => {
    if (ttsModel) {
      console.log('field', field);
      console.log('value', value);
      let updateInput: Partial<Model> = {};
      if (field in ttsModel) {
        updateInput = { id: ttsModel.id, [field]: value };
      } else if (
        isModelOptions(ttsModel.options) &&
        field in ttsModel.options
      ) {
        updateInput = {
          id: ttsModel.id,
          options: { ...ttsModel.options, [field]: value },
        };
      }

      try {
        const result = await updateModel({
          input: {
            id: ttsModel.id,
            ...updateInput,
          },
        }).unwrap();
        if (result.updateModel?.data) {
          setTtsModel(result.updateModel.data);
        }
        changeStatus(true);
      } catch (error) {
        console.error('Failed to update model:', error);
        showSnackbar('Failed to update model', 'error');
      }
    }
  };

  const TTS_SETTINGS: SliderSetting[] = [
    {
      label: 'Stability',
      field: 'stability',
      max: 1,
      step: 0.1,
      value: ttsModel?.options?.stability || 0,
    },
    {
      label: 'Clarity + Similarity',
      field: 'clarity',
      max: 1,
      step: 0.1,
      value: ttsModel?.options?.clarity || 0,
    },
    {
      label: 'Style Exaggeration',
      field: 'exaggeration',
      max: 1,
      step: 0.1,
      value: ttsModel?.options?.exaggeration || 0,
    },
    {
      label: 'Optimize Streaming Latency',
      field: 'streamingLatency',
      max: 4,
      step: 1,
      value: ttsModel?.options?.streamingLatency || 0,
    },
  ];
  return (
    <Box>
      <Box mb={2}>
        <Typography
          color="white"
          variant="h4"
        >
          Voice Configuration
        </Typography>
        <Typography variant="caption">
          Configure additional settings for the voice of your assistant.
        </Typography>
      </Box>

      <FormSection mb={0}>
        <VoiceSettings
          settings={TTS_SETTINGS}
          onChange={handleChange}
        />
        <Grid
          item
          xs={10}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={ttsModel?.options?.useSpeakerBoost || false}
                onChange={(e) =>
                  handleChange('useSpeakerBoost', e.target.checked)
                }
              />
            }
            label="Use Speaker Boost"
          />
        </Grid>
      </FormSection>
    </Box>
  );
}
