import { Box, Divider, Grid, Stack } from '@mui/material';
import { Voice } from '@duohub/types';
import { Loading, Pagination } from 'components/atoms';
import { VoicePreview } from 'components/molecules';
import { useCallback, useEffect, useState } from 'react';
import { useGetVoicesQuery } from 'graphql/generated';

export default function StudioVoices() {
  const [currentPage, setCurrentPage] = useState(1);
  const [tokenStack, setTokenStack] = useState<string[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [nextToken, setNextToken] = useState<string | null>(null);

  const {
    data,
    isLoading: isGettingVoice,
    refetch,
  } = useGetVoicesQuery({
    input: {
      limit: 10,
      nextToken: tokenStack[currentPage],
    },
  });

  const voices = data?.getVoices?.data;

  const handlePageChange = useCallback(
    async (token: string): Promise<{ items: unknown[]; nextToken: string }> => {
      setCurrentPage((prevPage) => {
        const newPage = token === null ? 1 : prevPage + 1;
        setTokenStack((prevStack) => {
          const newStack = [...prevStack];
          newStack[newPage] = token;
          return newStack;
        });
        return newPage;
      });
      const result = await refetch();
      if (result.data && result.data.getVoices && result.data.getVoices.data) {
        return {
          items: result.data.getVoices.data,
          nextToken: result.data.getVoices.nextToken || '',
        };
      }
      throw new Error('Failed to fetch files');
    },
    [refetch],
  );

  useEffect(() => {
    if (data?.getVoices) {
      setTotalCount(data.getVoices.totalCount || 0);
      setNextToken(data.getVoices.nextToken || null);
    }
  }, [data]);

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        <Pagination
          totalCount={totalCount}
          itemsPerPage={10}
          nextToken={nextToken}
          onPageChange={handlePageChange}
        />
      </Stack>
      <Divider sx={{ borderBottom: '0.1px solid #2e2e2e' }} />
      {isGettingVoice ? (
        <Loading />
      ) : (
        <Stack
          direction="column"
          spacing={2}
        >
          <Grid
            container
            spacing={2}
          >
            {voices?.map((voice: Voice) => (
              <VoicePreview
                key={voice?.id}
                voice={voice}
              />
            ))}
          </Grid>
        </Stack>
      )}
    </Box>
  );
}
