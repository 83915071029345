import { Divider, Stack } from '@mui/material';
import { formatCallDuration } from 'common/utils';
import { UsageIndicator } from 'components/atoms';
import { useEffect, useMemo } from 'react';
import { useGetUserQuery } from 'graphql/generated';

export default function AccountUsage({
  noPadding,
  disableTopDivider,
}: {
  noPadding?: boolean;
  disableTopDivider?: boolean;
}) {
  const { data, isLoading, refetch } = useGetUserQuery({
    input: {},
  });

  const user = useMemo(() => data?.getUser, [data]);
  useEffect(() => {
    refetch();
  }, [refetch]);
  const ingressTokensUsed = user?.ingressTokensUsed ?? 0;
  const ingressTokensLimit = user?.ingressTokensLimit ?? 1;
  const egressTokensUsed = user?.egressTokensUsed ?? 0;
  const egressTokensLimit = user?.egressTokensLimit ?? 1;
  const egressCallMinutesUsed = user?.egressCallMinutesUsed ?? 0;
  const egressCallMinutesLimit = user?.egressCallMinutesLimit ?? 1;
  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{ my: noPadding ? 0 : 2 }}
    >
      {!disableTopDivider && <Divider sx={{ my: 2, opacity: 0.5 }} />}
      <UsageIndicator
        title="Graph Ingress Tokens"
        used={ingressTokensUsed}
        limit={ingressTokensLimit}
        formatUsed={(value) => `${value.toLocaleString()} tokens`}
        isLoading={isLoading}
      />
      <Divider sx={{ my: 2, opacity: 0.5 }} />
      <UsageIndicator
        title="Graph Egress Tokens"
        used={egressTokensUsed}
        limit={egressTokensLimit}
        formatUsed={(value) => `${value.toLocaleString()} tokens`}
        isLoading={isLoading}
      />
      <Divider sx={{ my: 2, opacity: 0.5 }} />
      <UsageIndicator
        title="Call Minutes"
        used={egressCallMinutesUsed}
        limit={egressCallMinutesLimit}
        formatUsed={(value) => formatCallDuration(value)}
        isLoading={isLoading}
      />
      <Divider sx={{ my: 2, opacity: 0.5 }} />
    </Stack>
  );
}
