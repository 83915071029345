import { useState, useCallback, useMemo, useEffect } from 'react';
import { Box, CircularProgress, Tooltip } from '@mui/material';
import { AvatarVoice, ModelSettingsSection } from 'components/atoms';
import { Model, Voice } from '@duohub/types';
import { FormSection } from './local';
import { Grid, Card, Typography, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import * as CountryFlags from 'country-flag-icons/react/3x2';
import {
  useGetModelQuery,
  useGetVoicesQuery,
  useLazyGetSignedCloudFrontUrlQuery,
  useUpdateModelMutation,
} from 'graphql/generated';
import { useParams } from 'react-router-dom';
import { AIType, VoiceStep } from 'types/enums';
import { useSnackbar } from 'use/snackbar';
import FiberManualRecord from '@mui/icons-material/FiberManualRecord';
import styled from '@mui/material/styles/styled';
import Stack from '@mui/material/Stack';
import { MemoryStep } from 'types/enums';
import { getVoiceStepindex } from 'common/utils';

type VoiceWithIcon = Voice & {
  icon?: string;
  flag?: string;
  gender?: string;
};

const DEFAULT_VOICES: VoiceWithIcon[] = [
  {
    id: '13524ffb-a918-499a-ae97-c98c7c4408c4',
    name: 'Katherine',
    icon: '/icons/male.svg',
    flag: 'AU',
    gender: 'male',
    description: 'Male voice with an Australian accent',
  },
  {
    id: '8985388c-1332-4ce7-8d55-789628aa3df4',
    name: 'Vivian',
    icon: '/icons/female.svg',
    flag: 'AU',
    gender: 'female',
    description: 'Female narrator voice with an Australian accent',
  },
  {
    id: '043cfc81-d69f-4bee-ae1e-7862cb358650',
    name: 'Eliza',
    icon: '/icons/female.svg',
    flag: 'AU',
    gender: 'female',
    description: 'Female voice with an Australian accent',
  },
  {
    id: '79a125e8-cd45-4c13-8a67-188112f4dd22',
    name: 'Alexander',
    icon: '/icons/female.svg',
    flag: 'GB',
    gender: 'female',
    description: 'Female voice with a British accent',
  },
  {
    id: '00a77add-48d5-4ef6-8157-71e5437b282d',
    name: 'Jessica',
    icon: '/icons/female.svg',
    flag: 'US',
    gender: 'female',
    description: 'Female voice with an American accent',
  },
  {
    id: '95856005-0332-41b0-935f-352e296aa0df',
    name: 'Chase',
    icon: '/icons/male.svg',
    flag: 'GB',
    gender: 'male',
    description: 'Male voice with a British accent',
  },
  {
    id: '5c42302c-194b-4d0c-ba1a-8cb485c84ab9',
    name: 'Leo',
    icon: '/icons/female.svg',
    flag: 'US',
    gender: 'female',
    description: 'Friendly female voice with an American accent',
  },
  {
    id: '421b3369-f63f-4b03-8980-37a44df1d4e8',
    name: 'Oliver',
    icon: '/icons/male.svg',
    flag: 'AU',
    gender: 'male',
    description: 'Friendly male voice with an Australian accent',
  },
  {
    id: 'ee7ea9f8-c0c1-498c-9279-764d6b56d189',
    name: 'Destiny',
    icon: '/icons/male.svg',
    flag: 'US',
    gender: 'male',
    description: 'Polite male voice with an American accent',
  },
];

const StepIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const VoiceSelect = ({
  onVoiceSelect,
  selectedVoiceId,
  userVoices = [],
  loading,
}: {
  onVoiceSelect: (voiceId: string) => void;
  selectedVoiceId: string | undefined;
  userVoices: VoiceWithIcon[];
  loading: boolean;
  ttsModel: Model;
}) => {
  const [playingVoice, setPlayingVoice] = useState<string | null>(null);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const steps = ['Select', 'Settings', 'Ingest', 'Review'];
  const [getCloudfrontUrl] = useLazyGetSignedCloudFrontUrlQuery();
  const [loadingVoiceID, setLoadingVoiceID] = useState<string | null>(null);
  const playVoice = useCallback(
    async (voiceId: string) => {
      if (playingVoice === voiceId) {
        audio?.pause();
        setPlayingVoice(null);
      } else {
        if (audio) {
          audio.pause();
        }
        setLoadingVoiceID(voiceId);
        const signedCloudfrontUrl = await getCloudfrontUrl({
          input: {
            key: `audio/${voiceId}.wav`,
            type: 'CONTENT',
          },
        });
        const newAudio = new Audio(
          signedCloudfrontUrl.data?.getSignedCloudFrontUrl.uri,
        );
        setLoadingVoiceID(null);
        newAudio.play();
        newAudio.onended = () => {
          setPlayingVoice(null);
        };
        setAudio(newAudio);
        setPlayingVoice(voiceId);
      }
    },
    [playingVoice, audio, getCloudfrontUrl, setLoadingVoiceID, setPlayingVoice],
  );

  const handleVoiceSelect = useCallback(
    (voiceId: string) => {
      onVoiceSelect(voiceId);
    },
    [onVoiceSelect],
  );

  const renderVoiceCards = (
    voiceList: VoiceWithIcon[],
    sectionTitle: string,
  ) => (
    <>
      <Grid
        item
        xs={12}
      >
        <Typography
          variant="h5"
          sx={{ my: 2 }}
        >
          {sectionTitle}
        </Typography>
      </Grid>
      {voiceList.map((voice) => {
        const countryCode = voice.flag;
        const FlagComponent = countryCode
          ? CountryFlags[countryCode as keyof typeof CountryFlags]
          : null;
        const activeStep = getVoiceStepindex(voice?.step as VoiceStep);
        const isDisabled =
          sectionTitle === 'My Voices' && voice.step
            ? voice.step !== MemoryStep.review &&
              voice.step !== MemoryStep.complete
            : false;

        const isSelected =
          sectionTitle === 'Standard Voices'
            ? voice.id === selectedVoiceId
            : voice.apiVoiceID === selectedVoiceId;

        return (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={voice.id}
          >
            <Card
              elevation={isSelected ? 0 : 3}
              sx={{
                cursor: !isDisabled ? 'pointer' : 'not-allowed',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                opacity: !isDisabled ? 1 : 0.75,
              }}
              onClick={() =>
                !isDisabled &&
                handleVoiceSelect(
                  sectionTitle === 'My Voices' ? voice.apiVoiceID : voice.id,
                )
              }
            >
              <Tooltip
                placement="top"
                title={voice.description}
                arrow
              >
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  sx={{ p: 1 }}
                >
                  {FlagComponent && (
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1}
                    >
                      <FlagComponent
                        style={{ width: '24px', marginRight: '8px' }}
                      />
                    </Stack>
                  )}
                  {sectionTitle === 'Standard Voices' && (
                    <Tooltip
                      placement="top"
                      title={voice.description}
                      arrow
                    >
                      <AvatarVoice name={voice.name} />
                    </Tooltip>
                  )}
                  {sectionTitle === 'My Voices' && (
                    <Box sx={{ width: '100%' }}>
                      <Typography
                        align="center"
                        variant="h4"
                      >
                        {voice.name}
                      </Typography>
                    </Box>
                  )}
                  {sectionTitle === 'Standard Voices' && (
                    <IconButton
                      aria-label="play"
                      onClick={(e) => {
                        e.stopPropagation();
                        playVoice(voice.id);
                      }}
                    >
                      {loadingVoiceID === voice.id ? (
                        <CircularProgress size={24} />
                      ) : playingVoice === voice.id ? (
                        <PauseIcon />
                      ) : (
                        <PlayArrowIcon />
                      )}
                    </IconButton>
                  )}
                </Stack>
              </Tooltip>

              {sectionTitle === 'My Voices' && (
                <Box
                  sx={{ pb: 2 }}
                  display="flex"
                  justifyContent="center"
                >
                  <Tooltip
                    arrow
                    placement="top"
                    title={`${activeStep > 3 ? 'Ready to Use' : 'Incomplete: ' + steps[activeStep]}`}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                    >
                      {steps.map((step, index) => (
                        <StepIcon
                          key={step}
                          sx={{
                            opacity: index <= activeStep ? 1 : 0.3,
                            cursor: 'pointer',
                          }}
                        >
                          {index <= activeStep ? (
                            <FiberManualRecord
                              sx={{ fontSize: '10px' }}
                              color="primary"
                            />
                          ) : (
                            <FiberManualRecord
                              sx={{ opacity: 0.3, fontSize: '10px' }}
                            />
                          )}
                        </StepIcon>
                      ))}
                    </Stack>
                  </Tooltip>
                </Box>
              )}
            </Card>
          </Grid>
        );
      })}
    </>
  );

  return (
    <Box sx={{ p: 2 }}>
      <Grid
        container
        spacing={{ xs: 3, sm: 2 }}
      >
        {!loading &&
          userVoices.length > 0 &&
          renderVoiceCards(userVoices, 'My Voices')}
        {renderVoiceCards(DEFAULT_VOICES, 'Standard Voices')}
      </Grid>
    </Box>
  );
};

export function ModelVoice() {
  const { botID } = useParams();
  const { data: userVoiceData, isLoading: userVoiceLoading } =
    useGetVoicesQuery({
      input: {},
    });
  const userVoices = useMemo(
    () => userVoiceData?.getVoices?.data || [],
    [userVoiceData?.getVoices?.data],
  );
  const { data } = useGetModelQuery({
    input: {
      botID,
    },
  });
  const models = useMemo(
    () => data?.getModel?.data || [],
    [data?.getModel?.data],
  );
  const [ttsModel, setTtsModel] = useState<Model>();
  const [updateModel] = useUpdateModelMutation();
  const { showSnackbar } = useSnackbar();
  const [selectedVoiceId, setSelectedVoiceId] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    const ttsModelIndex = models?.findIndex((m) => m.type === AIType.TTS);
    if (ttsModelIndex !== -1 && models[ttsModelIndex]) {
      setTtsModel(models[ttsModelIndex]);
    }
  }, [models]);

  useEffect(() => {
    if (ttsModel?.voiceID) {
      setSelectedVoiceId(ttsModel.voiceID);
    }
  }, [ttsModel]);

  const handleVoiceSelect = useCallback(
    (voiceId: string) => {
      if (ttsModel) {
        setSelectedVoiceId(voiceId);
        updateModel({
          input: {
            id: ttsModel.id,
            voiceID: voiceId,
          },
        })
          .then(() => {
            showSnackbar('Voice updated successfully', 'success');
          })
          .catch((error) => {
            console.error('Failed to update voice:', error);
            showSnackbar('Failed to update voice', 'error');
            setSelectedVoiceId(ttsModel.voiceID);
          });
      }
    },
    [ttsModel, updateModel, showSnackbar],
  );

  return (
    <Box>
      <ModelSettingsSection
        title="Voice Configuration"
        subtitle="Choose from the list of default voices."
      >
        <FormSection>
          <VoiceSelect
            onVoiceSelect={handleVoiceSelect}
            selectedVoiceId={selectedVoiceId}
            userVoices={userVoices}
            loading={userVoiceLoading}
            ttsModel={ttsModel}
          />
        </FormSection>
      </ModelSettingsSection>
    </Box>
  );
}
