import { Skeleton, Stack, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useGetUserQuery } from 'graphql/generated';

export default function UserTitle() {
  const { data } = useGetUserQuery({
    input: {},
  });
  const user = data?.getUser;
  const location = useLocation();

  if (location.pathname === '/') {
    return <Typography variant="h4">Users</Typography>;
  }

  if (location.pathname.includes('/bots')) {
    return <Typography variant="h4">Bots</Typography>;
  }

  if (location.pathname.includes('/user/')) {
    if (!user?.firstName && !user?.id && !user?.id) {
      return (
        <Stack
          direction="row"
          spacing={2}
        >
          <Skeleton
            height="40px"
            width="50px"
          />
          <Skeleton
            height="40px"
            width="80px"
          />
        </Stack>
      );
    }

    const userTitle =
      user?.firstName && user?.lastName
        ? `${user.firstName} ${user.lastName}`
        : user?.id || user?.id || null;

    return userTitle ? <Typography variant="h4">{userTitle}</Typography> : null;
  }

  return null;
}
