import { HttpMethod, GetSignedS3UrlInput, UploadUrl } from '@duohub/types';
import { GraphQLQuery, generateClient } from 'aws-amplify/api';
import { AppsyncErrorResponse, appsyncErrorConverter } from './errors';
import { getCurrentCredentials } from './auth/api';
import { ObjectType } from 'types/enums';

export const getSignedUploadUrl = async ({
  key,
  bucketName,
  method,
}: {
  key: string;
  bucketName: string;
  method?: HttpMethod;
}) => {
  const uploadContentInput: GetSignedS3UrlInput = {
    method: method ? method : 'PUT',
    key,
    type: ObjectType.CONTENT,
    expiry: 60 * 60 * 24,
    bucketName,
  };

  console.log('uploadContentInput', uploadContentInput);

  const getSignedS3UrlQuery = `
  query GetSignedS3Url ($input: GetSignedS3UrlInput!) {
    getSignedS3Url(input: $input) {
      type
      url
      headers
      }
    }
  `;

  const { jwt } = await getCurrentCredentials();
  const client = generateClient({ authToken: jwt, authMode: 'lambda' });

  try {
    console.log('uploadContentInput', uploadContentInput);

    const res = await client.graphql<
      GraphQLQuery<{ getSignedS3Url: UploadUrl }>
    >({
      query: getSignedS3UrlQuery,
      variables: { input: uploadContentInput },
    });

    return res;
  } catch (err: unknown) {
    console.error('GraphQL operation failed');
    console.error('Error:', err);
    throw appsyncErrorConverter(
      err as AppsyncErrorResponse,
      getSignedS3UrlQuery,
      { input: uploadContentInput },
    );
  }
};

export async function executeGraphqlOperation<
  T,
  V extends Record<string, unknown>,
>(query: string, variables: V, authToken: string) {
  const client = generateClient({ authToken, authMode: 'lambda' });
  try {
    const res = await client.graphql<GraphQLQuery<T>>({
      query,
      variables: variables as any,
    });

    return res;
  } catch (err: unknown) {
    console.error('GraphQL operation failed');
    console.error('Error:', err);
    throw appsyncErrorConverter(err as AppsyncErrorResponse, query, variables);
  }
}
