import {
  Box,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';

export const UsageIndicator = ({
  title,
  used,
  limit,
  formatUsed,
  isLoading,
}) => {
  const percentage = (used / limit) * 100;
  const isOverLimit = percentage > 100;
  const displayPercentage = Math.min(percentage, 100);
  const overPercentage = isOverLimit ? (percentage - 100).toFixed(1) : 0;

  const getColorForPercentage = (percent) => {
    if (percent > 100) return 'error.main';
    if (percent > 90) return 'error.main';
    if (percent > 80) return 'warning.main';
    if (percent > 70) return 'info.main';
    return 'inherit';
  };

  return (
    <>
      <Typography
        variant="h6"
        sx={{ mt: 2 }}
      >
        {title}
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Box minWidth={120}>
          {isLoading ? (
            <Skeleton
              width={120}
              height={24}
            />
          ) : (
            <Typography variant="body2">{formatUsed(used)} used</Typography>
          )}
        </Box>
        <LinearProgress
          sx={{ borderRadius: 2, flexGrow: 1 }}
          variant="determinate"
          value={displayPercentage}
        />
        <Box minWidth={120}>
          {isLoading ? (
            <Skeleton
              width={120}
              height={24}
            />
          ) : (
            <Typography
              variant="body2"
              align="right"
              sx={{
                color: getColorForPercentage(percentage),
                fontWeight:
                  getColorForPercentage(percentage) !== 'inherit'
                    ? 'bold'
                    : 'normal',
              }}
            >
              {isOverLimit
                ? `${overPercentage}% over limit`
                : `${(100 - displayPercentage).toFixed(1)}% remaining`}
            </Typography>
          )}
        </Box>
      </Stack>
    </>
  );
};
