import { useMemo } from 'react';
import { createAvatar } from '@dicebear/core';
import { botttsNeutral } from '@dicebear/collection';
import { Avatar, AvatarProps } from '@mui/material';
import { Bot } from '@duohub/types';

interface AvatarBotProps extends Omit<AvatarProps, 'src'> {
  bot: Bot;
  size?: number;
}

export default function AvatarBot({
  bot,
  size = 56,
  ...props
}: AvatarBotProps) {
  const avatarSvg = useMemo(() => {
    const avatar = createAvatar(botttsNeutral, {
      seed: bot?.id,
      size: size,
      backgroundColor: [bot?.backgroundColor],
      eyes: [bot?.eyes as any],
      mouth: [bot?.mouth as any],
    });
    return avatar.toDataUri();
  }, [bot, size]);

  return (
    <Avatar
      src={avatarSvg}
      alt={`Avatar for bot ${bot?.id}`}
      sx={{ width: size, height: size }}
      {...props}
    />
  );
}
