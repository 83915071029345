import { Box, Stack, Typography, TypographyProps } from '@mui/material';

interface ModelSectionProps {
  title: string;
  titleVariant?: TypographyProps['variant'];
  subtitle?: string;
  children?: React.ReactNode;
  actionButton?: React.ReactNode;
}

export function ModelSettingsSection({
  title,
  subtitle,
  children,
  titleVariant = 'h4',
  actionButton,
}: ModelSectionProps) {
  return (
    <Box
      component="section"
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <Stack
        direction="column"
        spacing={1}
      >
        <Stack
          direction="row"
          spacing={1.5}
          alignItems="center"
        >
          <Typography variant={titleVariant}>{title}</Typography>
          {actionButton}
        </Stack>
        {subtitle && <Typography variant="caption">{subtitle}</Typography>}
      </Stack>
      <Box my={2}>{children}</Box>
    </Box>
  );
}
