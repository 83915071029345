import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetVectorStatsQuery } from 'graphql/generated';
import { Box, Stack, Typography, Divider } from '@mui/material';
import { Loading } from 'components/atoms';
import { CollectionStats } from 'types/api';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export default function MemoryNewStepReviewVector() {
  const { memoryID } = useParams<{ memoryID: string }>();

  const { data: vectorStatsData, isLoading } = useGetVectorStatsQuery({
    input: { memoryID },
  });

  const vectorStats = useMemo(() => {
    if (!vectorStatsData) return null;
    return JSON.parse(vectorStatsData.getVectorStats.stats) as CollectionStats;
  }, [vectorStatsData]);

  if (isLoading) {
    return <Loading />;
  }
  console.log('vectorStats:', vectorStats);
  return (
    <Box sx={{ p: 2 }}>
      <Stack spacing={3}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Typography variant="h5">Status</Typography>
          <FiberManualRecordIcon
            sx={{
              color:
                vectorStats?.status === 'green' ? 'success.main' : 'error.main',
            }}
          />
          <Typography>{vectorStats?.optimizer_status}</Typography>
        </Stack>

        <Divider sx={{ opacity: 0.3 }} />

        <Box>
          <Typography
            variant="h6"
            gutterBottom
            textTransform="uppercase"
            sx={{ textTransform: 'uppercase', textDecoration: 'underline' }}
          >
            General Statistics
          </Typography>
          <Stack spacing={1}>
            <Typography>Points Count: {vectorStats?.pointsCount}</Typography>
            <Typography>
              Segments Count: {vectorStats?.segmentsCount}
            </Typography>
            <Typography>Indexed Count: {vectorStats?.indexedCount}</Typography>
          </Stack>
        </Box>

        <Divider sx={{ opacity: 0.3 }} />

        <Box>
          <Typography
            variant="h6"
            gutterBottom
            textTransform="uppercase"
            sx={{ textTransform: 'uppercase', textDecoration: 'underline' }}
          >
            HNSW Configuration
          </Typography>
          <Stack spacing={1}>
            <Typography>
              EF Construct: {vectorStats?.hnswConfig.efConstruct}
            </Typography>
            <Typography>
              Full Scan Threshold: {vectorStats?.hnswConfig.fullScanThreshold}
            </Typography>
            <Typography>M: {vectorStats?.hnswConfig.m}</Typography>
            <Typography>
              Max Indexing Threads: {vectorStats?.hnswConfig.maxIndexingThreads}
            </Typography>
          </Stack>
        </Box>

        <Divider sx={{ opacity: 0.3 }} />

        <Box>
          <Typography
            variant="h6"
            gutterBottom
            textTransform="uppercase"
            sx={{ textTransform: 'uppercase', textDecoration: 'underline' }}
          >
            Vector Configuration
          </Typography>
          <Stack spacing={1}>
            <Typography>
              Distance: {vectorStats?.vectorConfig.distance}
            </Typography>
            <Typography>Size: {vectorStats?.vectorConfig.size}</Typography>
            <Typography>
              Shard Number: {vectorStats?.vectorConfig.shardNumber}
            </Typography>
            <Typography>
              Replication Factor: {vectorStats?.vectorConfig.replicationFactor}
            </Typography>
          </Stack>
        </Box>

        <Divider sx={{ opacity: 0.3 }} />

        <Box>
          <Typography
            variant="h6"
            gutterBottom
            textTransform="uppercase"
            sx={{ textTransform: 'uppercase', textDecoration: 'underline' }}
          >
            Optimizer Configuration
          </Typography>
          <Stack spacing={1}>
            <Typography>
              Default Segment Number:{' '}
              {vectorStats?.optimizerConfig.defaultSegmentNumber}
            </Typography>
            <Typography>
              Deleted Threshold: {vectorStats?.optimizerConfig.deletedThreshold}
            </Typography>
            <Typography>
              Flush Interval (sec):{' '}
              {vectorStats?.optimizerConfig.flushIntervalSec}
            </Typography>
            <Typography>
              Indexing Threshold:{' '}
              {vectorStats?.optimizerConfig.indexingThreshold}
            </Typography>
            <Typography>
              Vacuum Min Vector Number:{' '}
              {vectorStats?.optimizerConfig.vacuumMinVectorNumber}
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}
