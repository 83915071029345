import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import BotConfig from './config';
import BotActivity from './activity';

export default function BotHome() {
  return (
    <Box mt={2}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <Stack
            spacing={2}
            sx={{ p: 2 }}
          >
            <Typography variant="h4">Activity</Typography>
          </Stack>
          <Divider sx={{ opacity: 0.25 }} />
          <BotActivity />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <BotConfig />
        </Grid>
      </Grid>
    </Box>
  );
}
