import { LoadingButton } from '@mui/lab';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { ButtonIconTooltip } from 'components/atoms';
import { useGetVoicesQuery, useUpdateVoiceMutation } from 'graphql/generated';
import mixpanel from 'mixpanel-browser';
import { v4 as uuid } from 'uuid';
import { AIProvider, VoiceStep } from 'types/enums';
import { useNavigate } from 'react-router-dom';

interface NewVoiceProps {
  title: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function NewVoice({ title, open, setOpen }: NewVoiceProps) {
  const [saving, setSaving] = useState(false);
  const [voiceName, setVoiceName] = useState<string>('');
  const [voiceDescription, setVoiceDescription] = useState<string>('');
  const navigate = useNavigate();
  const { refetch: refetchVoices } = useGetVoicesQuery({
    input: {},
  });
  const [updateVoice] = useUpdateVoiceMutation();

  const handleToggleOpen = () => {
    setOpen(!open);
  };

  const createVoice = useCallback(async () => {
    try {
      setSaving(true);
      const voiceID = uuid();
      const input = {
        id: voiceID,
        step: VoiceStep.select,
        name: voiceName,
        provider: AIProvider.CARTESIA,
      };
      await updateVoice({ input }).unwrap();
      mixpanel.track('create_voice');
      await refetchVoices();
      navigate(`/studio/voice/${voiceID}`);
      return voiceID;
    } catch (error) {
      console.error('Error creating voice:', error);
      throw error;
    } finally {
      setSaving(false);
    }
  }, [voiceName, updateVoice, refetchVoices, navigate]);

  const handleClick = () => {
    createVoice();
  };

  const disable = () => {
    if (!voiceName || !voiceDescription) {
      return true;
    }
    return false;
  };

  return (
    <Box>
      <Dialog
        maxWidth="xs"
        fullWidth
        onClose={handleToggleOpen}
        open={open}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ px: 2, py: 1 }}
        >
          <Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h4"
            component="div"
          >
            {title || 'New Voice'}
          </Typography>

          <ButtonIconTooltip
            onClick={() => setOpen(false)}
            isLoading={false}
            tooltipTitle="Close"
            icon="close"
            tooltipColorVariant="info"
          />
        </Stack>
        <Divider />
        <DialogContent>
          <Stack
            sx={{ pt: 2 }}
            spacing={2}
          >
            <TextField
              size="small"
              fullWidth
              label="Name"
              variant="outlined"
              placeholder="What is this voice's name?"
              value={voiceName}
              onChange={(e) => {
                setVoiceName(e.target.value);
              }}
            />
            <TextField
              size="small"
              fullWidth
              label="Description"
              variant="outlined"
              placeholder="What makes this voice unique?"
              value={voiceDescription}
              onChange={(e) => {
                setVoiceDescription(e.target.value);
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <LoadingButton
            disabled={disable()}
            loading={saving}
            size="small"
            onClick={handleClick}
            variant="contained"
            sx={{ fontWeight: 'bold', height: 28 }}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
