import { getCurrentUser } from 'aws-amplify/auth';
import { DateTime } from 'luxon';
import { ColorVariant } from 'types/app';
import {
  AIProvider,
  FileCategory,
  FileSource,
  FileStage,
  FileType,
  MemoryStep,
  VoiceStep,
  Status,
} from 'types/enums';

export const formatDate = (
  dateString?: string,
  format?: string,
  includeDay?: boolean,
) => {
  const defaultFormat = includeDay
    ? 'ccc, dd-MMM-yy - HH:mm:ss'
    : 'dd-MMM-yy - HH:mm:ss';
  return DateTime.fromISO(dateString!).toFormat(format || defaultFormat);
};

export const formatCallDuration = (duration: number): string => {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;

  if (hours === 0) {
    return `${minutes} min${minutes !== 1 ? 's' : ''}`;
  }

  if (minutes === 0) {
    return `${hours} hour${hours !== 1 ? 's' : ''}`;
  }

  return `${hours} hour${hours !== 1 ? 's' : ''} ${minutes} min${minutes !== 1 ? 's' : ''}`;
};

export function truncateText(text: string, maxWords: number): string {
  const words = text?.split(' ');
  if (words?.length > maxWords) {
    return `${words?.slice(0, maxWords).join(' ')} ...`;
  }
  return text;
}

export const formatFileSize = (size: number) => {
  if (size >= 1073741824) {
    return `${(size / 1073741824).toFixed(2)} GB`;
  }
  if (size >= 1048576) {
    return `${(size / 1048576).toFixed(2)} MB`;
  }
  return `${(size / 1024).toFixed(2)} KB`;
};

export const roundToTwoDecimals = (num: number): number => {
  return parseFloat(num.toFixed(2));
};

export const enumToObject = (
  enumObj: Record<string, string>,
): Record<string, string> => {
  return Object.keys(enumObj)
    .filter((key) => typeof enumObj[key] === 'string')
    .reduce(
      (acc, key) => {
        acc[key] = enumObj[key];
        return acc;
      },
      {} as Record<string, string>,
    );
};

export const menuOptions = (options: string[]) =>
  options.map((value) => ({
    value,
    label: value,
  }));

export const parseAwsDateTime = (
  awsDateTime: string | null,
): DateTime | null => {
  return awsDateTime ? DateTime.fromISO(awsDateTime, { zone: 'utc' }) : null;
};

export const getFileStepIndex = (stage?: FileStage): number => {
  switch (stage?.toLowerCase()) {
    case FileStage.upload:
      return 0;
    case FileStage.process:
      return 1;
    case FileStage.review:
      return 2;
    case FileStage.submit:
      return 3;
    case FileStage.complete:
      return 4;
    default:
      return 0;
  }
};

export const getVoiceStepindex = (step?: VoiceStep): number => {
  switch (step?.toLowerCase()) {
    case VoiceStep.select:
      return 0;
    case VoiceStep.clean:
      return 1;
    case VoiceStep.train:
      return 2;
    case VoiceStep.review:
      return 3;
    case VoiceStep.complete:
      return 4;
    default:
      return 0;
  }
};

export const getMemoryStepindex = (step?: MemoryStep): number => {
  switch (step?.toLowerCase()) {
    case MemoryStep.select:
      return 0;
    case MemoryStep.ontology:
      return 1;
    case MemoryStep.ingest:
      return 2;
    case MemoryStep.review:
      return 3;
    case MemoryStep.complete:
      return 4;
    default:
      return 0;
  }
};

export const getMemoryStepFromIndex = (index: number): MemoryStep => {
  switch (index) {
    case 0:
      return MemoryStep.select;
    case 1:
      return MemoryStep.ontology;
    case 2:
      return MemoryStep.ingest;
    case 3:
      return MemoryStep.review;
    case 4:
      return MemoryStep.complete;
    default:
      return MemoryStep.select;
  }
};

export const getVoiceStepFromIndex = (index: number): VoiceStep => {
  switch (index) {
    case 0:
      return VoiceStep.select;
    case 1:
      return VoiceStep.clean;
    case 2:
      return VoiceStep.train;
    case 3:
      return VoiceStep.clean;
    case 4:
      return VoiceStep.complete;
    default:
      return VoiceStep.select;
  }
};

export function getProviderName(provider: AIProvider): string {
  switch (provider) {
    case AIProvider.PLAYHT:
      return 'PlayHT';
    case AIProvider.CARTESIA:
      return 'Cartesia';
    case AIProvider.OPENAI:
      return 'OpenAI';
    case AIProvider.DEEPGRAM:
      return 'Deepgram';
    case AIProvider.ELEVENLABS:
      return 'ElevenLabs';
    case AIProvider.UNKNOWN:
      return 'Unknown';
    default:
      return 'Unknown';
  }
}

export function secondsToTimeFormat(seconds: number): string {
  if (seconds < 0) {
    throw new Error('Input must be a non-negative number');
  }

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${minutes}:${formattedSeconds}`;
}

export function getFileType(blobType: string): string {
  const mimeToExtension: { [key: string]: string } = {
    'audio/wav': 'wav',
    'audio/mpeg': 'mp3',
    'audio/flac': 'flac',
    'audio/webm': 'webm',
    'audio/ogg': 'ogg',
    'audio/aac': 'aac',
    'audio/mp4': 'm4a',
    'video/quicktime': 'mov',
    'video/mp4': 'mp4',
    'video/x-matroska': 'mkv',
    'video/x-msvideo': 'avi',
    'video/x-ms-wmv': 'wmv',
    'application/pdf': 'pdf',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      'docx',
    'text/plain': 'txt',
    'application/rtf': 'rtf',
    'text/markdown': 'md',
  };

  return mimeToExtension[blobType] || 'unknown';
}

export function getFileCategory(blobType: string): FileType {
  const audioTypes = [
    'audio/wav',
    'audio/mpeg',
    'audio/flac',
    'audio/webm',
    'audio/ogg',
    'audio/aac',
    'audio/mp4',
  ];
  const videoTypes = [
    'video/quicktime',
    'video/mp4',
    'video/x-matroska',
    'video/x-msvideo',
    'video/x-ms-wmv',
  ];
  const documentTypes = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain',
    'application/rtf',
    'text/markdown',
  ];

  if (audioTypes.includes(blobType)) {
    return FileType.audio_upload;
  } else if (videoTypes.includes(blobType)) {
    return FileType.video_upload;
  } else if (documentTypes.includes(blobType)) {
    return FileType.document;
  } else {
    return FileType.audio_upload; // Default to audio_upload instead of unknown
  }
}

export const formatFileSource = (source: FileSource): string => {
  switch (source) {
    case FileSource.content:
      return 'Content';
    case FileSource.record:
      return 'Recording';
    case FileSource.upload:
      return 'Upload';
    default:
      return 'Unknown';
  }
};

export const getAcceptedFiles = (fileType: FileCategory) => {
  switch (fileType) {
    case FileCategory.audio:
      return {
        'audio/wav': [],
        'audio/mp3': [],
        'audio/ogg': [],
        'audio/aac': [],
        'audio/mpeg': [],
        'audio/webm': [],
        'audio/m4a': [],
        'audio/x-m4a': [],
        'audio/mpeg3': [],
        'audio/x-mpeg-3': [],
        'audio/x-mpeg': [],
        'audio/x-mpeg-2': [],
        'audio/x-mpeg-1': [],
      };
    case FileCategory.video:
      return { 'video/*': [] };
    case FileCategory.document:
      return {
        'application/pdf': [],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          [],
        'text/plain': [],
        'text/markdown': [],
      };
    case FileCategory.all:
    default:
      return {
        'audio/wav': [],
        'audio/mp3': [],
        'audio/ogg': [],
        'audio/aac': [],
        'audio/mpeg': [],
        'audio/webm': [],
        'audio/m4a': [],
        'audio/x-m4a': [],
        'audio/mpeg3': [],
        'audio/x-mpeg-3': [],
        'audio/x-mpeg': [],
        'audio/x-mpeg-2': [],
        'audio/x-mpeg-1': [],
        'video/*': [],
        'application/pdf': [],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          [],
        'text/plain': [],
        'text/markdown': [],
      };
  }
};

export const validateUrl = (input: string) => {
  try {
    new URL(input);
    return true;
  } catch (_) {
    return false;
  }
};

export const getColorVariantFromStatus = (status: Status): ColorVariant => {
  switch (status) {
    case Status.success:
      return 'success';
    case Status.failed:
      return 'error';
    case Status.processing:
      return 'info';
    case Status.pending:
      return 'warning';
    default:
      return 'primary';
  }
};

export async function checkAuthState() {
  try {
    await getCurrentUser();
    return true;
  } catch {
    return false;
  }
}
