import React from 'react';
import { Stack, Tabs, Tab } from '@mui/material';
import { FileCategory } from 'types/enums';

interface FileFilterAndPaginationProps {
  status: FileCategory | 'all';
  handleStatusChange: (
    event: React.MouseEvent<HTMLElement>,
    fileCategory: FileCategory | null,
  ) => void;
  totalCount: number;
  nextToken: string | null;
  handlePageChange: (
    token: string | null,
  ) => Promise<{ items: unknown[]; nextToken: string }>;
}

const FileFilterAndPagination: React.FC<FileFilterAndPaginationProps> = ({
  status,
  handleStatusChange,
  totalCount,
  nextToken,
  handlePageChange,
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
    >
      <Tabs
        value={status}
        onChange={handleStatusChange}
        textColor="primary"
        indicatorColor="primary"
        sx={{ minHeight: 28 }}
      >
        <Tab
          label="All"
          value="all"
          sx={{
            minHeight: 28,
            px: 1.5,
            textTransform: 'none',
          }}
        />
        <Tab
          label="Video"
          value={FileCategory.video}
          sx={{
            minHeight: 28,
            px: 1.5,
            textTransform: 'none',
          }}
        />
        <Tab
          label="Audio"
          value={FileCategory.audio}
          sx={{
            minHeight: 28,
            px: 1.5,
            textTransform: 'none',
          }}
        />
        <Tab
          label="Webpages"
          value={FileCategory.web}
          sx={{
            minHeight: 28,
            px: 1.5,
            textTransform: 'none',
          }}
        />
        <Tab
          label="Documents"
          value={FileCategory.document}
          sx={{
            minHeight: 28,
            px: 1.5,
            textTransform: 'none',
          }}
        />
      </Tabs>
    </Stack>
  );
};

export default FileFilterAndPagination;
