import { Grid } from '@mui/material';
import { UsageAccount } from 'components/molecules';

export default function OverviewUsage() {
  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
      >
        <UsageAccount disableTopDivider />
      </Grid>
    </Grid>
  );
}
