import { ResourcesConfig } from 'aws-amplify';

export const amplifyConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      identityPoolId: String(process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID),
      userPoolId: String(process.env.REACT_APP_COGNITO_USER_POOL_ID),
      userPoolClientId: String(process.env.REACT_APP_COGNITO_APP_CLIENT_ID),
      allowGuestAccess: true, // required for unauthenticated access to api
      loginWith: {
        oauth: {
          domain: String(process.env.REACT_APP_COGNITO_DOMAIN),
          scopes: ['email', 'openid', 'profile'], // required for google auth
          redirectSignIn: [
            String(process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN),
          ],
          redirectSignOut: [
            String(process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT),
          ],
          responseType: 'code',
          providers: ['Google'], // required for google auth
        },
        email: true,
        phone: false,
        username: false,
      },
    },
  },
  API: {
    GraphQL: {
      endpoint: String(process.env.REACT_APP_APPSYNC_API_ENDPOINT),
      region: 'ap-southeast-2',
      defaultAuthMode: 'lambda', // appsync also uses iam auth for non auth users
    },
  },
};
