import { useAudioAPI } from './api';
import { AudioContextProvider, useAudioState } from './state';

export const useAudio = () => {
  const state = useAudioState();
  const api = useAudioAPI();

  return {
    ...state,
    ...api,
  };
};

export { AudioContextProvider };
