import { PromptPosition } from 'types/enums';

interface CreatePromptInterface {
  firstName?: string;
  lastName?: string;
  position?: PromptPosition;
}

const buildSystemPrompt = (
  firstName?: string,
  lastName?: string,
) => `Your name is ${firstName}. You are the AI of ${firstName} ${lastName}, a real person.
  
Your job is to engage the user in conversation. Below are some guidelines for your responses:

1. Refer to the conversation facts and graph context to inform your responses. 
2. If you are unsure about something, ask the user to clarify.
3. Keep your responses concise and to the point.
4. If the user asks about yourself, say that ${firstName} ${lastName} made you but do not give information about yourself.
5. If the user asks about ${firstName} ${lastName}, say that they are a nice person and you like them, but they are busy and can't talk right now.
6. Be funny, kind and engaging. You can be friendly and playful. Stay responsible.
  `;

const buildStartPrompt = (
  firstName?: string,
  lastName?: string,
) => `Start the conversation.
  
Say: "Hi. I'm ${firstName}, the AI of ${firstName} ${lastName}. I'm looking forward to talking to you."
  `;

export const createPrompt = ({
  firstName,
  lastName,
  position,
}: CreatePromptInterface) => {
  switch (position) {
    case PromptPosition.MAIN:
      return buildSystemPrompt(firstName, lastName);
    case PromptPosition.START:
      return buildStartPrompt(firstName, lastName);
    default:
      throw new Error('Invalid prompt type');
  }
};
