import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetGraphQuery, useGetMemoryQuery } from 'graphql/generated';
import { ForceDirectedGraph3D } from 'components/organisms/force-directed-graph';
import {
  AppBar,
  Box,
  CssBaseline,
  Dialog,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  Paper,
} from '@mui/material';
import { Close, Fullscreen } from '@mui/icons-material';
import { Loading } from 'components/atoms';

export default function MemoryNewStepReviewGraph() {
  const { memoryID } = useParams<{ memoryID: string }>();
  const { data: graphData, isLoading: isGraphLoading } = useGetGraphQuery({
    input: { memoryID },
  });
  const { data: memoryData } = useGetMemoryQuery({
    input: { id: memoryID },
  });
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const memory = useMemo(() => {
    if (!memoryData) return null;
    return memoryData.getMemory.data;
  }, [memoryData]);

  const formattedGraphData = useMemo(() => {
    if (!graphData?.getGraph?.data) return null;

    const { nodes, links } = graphData.getGraph.data;
    const nodeIds = new Set(nodes.map((node) => node.id));
    console.log('nodes:', nodes);

    return {
      nodes: nodes.map((node) => ({
        id: node.id,
        name: node.name,
        description: node.description,
        color: node.color,
      })),
      links: links
        .filter((link) => nodeIds.has(link.source) && nodeIds.has(link.target))
        .map((link) => ({
          source: link.source,
          target: link.target,
        })),
    };
  }, [graphData]);

  useEffect(() => {
    if (formattedGraphData) {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [formattedGraphData]);

  if (isLoading || isGraphLoading) {
    return <Loading />;
  }

  return (
    <Box sx={{ p: 2 }}>
      <Paper
        elevation={3}
        sx={{
          borderRadius: '16px',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            pl: 2,
            pr: 1,
            py: 0.5,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        >
          <Typography variant="h5">Graph</Typography>
          <IconButton onClick={() => setOpen(true)}>
            <Fullscreen />
          </IconButton>
        </Stack>
        {formattedGraphData && !open && (
          <Box
            sx={{
              width: '100%',
              height: '500px',
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'black',
            }}
          >
            <ForceDirectedGraph3D
              data={formattedGraphData}
              height={500}
            />
          </Box>
        )}
      </Paper>
      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
      >
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{ background: 'none', boxShadow: 'none' }}
        >
          <Toolbar
            sx={{
              minHeight: '48px!important',
              backdropFilter: 'blur(10px)',
              WebkitBackdropFilter: 'blur(10px)',
              backgroundColor: 'black',
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Typography variant="h4">{memory.name}</Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setOpen(false)}
                aria-label="close"
              >
                <Close />
              </IconButton>
            </Stack>
          </Toolbar>
        </AppBar>
        <Box sx={{ paddingTop: '48px' }}>
          {formattedGraphData && open && (
            <ForceDirectedGraph3D
              data={formattedGraphData || { nodes: [], links: [] }}
              viewport
            />
          )}
        </Box>
      </Dialog>
    </Box>
  );
}
