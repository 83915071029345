import { FormControl, Box, Typography, TextField, Grid } from '@mui/material';
import {
  InfoLabel,
  Select,
  Section,
  Slider,
  ModelSettingsSection,
} from 'components/atoms';
import { AIModels, AIProvider, AIType, MODEL_PROVIDERS } from 'types/enums';
import { Model, ModelOptions } from '@duohub/types';
import { menuOptions } from 'common/utils';
import { useSnackbar } from 'use/snackbar';
import { useEffect, useMemo, useState } from 'react';
import { useGetModelQuery, useUpdateModelMutation } from 'graphql/generated';
import { useParams } from 'react-router-dom';

export default function ModelLLM() {
  const { botID } = useParams();

  const { data: modelData, refetch: refetchModel } = useGetModelQuery({
    input: {
      botID,
    },
  });

  const models = useMemo(() => modelData?.getModel.data || [], [modelData]);

  const llmModelIndex = models?.findIndex((m) => m.type === AIType.LLM);
  const [llmModel, setLlmModel] = useState(
    llmModelIndex !== -1 ? models[llmModelIndex] : undefined,
  );
  const availableProviders = Object.keys(
    MODEL_PROVIDERS[AIType.LLM] || {},
  ) as AIProvider[];
  const [selectedProvider, setSelectedProvider] = useState(
    (llmModel?.provider ||
      availableProviders[0]) as keyof (typeof MODEL_PROVIDERS)[typeof AIType.LLM],
  );
  const availableModels = MODEL_PROVIDERS[AIType.LLM]?.[selectedProvider] || [];
  const [selectedModel, setSelectedModel] = useState<AIModels>(
    AIModels.GPT_4_O,
  );

  useEffect(() => {
    if (llmModel?.model) {
      setSelectedModel(llmModel.model as AIModels);
    }
  }, [llmModel]);

  const { showSnackbar } = useSnackbar();

  const [changed, setChanged] = useState(false);
  const [updateModelMutation] = useUpdateModelMutation();

  const parseLLMModelOptions = (): Model | undefined => {
    if (llmModel?.options && typeof llmModel.options === 'string') {
      try {
        const parsedOptions = llmModel.options as ModelOptions;
        return {
          ...llmModel,
          options: parsedOptions,
        };
      } catch (error) {
        showSnackbar('Failed to parse model options', 'error');
      }
    }
    return llmModel;
  };

  const parsedLLMModel = parseLLMModelOptions();

  const changeStatus = (value: boolean): void => {
    if (!changed) {
      setChanged(value);
    }
  };

  const updateModel = async (updatedModel: Partial<Model>): Promise<void> => {
    if (parsedLLMModel) {
      const newModel: Model = {
        ...parsedLLMModel,
        ...updatedModel,
        options: {
          ...parsedLLMModel.options,
          ...(updatedModel.options || {}),
        },
      };
      const result = await updateModelMutation({ input: { ...newModel } });
      if (result.data?.updateModel?.data) {
        setLlmModel(result.data.updateModel.data);
      }
      changeStatus(true);
      await refetchModel();
    }
  };

  const handleTemperatureChange = async (value: number): Promise<void> =>
    await updateModel({ options: { temperature: value } });

  const handleMaxTokensChange = async (value: number): Promise<void> =>
    await updateModel({ options: { maxTokens: value } });

  const handleProviderChange = async (value: string): Promise<void> => {
    const provider = value as keyof (typeof MODEL_PROVIDERS)[typeof AIType.LLM];
    await updateModel({ provider });
    setSelectedProvider(provider);
    const updatedAvailableModels =
      MODEL_PROVIDERS[AIType.LLM]?.[provider] || [];
    const updatedSelectedModel = updatedAvailableModels[0] as AIModels;
    await updateModel({ model: updatedSelectedModel });
    setSelectedModel(updatedSelectedModel);
  };

  const handleModelChange = async (value: string): Promise<void> => {
    const newModel = value as AIModels;
    await updateModel({ model: newModel });
    setSelectedModel(newModel);
  };

  const getOptionValue = (
    key: keyof ModelOptions,
    defaultValue: number,
  ): number => {
    if (parsedLLMModel?.options && typeof parsedLLMModel.options === 'object') {
      const value = parsedLLMModel.options[key];
      return typeof value === 'number' ? value : defaultValue;
    }
    return defaultValue;
  };

  const temperature = getOptionValue('temperature', 0.2);
  const maxTokens = getOptionValue('maxTokens', 512);

  return (
    <ModelSettingsSection
      title="LLM Configuration"
      subtitle="Choose your LLM configuration."
    >
      <FormControl fullWidth>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Section label="Provider">
              <Select
                value={selectedProvider}
                defaultValue={AIProvider.OPENAI}
                options={menuOptions(availableProviders)}
                onChange={handleProviderChange}
              />
            </Section>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Section label="Model">
              <Select
                value={selectedModel}
                defaultValue={AIModels.GPT_4_O}
                options={menuOptions(availableModels)}
                onChange={handleModelChange}
              />
            </Section>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <Section label="Max Tokens">
              <TextField
                value={maxTokens}
                onChange={(e) => handleMaxTokensChange(Number(e.target.value))}
                type="number"
                size="small"
                placeholder="Numerical value, e.g. 250"
                fullWidth
              />
            </Section>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <Section>
              <InfoLabel
                label="Temperature"
                tooltip={`The temperature is used to control the randomness of the output. 
                  When you set it higher, you'll get more random outputs. 
                  When you set it lower, towards 0, the values are more deterministic."`}
              />
              <Box>
                <Slider
                  value={temperature}
                  setValue={handleTemperatureChange}
                  columns={1}
                  max={1}
                  step={0.1}
                  sx={{ marginTop: 2 }}
                  defaultValue={0.2}
                  slots={{ valueLabel: Typography }}
                />
              </Box>
            </Section>
          </Grid>
        </Grid>
      </FormControl>
    </ModelSettingsSection>
  );
}
