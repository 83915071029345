import { LocalLibrary, RecordVoiceOver } from '@mui/icons-material';
import {
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
  Paper,
  TextField,
  Skeleton,
} from '@mui/material';
import { ButtonIconTooltip } from 'components/atoms';
import { useParams } from 'react-router-dom';
import { useGetVoiceQuery, useUpdateVoiceMutation } from 'graphql/generated';
import { useEffect, useState } from 'react';

export default function StartTitle({
  browseLibrary,
  anchorEl,
  setAnchorEl,
  handleMenuClose,
  handleTrainMyVoice,
  handleBrowseLibrary,
}: {
  newVoice: boolean;
  browseLibrary: boolean;
  anchorEl: HTMLElement | null;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
  handleMenuClose: () => void;
  handleTrainMyVoice: () => void;
  handleBrowseLibrary: () => void;
}) {
  const { voiceID } = useParams<{ voiceID: string }>();
  const [updateVoice] = useUpdateVoiceMutation();
  const { data: voiceData, isLoading } = useGetVoiceQuery({
    input: {
      id: voiceID,
    },
  });
  const voice = voiceData?.getVoice?.data;

  const [isEditing, setIsEditing] = useState(false);
  const [voiceName, setVoiceName] = useState(voice?.name || 'New Voice');

  useEffect(() => {
    setVoiceName(voice?.name || 'New Voice');
  }, [voice]);

  const handleTextClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVoiceName(event.target.value);
  };

  const handleSave = async () => {
    setIsEditing(false);
    if (voice) {
      await updateVoice({
        input: { id: voiceID, name: voiceName },
      });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
      >
        {isLoading ? (
          <Skeleton
            variant="text"
            width={100}
            height={40}
          />
        ) : isEditing ? (
          <Paper
            elevation={3}
            style={{ display: 'inline-block' }}
          >
            <TextField
              placeholder="Voice name"
              value={voiceName}
              onChange={handleInputChange}
              onBlur={handleSave}
              onKeyDown={handleKeyDown}
              autoFocus
              sx={{
                '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
              }}
              size="small"
              inputProps={{
                style: {
                  fontSize: 'inherit',
                  padding: '6px 10px',
                  border: 'none',
                },
                maxLength: 70,
              }}
            />
          </Paper>
        ) : (
          <Typography
            variant="h4"
            onClick={handleTextClick}
            style={{ cursor: 'pointer' }}
          >
            {voiceName}
          </Typography>
        )}
        {browseLibrary && (
          <>
            <ButtonIconTooltip
              isLoading={false}
              onClick={() => {}}
              setAnchorEl={setAnchorEl}
              tooltipTitle="Add Voice Options"
              icon="add"
              tooltipColorVariant="info"
            />
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleTrainMyVoice}>
                <ListItemIcon>
                  <RecordVoiceOver fontSize="small" />
                </ListItemIcon>
                Train My Voice
              </MenuItem>
              <MenuItem onClick={handleBrowseLibrary}>
                <ListItemIcon>
                  <LocalLibrary fontSize="small" />
                </ListItemIcon>
                Browse Voice Library
              </MenuItem>
            </Menu>
          </>
        )}
      </Stack>
    </Stack>
  );
}
