import React from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Typography,
  Button,
} from '@mui/material';
import { Cancel, CheckCircle, Close, Source } from '@mui/icons-material';

interface DialogContentAddSpeakerSelectProps {
  open: boolean;
  onClose: () => void;
  selectedFile: File | null;
  onSave: (numSpeakers: number) => void;
  onCancel: () => void;
  onCloseAndCancelAll: () => void; // Add this new prop
  numSpeakers: number;
  setNumSpeakers: React.Dispatch<React.SetStateAction<number>>;
}

export default function DialogContentAddSpeakerSelect({
  open,
  onClose,
  selectedFile,
  onSave,
  onCancel,
  onCloseAndCancelAll, // Add this new prop
  numSpeakers,
  setNumSpeakers,
}: DialogContentAddSpeakerSelectProps) {
  const handleSave = () => {
    onSave(numSpeakers);
    onClose();
  };

  const handleCloseAndCancelAll = () => {
    onCloseAndCancelAll();
    onClose();
  };

  const renderSpeakerButton = (number: number) => (
    <IconButton
      key={number}
      onClick={() => setNumSpeakers(number)}
      sx={{
        color: numSpeakers === number ? 'black' : 'white',
        fontWeight: 'bold',
        backgroundColor: numSpeakers === number ? 'primary.main' : 'inherit',
        '&:hover': {
          backgroundColor: numSpeakers === number ? 'primary.dark' : 'inherit',
        },
      }}
    >
      <Avatar
        sx={{
          color: numSpeakers === number ? 'inherit' : 'white',
          backgroundColor: numSpeakers === number ? 'primary.main' : 'inherit',
        }}
      >
        {number}
      </Avatar>
    </IconButton>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <Stack
        direction="row"
        sx={{ p: 2, justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Typography variant="h4">Select number of speakers</Typography>
        <IconButton onClick={handleCloseAndCancelAll}>
          <Close />
        </IconButton>
      </Stack>
      <Divider sx={{ opacity: 0.5 }} />
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ mx: 6, my: 2 }}
      >
        <Source />
        <Typography
          variant="body1"
          noWrap
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
          }}
        >
          {selectedFile ? selectedFile.name : 'No file selected'}
        </Typography>
      </Stack>
      <Divider sx={{ opacity: 0.5 }} />
      <DialogContent>
        <Stack
          direction="column"
          sx={{ p: 2 }}
        >
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            sx={{ mb: 1 }}
          >
            {[1, 2, 3, 4, 5].map(renderSpeakerButton)}
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
          >
            {[6, 7, 8, 9, 10].map(renderSpeakerButton)}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button
          startIcon={<Cancel />}
          onClick={onCancel}
        >
          Remove
        </Button>
        <LoadingButton
          startIcon={<CheckCircle />}
          size="small"
          variant="contained"
          sx={{ height: 28, fontWeight: 'bold' }}
          onClick={handleSave}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
