import { Amplify } from 'aws-amplify';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { FC } from 'react';
import { amplifyConfig } from 'amplify-config';
import { darkTheme } from './theme';
import { router } from './router';
import ContextProvider from './use';
import '@aws-amplify/ui-react/styles.css';
import { store } from './store';

Amplify.configure(amplifyConfig);

const App: FC = function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <ReduxProvider store={store}>
        <ContextProvider>
          <RouterProvider router={router} />
        </ContextProvider>
      </ReduxProvider>
    </ThemeProvider>
  );
};

export default App;
