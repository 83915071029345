import { FormControl, Box, Typography, Stack, Grid } from '@mui/material';
import { PromptPosition } from 'types/enums';
import { useState, useEffect } from 'react';
import InfoTextField from 'components/atoms/info-text-field';
import { useGetPromptQuery, useUpdatePromptMutation } from 'graphql/generated';
import { useParams } from 'react-router-dom';

export function ModelHome() {
  const { botID } = useParams();

  const mainPromptQuery = useGetPromptQuery(
    {
      input: {
        botID,
        position: PromptPosition.MAIN,
      },
    },
    { skip: !botID },
  );

  const startPromptQuery = useGetPromptQuery(
    {
      input: {
        botID,
        position: PromptPosition.START,
      },
    },
    { skip: !botID },
  );

  const [changed, setChanged] = useState(false);
  const [updatePromptMutation] = useUpdatePromptMutation();

  const [startPromptContent, setStartPromptContent] = useState('');
  const [mainPromptContent, setMainPromptContent] = useState('');

  useEffect(() => {
    if (startPromptQuery.data?.getPrompt.data?.[0]?.content) {
      setStartPromptContent(startPromptQuery.data.getPrompt.data[0].content);
    }
  }, [startPromptQuery.data]);

  useEffect(() => {
    if (mainPromptQuery.data?.getPrompt.data?.[0]?.content) {
      setMainPromptContent(mainPromptQuery.data.getPrompt.data[0].content);
    }
  }, [mainPromptQuery.data]);

  const changeStatus = (value: boolean): void => {
    if (!changed) {
      setChanged(value);
    }
  };

  const updateStartPrompt = async ({
    position,
    content,
  }: {
    position: PromptPosition;
    content?: string;
  }): Promise<void> => {
    if (!botID) return;

    try {
      await updatePromptMutation({
        input: {
          id: startPromptQuery.data?.getPrompt.data?.[0].id,
          botID,
          content: content,
          position: position,
        },
      }).unwrap();
    } catch (error) {
      console.error('Failed to update start prompt:', error);
    }
    changeStatus(true);
  };

  const updateMainPrompt = async ({
    position,
    content,
  }: {
    position: PromptPosition;
    content?: string;
  }): Promise<void> => {
    if (!botID) return;

    try {
      await updatePromptMutation({
        input: {
          id: mainPromptQuery.data?.getPrompt.data?.[0].id,
          botID,
          content: content,
          position: position,
        },
      }).unwrap();
    } catch (error) {
      console.error('Failed to update main prompt:', error);
    }
  };

  const handleFirstMessageChange = async (value: string): Promise<void> => {
    setStartPromptContent(value);
    updateStartPrompt({ position: PromptPosition.START, content: value });
  };

  const handleSystemPromptChange = async (value: string): Promise<void> => {
    setMainPromptContent(value);
    updateMainPrompt({ position: PromptPosition.MAIN, content: value });
  };

  return (
    <FormControl fullWidth>
      <Box mb={3}>
        <Typography
          color="white"
          variant="h4"
        >
          Instructions
        </Typography>
        <Typography variant="caption">
          This section allows you to configure the instructions for how the bot
          should behave.
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          md={12}
          xs={12}
        >
          <Stack
            display="flex"
            flexDirection="column"
            width="100%"
            spacing={2}
          >
            <InfoTextField
              value={startPromptContent}
              setValue={handleFirstMessageChange}
              label="First Message"
              multiline
              tooltip="Instructions for the first message that the assistant will say."
            />
            <InfoTextField
              value={mainPromptContent}
              setValue={handleSystemPromptChange}
              label="System Prompt"
              tooltip="The system prompt can be used to configure the role, personality, and instructions for the assistant."
              placeholder={'Main system prompt'}
              multiline
              minRows={13}
            />
          </Stack>
        </Grid>
      </Grid>
    </FormControl>
  );
}
