import React, { createContext, useContext, useState, useCallback } from 'react';
import { AudioSource } from 'types/enums';

interface AudioContextType {
  audioSource: AudioSource;
  setAudioSource: (source: AudioSource) => void;
  handleSourceChange: (source: AudioSource) => void;
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  isRecording: boolean;
  setIsRecording: (isRecording: boolean) => void;
  isPaused: boolean;
  setIsPaused: (isPaused: boolean) => void;
  recordingTime: string;
  setRecordingTime: (time: string) => void;
  recordedAudio: string | null;
  setRecordedAudio: (audio: string | null) => void;
  isPlaying: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  cleaningFinished: boolean;
  setCleaningFinished: (finished: boolean) => void;
}

const AudioContext = createContext<AudioContextType | undefined>(undefined);

export const AudioContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [audioSource, setAudioSource] = useState<AudioSource>(
    AudioSource.RECORD,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [recordingTime, setRecordingTime] = useState('00:00');
  const [recordedAudio, setRecordedAudio] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [cleaningFinished, setCleaningFinished] = useState(false);
  const handleSourceChange = useCallback((source: AudioSource) => {
    setAudioSource(source);
  }, []);

  const value = {
    audioSource,
    setAudioSource,
    handleSourceChange,
    isSubmitting,
    setIsSubmitting,
    isRecording,
    setIsRecording,
    isPaused,
    setIsPaused,
    recordingTime,
    setRecordingTime,
    recordedAudio,
    setRecordedAudio,
    isPlaying,
    setIsPlaying,
    cleaningFinished,
    setCleaningFinished,
  };

  return (
    <AudioContext.Provider value={value}>{children}</AudioContext.Provider>
  );
};

export const useAudioState = () => {
  const context = useContext(AudioContext);
  if (context === undefined) {
    throw new Error('useAudio must be used within an AudioProvider');
  }
  return context;
};
