import { LoadingButton } from '@mui/lab';
import { PlanType } from 'types/enums';
import { useState } from 'react';
import { SxProps } from '@mui/material';
import { ColorVariant } from 'types/app';

interface UpgradeButtonProps {
  planType: PlanType;
  currentPlan: PlanType | undefined;
  onUpgrade: (planType: PlanType) => Promise<void>;
  color: ColorVariant;
  sx?: SxProps;
  variant?: 'contained' | 'outlined';
}

export function UpgradeButton({
  planType,
  currentPlan,
  onUpgrade,
  color,
  sx,
  variant = 'outlined',
}: UpgradeButtonProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleUpgrade = async () => {
    setIsLoading(true);
    try {
      await onUpgrade(planType);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoadingButton
      variant={variant}
      color={color}
      size="small"
      sx={{
        mt: 1,
        borderRadius: 4,
        height: 24,
        fontWeight: variant === 'outlined' ? 600 : 800,
        ...sx,
      }}
      onClick={handleUpgrade}
      loading={isLoading}
      disabled={currentPlan === planType}
    >
      {currentPlan === planType
        ? 'Current Plan'
        : `Get ${planType === PlanType.pro ? 'Business' : planType.charAt(0).toUpperCase() + planType.slice(1)}`}
    </LoadingButton>
  );
}
