import React from 'react';

import { SampleType } from 'types/enums';
import { useParams } from 'react-router-dom';
import { RowSampleMiniFile } from 'components/atoms';
import {
  Box,
  Stack,
  Typography,
  Alert,
  AlertTitle,
  Button,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { File } from '@duohub/types';

interface InterfaceDocumentContentProps {
  files: File[];
}

const InterfaceDocumentContent: React.FC<InterfaceDocumentContentProps> = ({
  files,
}) => {
  const { memoryID } = useParams<{ memoryID: string }>();

  if (files.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '60vh',
        }}
      >
        <Stack
          spacing={2}
          alignItems="center"
        >
          <Alert
            severity="info"
            sx={{ width: 'auto' }}
          >
            <AlertTitle>No files available to select</AlertTitle>
            <Typography variant="body2">
              Please add content to your content library.
            </Typography>
          </Alert>
          <Button
            variant="contained"
            color="primary"
            href="/content"
            size="small"
            sx={{ fontWeight: 'bold' }}
          >
            Add Content
          </Button>
        </Stack>
      </Box>
    );
  }

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        sx={{ px: 2, mb: 1 }}
      >
        <Typography
          variant="subtitle2"
          width="15%"
        >
          Type
        </Typography>
        <Typography
          variant="subtitle2"
          width="40%"
        >
          File Name
        </Typography>
        <Typography
          variant="subtitle2"
          width="8%"
        >
          Status
        </Typography>
        <Typography
          variant="subtitle2"
          width="8%"
        >
          Tokens
        </Typography>
        <Typography
          variant="subtitle2"
          width="15%"
        >
          Updated
        </Typography>
        <Box width="24px" />
      </Stack>

      <Box
        sx={{
          height: '100%',
          width: '100%',
          borderRadius: '4px',
          padding: '8px',
          '&::-webkit-scrollbar': {
            width: '10px',
            backgroundColor: grey[900],
            borderRadius: 8,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: [grey[500]],
            borderRadius: 8,
          },
          overflowY: 'scroll',
        }}
      >
        <Stack spacing={1}>
          {files.map((file) => (
            <RowSampleMiniFile
              key={file.id}
              file={file}
              sampleType={SampleType.document}
              sampleIndex={memoryID}
            />
          ))}
        </Stack>
      </Box>
    </>
  );
};

export default InterfaceDocumentContent;
