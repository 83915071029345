import { Stack } from '@mui/material';
import Layout from 'layouts';
import StartTitle from './components/startTitle';
import EndAction from './components/endAction';
import ProgressStepper from './components/progressStepper';
import { MediaAdd } from 'components/organisms';
import Samples from './components/samples';
import { useParams } from 'react-router-dom';
import { useGetVoiceQuery } from 'graphql/generated';
import { getVoiceStepindex } from 'common/utils';
import { VoiceStep } from 'types/enums';
import { Loading } from 'components/atoms';
import VoiceNewTrain from './components/train';
import VoiceNewTest from './components/test';
import Clean from './components/clean';

export default function StudioVoice() {
  const { voiceID } = useParams<{ voiceID: string }>();
  const { data: voiceData, isLoading } = useGetVoiceQuery({
    input: { id: voiceID },
  });
  const voice = voiceData?.getVoice?.data;
  const activeStep = getVoiceStepindex(voice?.step as VoiceStep);
  return (
    <Layout
      backArrow
      startTitle={
        <StartTitle
          newVoice={true}
          browseLibrary={false}
          anchorEl={null}
          setAnchorEl={() => {}}
          handleMenuClose={() => {}}
          handleTrainMyVoice={() => {}}
          handleBrowseLibrary={() => {}}
        />
      }
      endAction={<EndAction />}
    >
      <Stack
        direction="column"
        spacing={2}
        sx={{ py: 1 }}
      >
        <ProgressStepper />
        {isLoading && <Loading />}
        {!isLoading && activeStep === 0 && (
          <Stack
            direction="column"
            spacing={2}
          >
            <MediaAdd />
            <Samples />
          </Stack>
        )}
        {!isLoading && activeStep === 1 && <Clean />}
        {!isLoading && activeStep === 2 && <VoiceNewTrain />}
        {!isLoading && (activeStep === 3 || activeStep === 4) && (
          <VoiceNewTest />
        )}
      </Stack>
    </Layout>
  );
}
