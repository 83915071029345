import React from 'react';
import { Box, Typography, Stack, IconButton, Tooltip } from '@mui/material';
import ButtonIconTooltip from '../button-icon-tooltip';
import { CopyAll } from '@mui/icons-material';

interface PreviewHeaderProps {
  title: React.ReactNode | string;
  deleting?: boolean;
  onDelete: () => void;
  tooltip?: string;
  extraContent?: React.ReactNode;
  loading?: boolean;
  showCopy?: boolean;
  onCopy?: () => void;
}

export function PreviewHeader({
  title,
  deleting,
  onDelete,
  tooltip,
  extraContent,
  loading,
  showCopy,
  onCopy,
}: PreviewHeaderProps) {
  return (
    <Box
      sx={{
        p: 1,
        pl: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        gap={2}
      >
        {typeof title === 'string' ? (
          <Typography variant="h4">{title}</Typography>
        ) : (
          title
        )}
        {extraContent}
      </Box>
      <Stack
        direction="row"
        spacing={1.5}
        alignItems="center"
      >
        {showCopy && (
          <Tooltip
            title="Copy Memory ID"
            placement="top"
            arrow
          >
            <IconButton
              size="small"
              onClick={onCopy}
            >
              <CopyAll />
            </IconButton>
          </Tooltip>
        )}
        <ButtonIconTooltip
          onClick={onDelete}
          isLoading={deleting}
          tooltipTitle={tooltip || 'Delete'}
          tooltipColorVariant="error"
          icon="close"
          size="small"
        />
      </Stack>
    </Box>
  );
}
