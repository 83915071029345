import { createContext, useContext, useState, ReactNode, useMemo } from 'react';
import { ContextType } from 'types/enums';

interface MemoryContextState {
  memorySource: ContextType;
  setMemorySource: (memorySource: ContextType) => void;
  fullscreen: boolean;
  setFullscreen: (fullscreen: boolean) => void;
  ingestionComplete: boolean;
  setIngestionComplete: (ingestionComplete: boolean) => void;
}

const MemoryContext = createContext<MemoryContextState | undefined>(undefined);

export const useMemoryContext = () => {
  const context = useContext(MemoryContext);
  if (!context) {
    throw new Error(
      'useMemoryContext must be used within a MemoryContextProvider',
    );
  }
  return context;
};

interface MemoryContextProviderProps {
  children: ReactNode;
}

export function MemoryContextProvider({
  children,
}: MemoryContextProviderProps): JSX.Element {
  const [memorySource, setMemorySource] = useState<ContextType>(
    ContextType.upload,
  );
  const [fullscreen, setFullscreen] = useState(false);
  const [ingestionComplete, setIngestionComplete] = useState(false);
  const contextValue = useMemo(
    () => ({
      setMemorySource,
      memorySource,
      setFullscreen,
      fullscreen,
      ingestionComplete,
      setIngestionComplete,
    }),
    [memorySource, fullscreen, ingestionComplete],
  );

  return (
    <MemoryContext.Provider value={contextValue}>
      {children}
    </MemoryContext.Provider>
  );
}
