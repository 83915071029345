import {
  RadioButtonUnchecked,
  FiberManualRecord,
  RadioButtonChecked,
} from '@mui/icons-material';
import {
  Card,
  IconButton,
  Stack,
  Typography,
  Box,
  styled,
  Tooltip,
} from '@mui/material';
import { Memory } from '@duohub/types';
import { MemoryStep } from 'types/enums';
import { formatDate, getMemoryStepindex } from 'common/utils';
import { useNavigate } from 'react-router-dom';

const StepIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '24px',
  height: '24px',
}));

interface RowMiniMemoryProps {
  memory: Memory;
  onSelect: (id: string) => void;
  selected: boolean;
}

export default function RowMiniMemory({
  memory,
  onSelect,
  selected,
}: RowMiniMemoryProps) {
  const steps = ['Select', 'Settings', 'Ingest'];
  const activeStep = getMemoryStepindex(memory?.step as MemoryStep);
  const navigate = useNavigate();

  const goToMemory = () => {
    navigate(`/graph/${memory.id}`);
  };

  const isDisabled =
    memory.step !== MemoryStep.review && memory.step !== MemoryStep.complete;

  return (
    <Card
      sx={{
        px: 2,
        py: 1,
        opacity: memory.step === MemoryStep.complete ? 1 : 0.75,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          direction="row"
          spacing={3}
          alignItems="center"
        >
          <Tooltip
            placement="top"
            arrow
            title={`${activeStep === 4 ? 'Complete' : 'Incomplete: ' + steps[activeStep]}`}
            onClick={goToMemory}
          >
            <Stack
              direction="row"
              alignItems="center"
            >
              {steps.map((step, index) => (
                <StepIcon
                  key={step}
                  sx={{
                    opacity: index <= activeStep ? 1 : 0.3,
                    cursor: 'pointer',
                  }}
                >
                  {index <= activeStep ? (
                    <FiberManualRecord
                      sx={{ fontSize: '16px' }}
                      color="primary"
                    />
                  ) : (
                    <FiberManualRecord
                      sx={{ opacity: 0.3, fontSize: '16px' }}
                    />
                  )}
                </StepIcon>
              ))}
            </Stack>
          </Tooltip>
          <Typography
            variant="h5"
            noWrap
          >
            {memory.name}
          </Typography>
          <Typography variant="body2">
            {formatDate(memory.updatedAt)}
          </Typography>
        </Stack>
        <Box
          width={200}
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <IconButton
            onClick={() => onSelect(memory.id)}
            disabled={isDisabled}
          >
            {selected ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
          </IconButton>
        </Box>
      </Stack>
    </Card>
  );
}
