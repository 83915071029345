import React, { useCallback } from 'react';
import { Card, Stack } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { FileCategory, FileSource, FileType, UploadStatus } from 'types/enums';
import { useUploadQueue } from 'use/upload';
import { v4 as uuid } from 'uuid';
import { AppFile, FileWithMetadata } from 'types/app';
import { getAcceptedFiles } from 'common/utils';
import { useUser } from 'use/user';
import { CloudUpload } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { useParams } from 'react-router-dom';

interface InterfaceUploadProps {
  acceptedFileTypes: FileCategory;
}

const InterfaceUpload: React.FC<InterfaceUploadProps> = ({
  acceptedFileTypes,
}) => {
  const { addToQueue } = useUploadQueue();
  const { getUser } = useUser();
  const { voiceID } = useParams<{ voiceID: string }>();

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const user = await getUser();
      const filesToUpload: FileWithMetadata[] = acceptedFiles.map((file) => {
        const appFile: AppFile = {
          id: uuid(),
          name: file.name,
          size: file.size,
          category: acceptedFileTypes,
          source: FileSource.upload,
          userID: user?.userId,
          extension: file.name.split('.').pop() || '',
          fileType: FileType.audio_upload,
        };

        return {
          file,
          appFile,
          uploadStatus: UploadStatus.pending,
          progress: 0,
          id: uuid(),
          sampleIndexID: voiceID,
          sampleType: 'voice',
          createSample: true,
        };
      });
      addToQueue(filesToUpload);
    },
    [getUser, addToQueue, acceptedFileTypes, voiceID],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: getAcceptedFiles(acceptedFileTypes),
    onDrop,
  });

  return (
    <Stack
      direction="column"
      spacing={1.5}
      sx={{ minHeight: '200px' }}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
      >
        <Card
          elevation={2}
          {...getRootProps()}
          sx={{
            border: `1px dashed ${grey[800]}`,
            borderRadius: '4px',
            padding: '20px',
            textAlign: 'center',
            cursor: 'pointer',
            width: '100%',
            height: '150px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <input {...getInputProps()} />
          <CloudUpload
            sx={{ fontSize: 80, color: grey[isDragActive ? 300 : 800] }}
          />
        </Card>
      </Stack>
    </Stack>
  );
};

export default InterfaceUpload;
