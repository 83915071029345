import {
  ArrowBack,
  Assistant,
  AutoAwesome,
  Check,
  Close,
  Flare,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Container,
  Dialog,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Button,
  TextField,
} from '@mui/material';
import { blue, green } from '@mui/material/colors';
import { UpgradeButton } from 'components/atoms';
import { useCallback, useMemo, useState } from 'react';

import {
  useGetUserQuery,
  useLazyGetCheckoutQuery,
  useSendBillingInboundMutation,
} from 'graphql/generated';
import { PlanType } from 'types/enums';
import mixpanel from 'mixpanel-browser';
import { usePro } from 'use/pro';

export default function ModalUpgrade() {
  const { openModal, setOpenModal } = usePro();
  const [getCheckout] = useLazyGetCheckoutQuery();
  const { data: rawUser } = useGetUserQuery({ input: {} });
  const user = useMemo(() => rawUser?.getUser, [rawUser]);
  const [sendBillingInbound, { isLoading: isSendingBillingInbound }] =
    useSendBillingInboundMutation();
  const freePlanFeatures = [
    { feature: '10,000 tokens in', available: true },
    { feature: '30,000 tokens out', available: true },
    { feature: '1 graph store', available: true },
    { feature: '5 vector stores', available: true },
    { feature: '5,000 messages', available: true },
    { feature: '~100ms query', available: true },
  ];

  const hobbyPlanFeatures = [
    { feature: '100,000 tokens in', available: true },
    { feature: '200,000 tokens out ', available: true },
    { feature: '10 graphs', available: true },
    { feature: 'Unlimited vector stores', available: true },
    { feature: '50,000 messages', available: true },
    { feature: '~50ms query', available: true },
  ];

  const businessPlanFeatures = [
    { feature: '1,000,000 tokens in', available: true },
    { feature: '2,000,000 tokens out', available: true },
    { feature: 'Unlimited graphs', available: true },
    { feature: 'Unlimited vector stores', available: true },
    { feature: '2x faster ingestion', available: true },
    { feature: '500,000 messages', available: true },
    { feature: '~50ms query', available: true },
  ];

  const enterprisePlanFeatures = [
    { feature: '10,000,000 tokens in', available: true },
    { feature: '20,000,000 tokens out', available: true },
    { feature: 'Unlimited graphs', available: true },
    { feature: 'Unlimited vector stores', available: true },
    { feature: '4x faster ingestion', available: true },
    { feature: 'Unlimited messages', available: true },
    { feature: '~50ms query', available: true },
  ];

  const handleGetCheckout = async (planType: PlanType) => {
    try {
      const priceID =
        planType === PlanType.pro
          ? process.env.REACT_APP_PRO_PRICE_ID
          : process.env.REACT_APP_STARTER_PRICE_ID;
      const { getCheckout: checkout } = await getCheckout({
        input: {
          priceID,
          planType,
        },
      }).unwrap();
      mixpanel.track('get_checkout', {
        'Plan Type': planType,
      });
      window.location.href = checkout.url;
    } catch (error) {
      console.error('Error fetching checkout data:', error);
    }
  };

  const [showContactForm, setShowContactForm] = useState(false);
  const [contactMessage, setContactMessage] = useState('');

  const handleContactSubmit = useCallback(async () => {
    try {
      await sendBillingInbound({
        input: {
          content: contactMessage,
        },
      }).unwrap();
    } catch (error) {
      console.error('Error sending billing inbound:', error);
    }
    setContactMessage('');
    setShowContactForm(false);
  }, [contactMessage, sendBillingInbound]);

  return (
    <Dialog
      fullScreen
      open={openModal}
      onClose={() => setOpenModal(false)}
      PaperProps={{ elevation: 1 }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <img
          src="/img/icon-blue.svg"
          alt="duohub"
          style={{ width: 32, height: 32 }}
        />
        <IconButton onClick={() => setOpenModal(false)}>
          <Close />
        </IconButton>
      </Stack>
      <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
        <Container maxWidth="lg">
          {!showContactForm ? (
            <Box>
              <Stack
                direction="column"
                justifyContent="center"
                spacing={4}
              >
                <Typography
                  align="center"
                  variant="h2"
                >
                  Choose Your Plan
                </Typography>

                <Card sx={{ minHeight: 250, borderRadius: 2 }}>
                  <Grid
                    container
                    sx={{ height: '100%' }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        borderRight: '1px solid #2a2a2a',
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                      >
                        <Assistant />
                        <Typography variant="h4">Free</Typography>
                        <Typography
                          variant="h4"
                          component="span"
                          sx={{ fontWeight: 700, opacity: 0.4 }}
                        >
                          $0 / mo
                        </Typography>
                      </Stack>
                      <List sx={{ my: 2, flexGrow: 1 }}>
                        {freePlanFeatures.map((item, index) => (
                          <ListItem
                            dense
                            key={index}
                          >
                            <ListItemIcon>
                              {item.available ? <Check /> : <Close />}
                            </ListItemIcon>
                            <ListItemText primary={item.feature} />
                          </ListItem>
                        ))}
                      </List>
                      <Box>
                        {user?.plan === PlanType.free && (
                          <Button
                            variant="outlined"
                            disabled
                            fullWidth
                            sx={{ height: 24, fontWeight: 600 }}
                          >
                            Current Plan
                          </Button>
                        )}
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        borderRight: '1px solid #2a2a2a',
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                      >
                        <AutoAwesome sx={{ color: blue[400] }} />
                        <Typography variant="h4">Starter</Typography>
                        <Typography
                          variant="h4"
                          component="span"
                          sx={{ fontWeight: 700, opacity: 0.4 }}
                        >
                          $29 / mo
                        </Typography>
                      </Stack>
                      <List sx={{ my: 2, flexGrow: 1 }}>
                        {hobbyPlanFeatures.map((item, index) => (
                          <ListItem
                            dense
                            key={index}
                          >
                            <ListItemIcon>
                              <Check />
                            </ListItemIcon>
                            <ListItemText primary={item.feature} />
                          </ListItem>
                        ))}
                      </List>
                      <UpgradeButton
                        planType={PlanType.starter}
                        currentPlan={user?.plan as PlanType}
                        onUpgrade={handleGetCheckout}
                        color="primary"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        borderRight: '1px solid #2a2a2a',
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                      >
                        <Flare sx={{ color: green[400] }} />
                        <Typography variant="h4">Business</Typography>
                        <Typography
                          variant="h4"
                          component="span"
                          sx={{ fontWeight: 700, opacity: 0.4 }}
                        >
                          $199 / mo
                        </Typography>
                      </Stack>
                      <List sx={{ my: 2, flexGrow: 1 }}>
                        {businessPlanFeatures.map((item, index) => (
                          <ListItem
                            dense
                            key={index}
                          >
                            <ListItemIcon>
                              <Check />
                            </ListItemIcon>
                            <ListItemText primary={item.feature} />
                          </ListItem>
                        ))}
                      </List>
                      <UpgradeButton
                        planType={PlanType.pro}
                        currentPlan={user?.plan as PlanType}
                        onUpgrade={handleGetCheckout}
                        color="primary"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                      >
                        <Flare sx={{ color: green[400] }} />
                        <Typography variant="h4">Enterprise</Typography>
                        <Typography
                          variant="h4"
                          component="span"
                          sx={{ fontWeight: 700, opacity: 0.4 }}
                        >
                          $999 / mo
                        </Typography>
                      </Stack>
                      <List sx={{ my: 2, flexGrow: 1 }}>
                        {enterprisePlanFeatures.map((item, index) => (
                          <ListItem
                            dense
                            key={index}
                          >
                            <ListItemIcon>
                              <Check />
                            </ListItemIcon>
                            <ListItemText primary={item.feature} />
                          </ListItem>
                        ))}
                      </List>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        fullWidth
                        sx={{ height: 28, fontWeight: 600 }}
                        onClick={() => setShowContactForm(true)}
                      >
                        Contact Sales
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              </Stack>
              <Container
                sx={{ mt: 4 }}
                maxWidth="xs"
              >
                <Stack
                  direction="column"
                  alignItems="center"
                  spacing={2}
                  sx={{ p: 2 }}
                >
                  <Typography variant="h6">
                    Need help with custom pricing?
                  </Typography>
                  <Button
                    variant="text"
                    size="small"
                    sx={{ height: 28, fontWeight: 600 }}
                    onClick={() => setShowContactForm(true)}
                  >
                    Talk to us
                  </Button>
                </Stack>
              </Container>
            </Box>
          ) : (
            <Container
              maxWidth="md"
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Stack spacing={4}>
                <Typography
                  variant="h4"
                  align="center"
                >
                  Use Case Details
                </Typography>
                <Typography
                  align="center"
                  variant="body1"
                >
                  Please enter details about what you intend to use duohub for,
                  your approximate monthly usage limit, whether you are
                  interested in an on-premise solution, and any other details
                  that can help us help you.
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={6}
                  variant="outlined"
                  placeholder="How can we help you?"
                  value={contactMessage}
                  onChange={(e) => setContactMessage(e.target.value)}
                />
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Button
                    variant="text"
                    size="small"
                    sx={{ height: 28, fontWeight: 600 }}
                    startIcon={<ArrowBack />}
                    onClick={() => setShowContactForm(false)}
                  >
                    Back to Pricing
                  </Button>
                  <LoadingButton
                    variant="contained"
                    size="small"
                    sx={{ height: 28, fontWeight: 800 }}
                    onClick={handleContactSubmit}
                    loading={isSendingBillingInbound}
                  >
                    Submit
                  </LoadingButton>
                </Stack>
              </Stack>
            </Container>
          )}
        </Container>
      </Box>
    </Dialog>
  );
}
