import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';

const apiClient = generateClient();

const graphqlBaseQuery = async ({ document, variables }) => {
  try {
    const session = await fetchAuthSession();
    const response = await apiClient.graphql({
      query: document,
      variables: variables,
      authMode: session.tokens ? 'userPool' : 'iam',
    });
    if ('data' in response) {
      return { data: response.data };
    } else {
      throw new Error('Unexpected response format');
    }
  } catch (error) {
    return { error: { status: error.statusCode, data: error } };
  }
};
const baseQueryWithGraphQLAuth = async (args) => {
  const result = await graphqlBaseQuery(args);
  if (result.error) {
    console.error('API call failed:', result.error);
  }
  return result;
};

export const baseApiWithGraphQL = createApi({
  baseQuery: baseQueryWithGraphQLAuth,
  endpoints: () => ({}),
  tagTypes: [],
});
