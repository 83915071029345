import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Skeleton,
  Stack,
  Typography,
  Dialog,
  Divider,
} from '@mui/material';
import Layout from 'layouts';
import { useMemo, useState } from 'react';
import { useGetUserQuery } from 'graphql/generated';
import { DateTime } from 'luxon';
import { ApiKeys, OverviewUsage } from 'components/organisms';
import {
  Layers,
  PlayCircle,
  Code,
  BubbleChart,
  Hub,
} from '@mui/icons-material';
import ReactPlayer from 'react-player/lazy';

const getGreeting = (): string => {
  const hour = DateTime.local().hour;
  if (hour >= 0 && hour < 12) return 'Good morning';
  if (hour >= 12 && hour < 17) return 'Good afternoon';
  return 'Good evening';
};

interface QuickActionCardProps {
  icon: React.ReactNode;
  title: string;
  cardKey: string;
  videoUrl?: string;
}

const quickActions: QuickActionCardProps[] = [
  {
    icon: <Layers />,
    title: 'Add Content',
    cardKey: 'add-content',
    videoUrl: 'https://youtu.be/JI-i1AOAff0',
  },
  {
    icon: <Hub />,
    title: 'Create a Graph',
    cardKey: 'create-graph',
    videoUrl: 'https://youtu.be/IoLEeyg5ewg',
  },
  {
    icon: <BubbleChart />,
    title: 'Create a Vector Store',
    cardKey: 'create-vector-store',
    videoUrl: 'https://youtu.be/ldz0Av4Jtsk',
  },
  {
    icon: <Code />,
    title: 'Query the API',
    cardKey: 'query-api',
    videoUrl: 'https://youtu.be/TmYD8vJ3D6Q',
  },
];

interface VideoDialogProps {
  open: boolean;
  onClose: () => void;
  videoUrl: string;
}

const VideoDialog = ({ open, onClose, videoUrl }: VideoDialogProps) => (
  <Dialog
    open={open}
    onClose={onClose}
    maxWidth="md"
    fullWidth
    sx={{
      '& .MuiDialog-paper': {
        background: '#000',
      },
    }}
  >
    <Box sx={{ position: 'relative', pt: '56.25%' /* 16:9 aspect ratio */ }}>
      <ReactPlayer
        url={videoUrl}
        width="100%"
        height="100%"
        style={{ position: 'absolute', top: 0, left: 0 }}
        controls
        playing
      />
    </Box>
  </Dialog>
);

const QuickActionCard = ({
  icon,
  title,
  cardKey,
  videoUrl,
}: QuickActionCardProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClick = (e: React.MouseEvent) => {
    if (videoUrl) {
      e.preventDefault();
      setDialogOpen(true);
    }
  };

  // Get gradient based on index
  const getGradient = (index: number) => {
    const gradients = [
      'linear-gradient(45deg, #000 0%, rgba(180, 54, 92, 1) 100%)', // Pink
      'linear-gradient(45deg, #000 0%, rgba(166, 93, 46, 1) 100%)', // Orange
      'linear-gradient(45deg, #000 0%, rgba(90, 34, 139, 1) 100%)', // Purple
      'linear-gradient(45deg, #000 0%, rgba(43, 84, 120, 1) 100%)', // Blue
    ];
    return gradients[index % gradients.length];
  };

  return (
    <Grid
      item
      xs={12}
      md={3}
    >
      <Card>
        <CardActionArea onClick={handleClick}>
          <CardContent
            sx={{
              height: 150,
              color: 'inherit',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              transition: 'all 0.3s ease-in-out',
              background: getGradient(
                quickActions.findIndex((a) => a.cardKey === cardKey),
              ),
              '&:hover': {
                '& .MuiTypography-root': {
                  color: 'white',
                },
                '& .MuiSvgIcon-root': {
                  color: 'white',
                },
              },
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
            >
              {icon}
              <Typography
                variant="h4"
                sx={{ ml: 2 }}
              >
                {title}
              </Typography>
            </Stack>
            {videoUrl && (
              <Box sx={{ textAlign: 'center' }}>
                <PlayCircle sx={{ fontSize: 32, opacity: 0.9 }} />
              </Box>
            )}
            <Box sx={{ height: 2 }}></Box>
          </CardContent>
        </CardActionArea>
      </Card>
      {videoUrl && (
        <VideoDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          videoUrl={videoUrl}
        />
      )}
    </Grid>
  );
};

export default function Overview() {
  const { data: userData, isLoading: isUserLoading } = useGetUserQuery({
    input: {},
  });
  const user = useMemo(() => userData?.getUser, [userData]);

  const greeting = useMemo(() => getGreeting(), []);

  return (
    <Layout
      startTitle={
        <Typography
          variant="h4"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {isUserLoading ? (
            <Skeleton width={250} />
          ) : (
            <>
              {greeting}, {user?.firstName}
            </>
          )}
        </Typography>
      }
    >
      <Card
        elevation={0}
        sx={{ border: 'none' }}
      >
        <Grid
          sx={{ py: 2 }}
          container
          spacing={2}
        >
          {quickActions.map((action) => (
            <QuickActionCard
              key={action.cardKey}
              cardKey={action.cardKey}
              {...action}
            />
          ))}
        </Grid>
      </Card>
      <Box>
        <ApiKeys readonly />
      </Box>
      <Card sx={{ mt: 2 }}>
        <Box sx={{ p: 2 }}>
          <Typography variant="h4">Usage</Typography>
        </Box>
        <Divider sx={{ opacity: 0.5 }} />
        <Box sx={{ p: 2 }}>
          <OverviewUsage />
        </Box>
      </Card>
    </Layout>
  );
}
