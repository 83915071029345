import { createBrowserRouter } from 'react-router-dom';
import {
  Overview,
  Data,
  Logs,
  Account,
  Success,
  Call,
  Memory,
  MemoryID,
  SignUp,
  SignIn,
  ConfirmSignUp,
  Studio,
} from './routes';
import AuthWrapper from 'wrapper';
import Content from 'routes/content';
import StudioVoice from 'routes/studio/voice';
import StudioBot from 'routes/studio/bot';

export const router = createBrowserRouter([
  {
    children: [
      {
        path: '/',
        element: (
          <AuthWrapper>
            <Overview />
          </AuthWrapper>
        ),
      },
      {
        path: '/studio/*',
        element: (
          <AuthWrapper>
            <Studio />
          </AuthWrapper>
        ),
      },
      {
        path: '/studio/voice/:voiceID',
        element: (
          <AuthWrapper>
            <StudioVoice />
          </AuthWrapper>
        ),
      },
      {
        path: '/studio/bot/:botID/*',
        element: (
          <AuthWrapper>
            <StudioBot />
          </AuthWrapper>
        ),
      },
      {
        path: '/data/*',
        element: (
          <AuthWrapper>
            <Data />
          </AuthWrapper>
        ),
      },
      {
        path: '/memory/*',
        element: (
          <AuthWrapper>
            <Memory />
          </AuthWrapper>
        ),
      },
      {
        path: '/memory/:memoryID',
        element: (
          <AuthWrapper>
            <MemoryID />
          </AuthWrapper>
        ),
      },
      {
        path: '/content/*',
        element: (
          <AuthWrapper>
            <Content />
          </AuthWrapper>
        ),
      },
      {
        path: '/log/*',
        element: (
          <AuthWrapper>
            <Logs />
          </AuthWrapper>
        ),
      },
      {
        path: '/account/*',
        element: (
          <AuthWrapper>
            <Account />
          </AuthWrapper>
        ),
      },
      {
        path: '/success',
        element: (
          <AuthWrapper>
            <Success />
          </AuthWrapper>
        ),
      },
      {
        path: '/call/:botID',
        element: <Call />,
      },
      {
        path: '/signup',
        element: <SignUp />,
      },
      {
        path: '/signin',
        element: <SignIn />,
      },
      {
        path: '/confirm-signup',
        element: <ConfirmSignUp />,
      },
    ],
  },
]);
