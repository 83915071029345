import { Box, Button, TextField, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';

interface BulkWebsiteFormProps {
  bulkUrls: string;
  bulkName: string;
  onBulkUrlsChange: (urls: string) => void;
  onBulkNameChange: (name: string) => void;
  isProcessingCsv: boolean;
  setIsProcessingCsv: (processing: boolean) => void;
  uploadedFileName: string;
  setUploadedFileName: (name: string) => void;
}

export default function BulkWebsiteForm({
  bulkUrls,
  bulkName,
  onBulkUrlsChange,
  onBulkNameChange,
  isProcessingCsv,
  setIsProcessingCsv,
  uploadedFileName,
  setUploadedFileName,
}: BulkWebsiteFormProps) {
  const onDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      setIsProcessingCsv(true);
      setUploadedFileName(file.name);
      onBulkNameChange(file.name.replace('.csv', ''));

      try {
        const text = await file.text();
        Papa.parse(text, {
          complete: (results) => {
            const urls = results.data
              .map((row: any) => row[0])
              .filter(
                (url: string) => url && url.trim() !== '' && url.includes('.'),
              );
            onBulkUrlsChange(urls.join('\n'));
          },
          error: (error) => {
            console.error('Error parsing CSV:', error);
          },
        });
      } catch (error) {
        console.error('Error reading file:', error);
      }
      setIsProcessingCsv(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.ms-excel': ['.csv'],
    },
    multiple: false,
  });

  return (
    <>
      <TextField
        label="Name of this group of URLs"
        fullWidth
        value={bulkName}
        onChange={(e) => onBulkNameChange(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TextField
        label="Paste URLs (one per line)"
        fullWidth
        multiline
        rows={4}
        value={bulkUrls}
        onChange={(e) => onBulkUrlsChange(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Box
        {...getRootProps()}
        sx={{
          border: '2px dashed',
          borderColor: isDragActive ? 'primary.main' : 'grey.400',
          borderRadius: 1,
          p: 2,
          textAlign: 'center',
          cursor: 'pointer',
          bgcolor: isDragActive ? 'action.hover' : 'inherit',
          '&:hover': {
            bgcolor: 'action.hover',
          },
        }}
      >
        <input {...getInputProps()} />
        {isProcessingCsv ? (
          <Typography>Processing CSV...</Typography>
        ) : isDragActive ? (
          <Typography>Drop the CSV file here</Typography>
        ) : (
          <>
            <Typography>Drag and drop a CSV file here</Typography>
            <Button
              variant="outlined"
              size="small"
              sx={{ mt: 1 }}
              onClick={(e) => {
                e.stopPropagation();
                (
                  document.querySelector(
                    'input[type="file"]',
                  ) as HTMLInputElement
                )?.click();
              }}
            >
              Upload CSV
            </Button>
          </>
        )}
        <Typography
          variant="caption"
          display="block"
          color="text.secondary"
          sx={{ mt: 1 }}
        >
          CSV should have URLs in the first column
        </Typography>
      </Box>
    </>
  );
}
