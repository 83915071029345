import { ChevronRight } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { User } from '@duohub/types';
import { formatCallDuration, formatDate } from 'common/utils';
import { useNavigate } from 'react-router-dom';
import { Call } from 'graphql/generated';

export default function RowCall({
  call,
  owner,
  participant,
}: {
  call: Call;
  owner: User;
  participant: User;
}) {
  const navigate = useNavigate();

  return (
    <Card sx={{ opacity: call.unread ? 1 : 0.6 }}>
      <CardActionArea
        sx={{ p: 1 }}
        onClick={() => navigate(`/log/call/${call.id}`)}
      >
        <Grid
          container
          alignItems="center"
        >
          <Grid
            item
            sm={7}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
            >
              <Avatar
                alt={`${participant?.firstName} ${participant?.lastName}`}
              >
                {participant?.firstName[0]}
                {participant?.lastName[0]}
              </Avatar>
              <Typography variant="h5">
                {participant?.firstName || 'Unknown'}{' '}
                {participant?.lastName || 'Person'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            sm={3}
          >
            <Typography variant="h6">
              {formatDate(call.startTime, 'ccc, dd MMM yy - HH:mm:ss')}
            </Typography>
          </Grid>
          <Grid
            item
            sm={1}
          >
            <Typography variant="h6">
              {formatCallDuration(call.callDuration)}
            </Typography>
          </Grid>
          <Grid
            item
            sm={1}
          >
            <Box
              display="flex"
              justifyContent="flex-end"
            >
              <ChevronRight />
            </Box>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
}
