import React, {
  useRef,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import WaveSurfer from 'wavesurfer.js';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record.esm.js';
import { AudioContainerWavesurfer } from 'components/atoms';
import { useAudio } from 'use/audio';
import { AudioWaveformRef } from 'types/app';
import { grey, red, green } from '@mui/material/colors';
import { styled } from '@mui/material';

const StyledAudioContainer = styled(AudioContainerWavesurfer)`
  overflow: hidden !important;
  width: 100%;
  height: 144px;
`;

interface AudioWaveformProps {
  onRecordEnd?: (blob: Blob) => void;
  onRecordProgress?: (time: number) => void;
}

const AudioWaveform = forwardRef<AudioWaveformRef, AudioWaveformProps>(
  ({ onRecordEnd, onRecordProgress }, ref) => {
    const { recordedAudio } = useAudio();
    const waveformRef = useRef<HTMLDivElement>(null);
    const wavesurferRef = useRef<WaveSurfer | null>(null);
    const recordRef = useRef<RecordPlugin | null>(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [isRecording, setIsRecording] = useState(false);

    const destroyWaveSurfer = useCallback(() => {
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
        wavesurferRef.current = null;
      }
      if (recordRef.current) {
        recordRef.current = null;
      }
    }, []);

    const createWaveSurfer = useCallback(() => {
      if (waveformRef.current && !wavesurferRef.current) {
        wavesurferRef.current = WaveSurfer.create({
          container: waveformRef.current,
          waveColor: red[800],
          progressColor: grey[200],
          cursorColor: '#ddd5e9',
          cursorWidth: 2,
          height: 144,
          minPxPerSec: 100,
          hideScrollbar: true,
          plugins: [
            RecordPlugin.create({
              scrollingWaveform: true,
              renderRecordedAudio: true,
            }),
          ],
        });

        const recordPlugin = wavesurferRef.current
          .getActivePlugins()
          .find((plugin) => plugin instanceof RecordPlugin) as
          | RecordPlugin
          | undefined;

        if (recordPlugin) {
          recordRef.current = recordPlugin;

          recordRef.current.on('record-end', (blob: Blob) => {
            console.log('record-end event fired');
            setIsRecording(false);
            onRecordEnd(blob);
          });

          recordRef.current.on('record-progress', (time: number) => {
            onRecordProgress(time);
          });
        } else {
          console.error('RecordPlugin not found');
        }
      }
    }, [onRecordEnd, onRecordProgress]);

    useEffect(() => {
      createWaveSurfer();
      return () => {
        destroyWaveSurfer();
      };
    }, [createWaveSurfer, destroyWaveSurfer]);

    useEffect(() => {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          setContainerWidth(entry.contentRect.width);
        }
      });

      if (waveformRef.current) {
        resizeObserver.observe(waveformRef.current);
      }

      return () => {
        resizeObserver.disconnect();
      };
    }, []);

    useEffect(() => {
      if (wavesurferRef.current) {
        wavesurferRef.current.setOptions({ width: containerWidth });
      }
    }, [containerWidth]);

    useEffect(() => {
      if (wavesurferRef.current) {
        wavesurferRef.current.setOptions({
          waveColor: isRecording ? red[800] : green[800],
        });
      }
    }, [isRecording]);

    useImperativeHandle(ref, () => ({
      destroy: () => {
        console.log('destroy called');
        destroyWaveSurfer();
      },
      destroyWaveSurfer,
      startRecording: () => {
        console.log('startRecording called');
        if (!wavesurferRef.current) {
          createWaveSurfer();
        }
        setIsRecording(true);
        recordRef.current?.startRecording();
      },
      load: (url: string) => {
        wavesurferRef.current?.load(url);
      },
      stopRecording: () => {
        console.log('stopRecording called');
        setIsRecording(false);
        recordRef.current?.stopRecording();
      },
      pauseRecording: () => {
        console.log('pauseRecording called');
        recordRef.current?.pauseRecording();
      },
      resumeRecording: () => {
        console.log('resumeRecording called');
        recordRef.current?.resumeRecording();
      },
      play: () => wavesurferRef.current?.play(),
      pause: () => wavesurferRef.current?.pause(),
      seekTo: (progress: number) => wavesurferRef.current?.seekTo(progress),
    }));

    useEffect(() => {
      if (recordedAudio && wavesurferRef.current) {
        wavesurferRef.current.load(recordedAudio);
      }
    }, [recordedAudio]);

    return (
      <StyledAudioContainer
        ref={waveformRef}
        onRecordEnd={onRecordEnd}
        onRecordProgress={onRecordProgress}
      />
    );
  },
);

export default AudioWaveform;
