import { Info } from '@mui/icons-material';
import {
  Card,
  CardContent,
  Divider,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useGetMemoryQuery, useUpdateMemoryMutation } from 'graphql/generated';
import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

// URL validation function
const isValidUrl = (url: string): boolean => {
  if (!url) return true; // Empty string is considered valid
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

export default function MemoryNewStepWebhook({
  isDisabled,
}: {
  isDisabled: boolean;
}) {
  const [webhookUrl, setWebhookUrl] = useState('');
  const [error, setError] = useState(false);
  const { memoryID } = useParams();
  const [updateMemory] = useUpdateMemoryMutation();
  const { data: memoryData, isLoading } = useGetMemoryQuery({
    input: { id: memoryID },
  });
  const memory = memoryData?.getMemory?.data;

  useEffect(() => {
    setWebhookUrl(memory?.webhookUrl || '');
  }, [memory?.webhookUrl]);

  const handleUpdateMemory = useCallback(
    (webhookUrl: string) => {
      if (isLoading) return;
      if (isValidUrl(webhookUrl)) {
        updateMemory({
          input: {
            id: memoryID,
            webhookUrl,
          },
        }).unwrap();
        setError(false);
      } else {
        setError(true);
      }
    },
    [memoryID, updateMemory, isLoading],
  );

  useEffect(() => {
    handleUpdateMemory(webhookUrl);
  }, [handleUpdateMemory, webhookUrl]);

  return (
    <Card>
      <CardContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">Webhook for update notifications</Typography>
          <Tooltip
            arrow
            placement="top"
            title="Get notifications when this memory status updates"
          >
            <Info sx={{ color: 'text.secondary' }} />
          </Tooltip>
        </Stack>
        <Divider sx={{ my: 2, opacity: 0.5 }} />
        <TextField
          label="Webhook URL"
          fullWidth
          disabled={isDisabled}
          value={webhookUrl}
          size="small"
          placeholder="https://example.com/webhook"
          onChange={(e) => setWebhookUrl(e.target.value)}
          error={error}
          helperText={error ? 'Please enter a valid URL' : ''}
        />
      </CardContent>
    </Card>
  );
}
