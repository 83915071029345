import { ProContextProvider, useProContext } from './state';

export const usePro = () => {
  const context = useProContext();

  return {
    ...context,
  };
};

export { ProContextProvider };
