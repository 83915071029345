import Layout from 'layouts';
import { Route, Routes } from 'react-router-dom';
import Memory from './memory';
import { Stack, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { ButtonIconTooltip } from 'components/atoms';
import { useGetMemoriesQuery, useGetUserQuery } from 'graphql/generated';
import { useMemo, useState } from 'react';
import { PlanType } from 'types/enums';
import { NewMemory } from 'components/molecules';

export default function MemoryIndex() {
  const { data: userData, isLoading: isUserLoading } = useGetUserQuery({
    input: {},
  });
  const { data: memoryData, isLoading: isMemoryLoading } = useGetMemoriesQuery(
    {
      input: {},
    },
    { refetchOnMountOrArgChange: true },
  );
  const memories = useMemo(() => memoryData?.getMemories?.data, [memoryData]);
  const user = useMemo(() => userData?.getUser, [userData]);
  const location = useLocation();

  const newMemory = location.pathname.includes('new');
  const index =
    !location.pathname.includes('library') &&
    !location.pathname.includes('new');
  const isLoading = isUserLoading || isMemoryLoading;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  return (
    <Layout
      backArrow
      startTitle={
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
          >
            <Typography variant="h4">
              {newMemory ? 'New Memory' : 'Memory'}
            </Typography>
            {index && (
              <>
                <ButtonIconTooltip
                  isLoading={isLoading}
                  isDisabled={isLoading}
                  onClick={handleOpenDialog}
                  tooltipTitle={
                    user?.plan !== PlanType.free || memories?.length < 1
                      ? 'Add Memory'
                      : 'Upgrade to add more graphs'
                  }
                  icon="add"
                  tooltipColorVariant={
                    user?.plan === PlanType.free && memories?.length >= 1
                      ? 'warning'
                      : 'info'
                  }
                />
              </>
            )}
          </Stack>
        </Stack>
      }
    >
      <NewMemory
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
      />
      <Routes>
        <Route
          path="/"
          element={<Memory />}
        />
      </Routes>
    </Layout>
  );
}
