import React, { useState } from 'react';
import {
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Add,
  VideoFile,
  AudioFile,
  Description,
  Refresh,
  Language,
} from '@mui/icons-material';

interface AddContentMenuProps {
  onYoutubeClick: () => void;
  onWebsiteClick: () => void;
  onVideoClick: () => void;
  onAudioClick: () => void;
  onDocumentClick: () => void;
  onRefresh: () => void;
  isLoading: boolean;
}

const AddContentMenu: React.FC<AddContentMenuProps> = ({
  onYoutubeClick,
  onWebsiteClick,
  onVideoClick,
  onAudioClick,
  onDocumentClick,
  onRefresh,
  isLoading,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
    >
      <Typography variant="h4">Content</Typography>
      <Tooltip
        title="Add content"
        arrow
      >
        <IconButton onClick={handleClick}>
          <Add />
        </IconButton>
      </Tooltip>
      <Tooltip
        title="Refresh"
        arrow
      >
        <IconButton onClick={onRefresh}>
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <Refresh fontSize="small" />
          )}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            onWebsiteClick();
            handleClose();
          }}
        >
          <Language
            fontSize="small"
            sx={{ mr: 1 }}
          />{' '}
          Web
        </MenuItem>
        <MenuItem
          onClick={() => {
            onVideoClick();
            handleClose();
          }}
        >
          <VideoFile
            fontSize="small"
            sx={{ mr: 1 }}
          />{' '}
          Video
        </MenuItem>
        <MenuItem
          onClick={() => {
            onAudioClick();
            handleClose();
          }}
        >
          <AudioFile
            fontSize="small"
            sx={{ mr: 1 }}
          />{' '}
          Audio
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDocumentClick();
            handleClose();
          }}
        >
          <Description
            fontSize="small"
            sx={{ mr: 1 }}
          />{' '}
          Document
        </MenuItem>
      </Menu>
    </Stack>
  );
};

export default AddContentMenu;
