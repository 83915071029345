import React, { useState, useCallback, useEffect } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

interface PaginationProps {
  onPageChange: (token: string | null) => Promise<{
    items: unknown[];
    nextToken: string | null;
  }>;
  itemsPerPage: number;
  nextToken: string | null;
  totalCount: number;
  disabled?: boolean;
}

export default function Pagination({
  onPageChange,
  itemsPerPage,
  nextToken,
  totalCount,
  disabled = false,
}: PaginationProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const [tokenStack, setTokenStack] = useState<(string | null)[]>([
    null,
    nextToken,
  ]);

  const totalPages = Math.ceil(totalCount / itemsPerPage);

  useEffect(() => {
    // Update the token stack when nextToken changes
    setTokenStack((prevStack) => {
      const newStack = [...prevStack];
      newStack[currentPage] = nextToken;
      return newStack;
    });
  }, [nextToken, currentPage]);

  const handlePageChange = useCallback(
    async (page: number) => {
      if (page === currentPage) return;

      let token: string | null;
      let newTokenStack = [...tokenStack];

      if (page > currentPage) {
        // Moving forward
        token = tokenStack[currentPage];
      } else {
        // Moving backward
        token = tokenStack[page - 1];
      }

      const result = await onPageChange(token);

      if (page > currentPage) {
        newTokenStack[page] = result.nextToken;
      }

      setCurrentPage(page);
      setTokenStack(newTokenStack);
    },
    [currentPage, tokenStack, onPageChange],
  );

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <IconButton
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1 || disabled}
      >
        <ChevronLeft />
      </IconButton>
      <Typography
        variant="body2"
        style={{
          margin: '0',
          opacity: disabled ? 0.5 : 1,
        }}
      >
        {currentPage}/{totalPages || 1}
      </Typography>
      <IconButton
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages || totalPages < 1}
      >
        <ChevronRight />
      </IconButton>
    </Box>
  );
}
