import { api as generatedApi } from 'graphql/generated';

export const sampleApi = generatedApi.enhanceEndpoints({
  addTagTypes: ['Sample', 'SampleList', 'SampleBatch', 'ProcessingStatus'],
  endpoints: {
    GetSamples: {
      providesTags: ['SampleList'],
    },
    GetSample: {
      providesTags: (result, error, id) =>
        result ? [{ type: 'Sample', id }] : ['Sample'],
    },
    UpdateSample: {
      invalidatesTags: (result, error, { input: { id } }) => [
        { type: 'Sample', id: id },
        'SampleList',
      ],
    },
    DeleteSample: {
      invalidatesTags: (result, error, { input: { id } }) => [
        { type: 'Sample', id },
        'SampleList',
      ],
    },
    GetSampleBatch: {
      providesTags: ['SampleBatch'],
    },
    GetBatchStatus: {
      providesTags: ['ProcessingStatus'],
    },
    GetSampleTokensCount: {
      providesTags: ['SampleTokens'],
    },
  },
});

export const {
  useGetSampleQuery,
  useLazyGetSampleQuery,
  useUpdateSampleMutation,
  useDeleteSampleMutation,
  useGetSamplesQuery,
  useLazyGetSamplesQuery,
  useGetSampleBatchQuery,
  useLazyGetSampleBatchQuery,
  useGetBatchStatusQuery,
  useLazyGetBatchStatusQuery,
  useGetSampleTokensCountQuery,
  useLazyGetSampleTokensCountQuery,
} = sampleApi;
