import { ModelHome, ModelVoice } from 'components/molecules';
import ModelContext from 'components/molecules/model-context';
import ModelModels from 'components/organisms/model-models';
import { JSXElementConstructor, ReactElement } from 'react';
import { ModelTabView } from 'types/app';

interface TabDefinition {
  key: number;
  value: ModelTabView;
  label: string;
  component: React.ReactNode;
  icon?:
    | string
    | ReactElement<unknown, string | JSXElementConstructor<unknown>>
    | undefined;
  disabled?: boolean;
}

export const tabs: TabDefinition[] = [
  {
    key: 0,
    value: 'instructions',
    label: 'Instructions',
    component: <ModelHome />,
  },
  {
    key: 1,
    value: 'memory',
    label: 'Memory',
    component: <ModelContext />,
  },
  {
    key: 2,
    value: 'voice',
    label: 'Voice',
    component: <ModelVoice />,
  },
  {
    key: 3,
    value: 'advanced',
    label: 'Advanced',
    component: <ModelModels />,
  },
];
