import {
  Card,
  CardContent,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Skeleton,
} from '@mui/material';
import { getMemoryStepindex } from 'common/utils';
import { useParams } from 'react-router-dom';
import { useGetMemoryQuery } from 'graphql/generated';
import { MemoryStep } from 'types/enums';

export default function ProgressStepper() {
  const { memoryID } = useParams<{ memoryID: string }>();
  const { data: memoryData, isLoading: isLoadingMemory } = useGetMemoryQuery({
    input: { id: memoryID },
  });
  const memory = memoryData?.getMemory?.data;
  const activeStep = getMemoryStepindex(memory?.step as MemoryStep);
  const steps = ['Select', 'Settings', 'Ingest'];

  return (
    <Card>
      <CardContent sx={{ mt: 1 }}>
        <Stepper
          activeStep={isLoadingMemory ? 0 : activeStep}
          orientation="horizontal"
        >
          {steps.map((step, index) => (
            <Step
              key={index}
              active={!isLoadingMemory && index === activeStep}
              completed={!isLoadingMemory && index < activeStep}
            >
              <StepLabel>
                {isLoadingMemory ? (
                  <Skeleton
                    variant="text"
                    width={step.length * 10} // Approximate width based on text length
                    height={20} // Match typography height
                    sx={{
                      transform: 'scale(1, 0.8)', // Adjust the height scale
                      marginTop: '4px', // Fine-tune vertical alignment
                    }}
                  />
                ) : (
                  <Typography variant="body2">{step}</Typography>
                )}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </CardContent>
    </Card>
  );
}
