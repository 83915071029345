import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
  Pagination,
} from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { useGetCallFactsQuery } from 'graphql/generated';
import { CallFactsAction } from 'types/enums';
import { useState } from 'react';
import { formatDate } from 'common/utils';

export default function LogMessages({ callID }: { callID: string }) {
  const [page, setPage] = useState(0);
  const messagesPerPage = 10;

  const { data: callFacts } = useGetCallFactsQuery({
    input: {
      callID: callID,
      action: CallFactsAction.getmessages,
      limit: messagesPerPage,
      cursor: page,
    },
  });

  const messages = callFacts?.getCallFacts?.data?.messages?.messages;
  const totalRowCount =
    callFacts?.getCallFacts?.data?.messages?.totalCount || 0;
  const totalPages = Math.ceil(totalRowCount / messagesPerPage);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  return (
    <Card>
      <CardContent>
        <Typography
          sx={{ textTransform: 'uppercase' }}
          variant="caption"
        >
          History
        </Typography>
        <Divider sx={{ opacity: 0.2, my: 1 }} />
        <Stack
          direction="column"
          spacing={1}
        >
          {messages?.map((message) => (
            <Accordion
              elevation={2}
              key={message.uuid}
            >
              <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                aria-controls={`panel-${message.uuid}-content`}
                id={`panel-${message.uuid}-header`}
              >
                <Typography variant="caption">
                  {formatDate(message.createdAt, 'ccc - MMM d, yyyy')} -{' '}
                  <strong>{message.role}:</strong>{' '}
                  {message.content.length > 50
                    ? `${message.content.substring(0, 50)}...`
                    : message.content}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">
                  <strong>{message.role}:</strong> {message.content}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Stack>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
        />
      </CardContent>
    </Card>
  );
}
