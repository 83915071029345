import { Alert, AlertTitle, Stack, Typography, Link } from '@mui/material';
import { getMemoryStepindex } from 'common/utils';
import Layout from 'layouts';
import { useParams } from 'react-router-dom';
import { useGetMemoryQuery } from 'graphql/generated';
import { MemoryStep, MemoryType } from 'types/enums';
import {
  StartTitle,
  EndAction,
  ProgressStepper,
  TokenChecker,
} from './components';
import { Loading } from 'components/atoms';
import {
  MemoryNewStepSelect,
  MemoryNewStepIngest,
  MemoryNewStepOntology,
  MemoryNewStepSettings,
  MemoryNewStepReviewGraph,
  MemoryNewStepReviewVector,
} from './step';
import MemoryNewStepWebhook from './step/webhook';

export default function MemoryNew() {
  const { memoryID } = useParams<{ memoryID: string }>();
  const { data: memoryData, isLoading } = useGetMemoryQuery({
    input: { id: memoryID },
  });

  const memory = memoryData?.getMemory?.data;
  const activeStep = getMemoryStepindex(memory?.step as MemoryStep);
  const memoryType = memory?.memoryType;

  return (
    <Layout
      backArrow
      startTitle={<StartTitle />}
      endAction={<EndAction />}
    >
      <Stack
        direction="column"
        spacing={2}
        sx={{ py: 1 }}
      >
        <ProgressStepper />
        {isLoading && <Loading />}
        {activeStep === 0 && !isLoading && <TokenChecker />}
        {activeStep === 0 && !isLoading && <MemoryNewStepSelect />}
        {activeStep === 1 && !isLoading && (
          <Alert
            severity="info"
            // icon={<InfoOutlined />}
            sx={{
              '& .MuiAlert-icon': {
                padding: '10px 5px',
                marginRight: 2,
              },
            }}
          >
            <AlertTitle>
              Need a <strong>Custom Ontology?</strong>
            </AlertTitle>
            <Typography variant="body2">
              We can fine tune a model for you based on your data.{' '}
              <Link
                href="https://duohub.ai/contact"
                target="_blank"
              >
                Contact us
              </Link>{' '}
              to discuss your requirements.
            </Typography>
          </Alert>
        )}
        {activeStep === 1 && !isLoading && memoryType === MemoryType.graph && (
          <MemoryNewStepOntology />
        )}
        {((activeStep === 1 && memoryType === MemoryType.graph) ||
          (activeStep === 2 &&
            memoryType === MemoryType.graph &&
            memory?.webhookUrl)) &&
          !isLoading && <MemoryNewStepWebhook isDisabled={activeStep === 2} />}
        {activeStep === 1 && !isLoading && memoryType === MemoryType.vector && (
          <MemoryNewStepSettings />
        )}
        {activeStep === 2 && !isLoading && <MemoryNewStepIngest />}
        {(activeStep === 3 || activeStep === 4) &&
          !isLoading &&
          memoryType === MemoryType.graph && <MemoryNewStepReviewGraph />}
        {(activeStep === 3 || activeStep === 4) &&
          !isLoading &&
          memoryType === MemoryType.vector && <MemoryNewStepReviewVector />}
      </Stack>
    </Layout>
  );
}
