import { useState, useEffect } from 'react';
import { createAvatar } from '@dicebear/core';
import { botttsNeutral } from '@dicebear/collection';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Box,
  Stack,
  Divider,
  Avatar,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useLazyGetBotQuery, useUpdateBotMutation } from 'graphql/generated';
import { Bot } from '@duohub/types';
import { backgroundColors, eyeStyles, mouthStyles } from 'common/avatar';

interface DialogAvatarProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  bot: Bot;
}

export default function DialogAvatar({
  open,
  setOpen,
  bot,
}: DialogAvatarProps) {
  const { botID } = useParams<{ botID: string }>();
  const [avatarSvg, setAvatarSvg] = useState('');
  const [backgroundColor, setBackgroundColor] = useState(
    bot?.backgroundColor || 'b6e3f4',
  );
  const [eyeStyle, setEyeStyle] = useState(bot?.eyes || 'bulging');
  const [mouthStyle, setMouthStyle] = useState(bot?.mouth || 'diagram');
  const [updateBot, { isLoading: updateBotLoading }] = useUpdateBotMutation();
  const [getBot] = useLazyGetBotQuery();

  useEffect(() => {
    setBackgroundColor(bot?.backgroundColor || 'b6e3f4');
    setEyeStyle(bot?.eyes || 'bulging');
    setMouthStyle(bot?.mouth || 'diagram');
  }, [bot]);

  useEffect(() => {
    const avatar = createAvatar(botttsNeutral, {
      seed: botID,
      size: 56,
      backgroundColor: [backgroundColor],
      eyes: [eyeStyle as any],
      mouth: [mouthStyle as any],
    });
    setAvatarSvg(avatar.toDataUri());
  }, [backgroundColor, eyeStyle, mouthStyle, botID]);

  const createEyeStylePreview = (style: string) => {
    const avatar = createAvatar(botttsNeutral, {
      seed: botID,
      size: 48,
      backgroundColor: [backgroundColor],
      eyes: [style as any],
      mouth: [mouthStyle as any],
    });
    return avatar.toDataUri();
  };

  const createMouthStylePreview = (style: string) => {
    const avatar = createAvatar(botttsNeutral, {
      seed: botID,
      size: 48,
      backgroundColor: [backgroundColor],
      eyes: [eyeStyle as any],
      mouth: [style as any],
    });
    return avatar.toDataUri();
  };

  const createBackgroundColorPreview = (color: string) => {
    const avatar = createAvatar(botttsNeutral, {
      seed: botID,
      size: 48,
      backgroundColor: [color],
      eyes: [eyeStyle as any],
      mouth: [mouthStyle as any],
    });
    return avatar.toDataUri();
  };

  const handleSave = async () => {
    await updateBot({
      input: {
        id: botID,
        backgroundColor,
        eyes: eyeStyle,
        mouth: mouthStyle,
      },
    });
    await getBot({ input: { id: botID } });
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <Stack
        direction="row"
        sx={{ px: 2, py: 1 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4">Customize Avatar</Typography>
        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </Stack>
      <Divider />
      <DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Avatar
            src={avatarSvg}
            alt="Avatar preview"
            sx={{ width: 150, height: 150 }}
          />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box>
            <Typography
              variant="subtitle1"
              gutterBottom
            >
              Background Color
            </Typography>
            <Box sx={{ maxWidth: '100%', overflowX: 'auto' }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                {backgroundColors.map((color) => (
                  <IconButton
                    key={color}
                    onClick={() => setBackgroundColor(color)}
                    sx={{
                      padding: 1,
                      minWidth: 'unset',
                      border:
                        backgroundColor === color ? '2px solid #fff' : 'none',
                    }}
                  >
                    <Avatar
                      src={createBackgroundColorPreview(color)}
                      alt={`Background color ${color}`}
                      sx={{ width: 40, height: 40 }}
                    />
                  </IconButton>
                ))}
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography
              variant="subtitle1"
              gutterBottom
            >
              Eye Style
            </Typography>
            <Box sx={{ maxWidth: '100%', overflowX: 'auto' }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                {eyeStyles.map((style) => (
                  <IconButton
                    key={style}
                    onClick={() => setEyeStyle(style)}
                    sx={{
                      padding: 1,
                      minWidth: 'unset',
                      border: eyeStyle === style ? '2px solid #fff' : 'none',
                    }}
                  >
                    <Avatar
                      src={createEyeStylePreview(style)}
                      alt={`Eye style ${style}`}
                      sx={{ width: 40, height: 40 }}
                    />
                  </IconButton>
                ))}
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography
              variant="subtitle1"
              gutterBottom
            >
              Mouth Style
            </Typography>
            <Box sx={{ maxWidth: '100%', overflowX: 'auto' }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                {mouthStyles.map((style) => (
                  <IconButton
                    key={style}
                    onClick={() => setMouthStyle(style)}
                    sx={{
                      padding: 1,
                      minWidth: 'unset',
                      border: mouthStyle === style ? '2px solid #fff' : 'none',
                    }}
                  >
                    <Avatar
                      src={createMouthStylePreview(style)}
                      alt={`Mouth style ${style}`}
                      sx={{ width: 40, height: 40 }}
                    />
                  </IconButton>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 2, pb: 2 }}>
        <LoadingButton
          onClick={handleSave}
          variant="contained"
          size="small"
          sx={{ height: 28, fontWeight: 600 }}
          loading={updateBotLoading}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
