import { Typography, Stack, styled } from '@mui/material';
import { Link } from 'react-router-dom';

const MavenProTypography = styled(Typography)({
  fontFamily: "'Maven Pro', sans-serif",
  fontWeight: 500,
});

interface LogoProps {
  size?: 'small' | 'medium' | 'large' | 'drawer';
}

export default function Logo({ size = 'medium' }: LogoProps) {
  return (
    <Link
      to="/"
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <Stack
        direction="row"
        spacing={
          size === 'small'
            ? 0.5
            : size === 'medium'
              ? 1
              : size === 'drawer'
                ? 2
                : 1.5
        }
        alignItems="center"
      >
        <img
          src="/img/icon-blue.svg"
          alt="duohub"
          style={{
            width:
              size === 'small'
                ? 24
                : size === 'medium' || size === 'drawer'
                  ? 32
                  : 48,
            height:
              size === 'small'
                ? 24
                : size === 'medium' || size === 'drawer'
                  ? 32
                  : 48,
          }}
        />
        <MavenProTypography
          variant="h4"
          sx={{
            mt: 0.5,
            fontSize:
              size === 'small'
                ? 16
                : size === 'medium' || size === 'drawer'
                  ? 24
                  : 32,
            ml:
              size === 'small'
                ? 0.25
                : size === 'medium' || size === 'drawer'
                  ? 0.5
                  : 1,
          }}
        >
          duohub
        </MavenProTypography>
      </Stack>
    </Link>
  );
}
