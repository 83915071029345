import {
  Card,
  CardContent,
  Chip,
  Divider,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useGetBatchStatusQuery, useGetSamplesQuery } from 'graphql/generated';
import { useEffect } from 'react';
import { SampleType } from 'types/enums';
import { useAudio } from 'use/audio';

export default function Clean() {
  const { setCleaningFinished } = useAudio();
  const { voiceID } = useParams<{ voiceID: string }>();
  const { data: voiceData } = useGetSamplesQuery({
    input: {
      voiceID,
      type: SampleType.human,
    },
  });

  const { data: processingStatusData, refetch: refetchProcessingStatus } =
    useGetBatchStatusQuery({
      input: {
        voiceID,
      },
    });

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (
      processingStatusData &&
      (processingStatusData.getBatchStatus.completed !== 0 ||
        processingStatusData.getBatchStatus.total !==
          processingStatusData.getBatchStatus.completed)
    ) {
      setCleaningFinished(false);
      intervalId = setInterval(() => {
        console.log('Refetching processing status');
        refetchProcessingStatus();
      }, 5000);
    }

    if (
      processingStatusData &&
      processingStatusData.getBatchStatus.completed ===
        processingStatusData.getBatchStatus.total &&
      processingStatusData.getBatchStatus.total !== 0
    ) {
      setCleaningFinished(true);
    }

    return () => {
      if (intervalId) {
        console.log('Clearing refetch interval');
        clearInterval(intervalId);
      }
    };
  }, [processingStatusData, refetchProcessingStatus, setCleaningFinished]);

  const samples = voiceData?.getSamples?.data;
  const completed = processingStatusData?.getBatchStatus.completed || 0;
  const total = processingStatusData?.getBatchStatus.total || 0;
  const progressPercentage = (completed / total) * 100;

  return (
    <Card>
      <CardContent>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1.5}
        >
          <Typography variant="h5">Cleaning Samples</Typography>
          <Chip
            size="small"
            label={`${completed}/${samples?.length}`}
          />
        </Stack>
        <Divider sx={{ my: 2 }} />
        <LinearProgress
          variant={
            !processingStatusData?.getBatchStatus.completed
              ? 'indeterminate'
              : 'determinate'
          }
          value={progressPercentage}
        />
      </CardContent>
    </Card>
  );
}
