import React from 'react';
import { IconButton, CircularProgress } from '@mui/material';
import {
  Close,
  Add,
  Edit,
  Info,
  ArrowForward,
  Settings,
  MapsUgc,
  ArrowBack,
  Tune,
  Speed,
  CopyAll,
  Launch,
  Download,
  Delete,
  LocalLibrary,
} from '@mui/icons-material';
import { Tooltip } from 'components/atoms';
import { ColorVariant, IconType } from 'types/app';

interface ButtonIconTooltipProps {
  onClick: () => Promise<void> | void;
  isLoading: boolean;
  isDisabled?: boolean;
  tooltipTitle: string;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
  tooltipColorVariant?: ColorVariant;
  icon?: IconType;
  size?: 'small' | 'medium' | 'large';
  setAnchorEl?: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

const ButtonIconTooltip: React.FC<ButtonIconTooltipProps> = ({
  onClick,
  isLoading,
  isDisabled = false,
  tooltipTitle,
  tooltipPlacement = 'top',
  tooltipColorVariant = 'tertiary',
  icon = 'close',
  size = 'medium',
  setAnchorEl,
}) => {
  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isDisabled && !isLoading) {
      if (setAnchorEl) {
        setAnchorEl(event.currentTarget);
      }
      await onClick();
    }
  };

  const getIcon = () => {
    switch (icon) {
      case 'add':
        return <Add fontSize={size} />;
      case 'edit':
        return <Edit />;
      case 'info':
        return <Info />;
      case 'go':
        return <ArrowForward />;
      case 'settings':
        return <Settings />;
      case 'posts':
        return <MapsUgc />;
      case 'back':
        return <ArrowBack />;
      case 'tune':
        return <Tune />;
      case 'speed':
        return <Speed />;
      case 'copy':
        return <CopyAll />;
      case 'launch':
        return <Launch />;
      case 'download':
        return <Download />;
      case 'delete':
        return <Delete />;
      case 'library':
        return <LocalLibrary />;
      case 'close':
      default:
        return <Close />;
    }
  };

  return (
    <Tooltip
      title={tooltipTitle}
      placement={tooltipPlacement}
      colorVariant={tooltipColorVariant as ColorVariant}
    >
      <IconButton
        // sx={{ py: size === 'medium' ? '6px' : 'default' }}
        size={size}
        disabled={isDisabled || isLoading}
        onClick={handleClick}
      >
        {isLoading ? <CircularProgress size={24} /> : getIcon()}
      </IconButton>
    </Tooltip>
  );
};

export default ButtonIconTooltip;
