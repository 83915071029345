import React from 'react';
import { Box, CircularProgress, Stack } from '@mui/material';
import { useGetFilesQuery } from 'graphql/generated';
import { RowSampleMiniFile } from 'components/atoms';
import { grey } from '@mui/material/colors';
import { FileCategory, SampleType } from 'types/enums';
import { useParams } from 'react-router-dom';

const InterfaceContent: React.FC = () => {
  const { data, isLoading } = useGetFilesQuery({
    input: {
      categories: [FileCategory.audio, FileCategory.video, FileCategory.audio],
    },
  });
  const files = data?.getFiles?.data;
  const { voiceID } = useParams<{ voiceID: string }>();

  return (
    <Box
      sx={{
        height: '200px',
        width: '100%',
        borderRadius: '4px',
        padding: '8px',
        '&::-webkit-scrollbar': {
          width: '10px',
          backgroundColor: grey[900],
          borderRadius: 8,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: [grey[500]],
          borderRadius: 8,
        },
        overflowY: 'scroll',
      }}
    >
      {isLoading && (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!isLoading && (
        <Stack
          direction="column"
          spacing={1.5}
          sx={{
            paddingRight: '10px',
          }}
        >
          {files?.map((file) => (
            <RowSampleMiniFile
              key={file?.id}
              file={file}
              sampleType={SampleType.human}
              sampleIndex={voiceID}
            />
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default InterfaceContent;
